import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const NewMessage = ({ userId }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      const q = query(collection(db, 'users'), where('role', 'in', ['provider', 'officeStaff']));
      const querySnapshot = await getDocs(q);
      const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  const handleStartConversation = async () => {
    if (selectedUser && message.trim() !== '') {
      const conversation = await addDoc(collection(db, 'conversations'), {
        participants: [userId, selectedUser],
        lastMessage: message,
        lastMessageTimestamp: new Date(),
      });

      await addDoc(collection(db, 'messages'), {
        conversationId: conversation.id,
        senderId: userId,
        message: message,
        timestamp: new Date(),
      });

      setSelectedUser('');
      setMessage('');
    }
  };

  return (
    <div className="mb-4 dark:bg-gray-800">
      <h2 className="text-2xl font-bold mb-4">New Message</h2>
      <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)} className="p-2 border rounded mb-2 w-full">
        <option value="" disabled>Select a user</option>
        {users.map((user) => (
          <option key={user.id} value={user.id}>{user.displayName || user.email}</option>
        ))}
      </select>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message"
        className="p-2 border rounded w-full"
      ></textarea>
      <button onClick={handleStartConversation} className="p-2 bg-green-500 text-white rounded mt-2 w-full">Start Conversation</button>
    </div>
  );
};

export default NewMessage;