import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown

const PatientNotes = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false); // State to manage expand/collapse

  useEffect(() => {
    const fetchNotes = async () => {
      const notesRef = collection(db, `patients/${id}/notes`); // Fetch from the notes collection
      const querySnapshot = await getDocs(notesRef);
      const notesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotes(notesData);
    };

    fetchNotes();
  }, [id]);

  const toggleNotes = () => {
    setIsExpanded((prev) => !prev); // Toggle the expanded state
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Patient Notes</h1>
      <button
        onClick={toggleNotes}
        className="mb-4 text-sm text-blue-500 font-bold"
      >
        {isExpanded ? 'Hide Notes' : 'Show Notes'}
      </button>
      {isExpanded && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Patient Notes</h3>
          {notes.length > 0 ? (
            notes.map(note => (
              <div key={note.id} className="prose prose-sm max-w-none bg-gray-100 p-4 rounded-md shadow-sm mb-4">
                <ReactMarkdown>{note.content || 'No Content Available'}</ReactMarkdown>
              </div>
            ))
          ) : (
            <p>No Notes Available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PatientNotes;