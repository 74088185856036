import React, { useState, useEffect } from 'react';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';

const VitalsForm = ({ editingId, setEditingId, fetchVitals }) => {
  const { id } = useParams();
  const [form, setForm] = useState({
    systolic: '',
    diastolic: '',
    pulse: '',
    respiratoryRate: '',
    oxygenSaturation: '',
    heightFt: '',
    heightIn: '',
    heightM: '',
    weightLb: '',
    weightKg: '',
    temperatureF: '',
    temperatureC: '',
  });

  useEffect(() => {
    if (editingId) {
      fetchVital(editingId);
    }
  }, [editingId]);

  const fetchVital = async (vitalId) => {
    const docRef = doc(db, 'patients', id, 'vitals', vitalId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setForm(docSnap.data());
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    let heightFt = form.heightFt;
    let heightIn = form.heightIn;
    let heightM = form.heightM;

    if (name === 'heightFt' || name === 'heightIn') {
      if (name === 'heightFt') {
        heightFt = value;
      } else {
        heightIn = value;
      }
      heightM = (((parseInt(heightFt, 10) * 12) + parseInt(heightIn, 10)) * 0.0254).toFixed(2);
    } else if (name === 'heightM') {
      heightM = value;
      const totalInches = parseFloat(heightM) / 0.0254;
      heightFt = Math.floor(totalInches / 12);
      heightIn = (totalInches % 12).toFixed(0);
    }

    setForm((prevForm) => ({
      ...prevForm,
      heightFt,
      heightIn,
      heightM,
    }));
  };

  const handleWeightChange = (e) => {
    const { name, value } = e.target;
    let weightLb = form.weightLb;
    let weightKg = form.weightKg;

    if (name === 'weightLb') {
      weightLb = value;
      weightKg = (parseFloat(weightLb) * 0.453592).toFixed(2);
    } else if (name === 'weightKg') {
      weightKg = value;
      weightLb = (parseFloat(weightKg) / 0.453592).toFixed(2);
    }

    setForm((prevForm) => ({
      ...prevForm,
      weightLb,
      weightKg,
    }));
  };

  const handleTemperatureChange = (e) => {
    const { name, value } = e.target;
    let temperatureF = form.temperatureF;
    let temperatureC = form.temperatureC;

    if (name === 'temperatureF') {
      temperatureF = value;
      temperatureC = ((parseFloat(temperatureF) - 32) * (5 / 9)).toFixed(2);
    } else if (name === 'temperatureC') {
      temperatureC = value;
      temperatureF = (parseFloat(temperatureC) * (9 / 5) + 32).toFixed(2);
    }

    setForm((prevForm) => ({
      ...prevForm,
      temperatureF,
      temperatureC,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        const docRef = doc(db, 'patients', id, 'vitals', editingId);
        await updateDoc(docRef, { ...form, patientId: id, editedAt: new Date() });
        setEditingId(null);
      } else {
        await addDoc(collection(db, 'patients', id, 'vitals'), { ...form, patientId: id, createdAt: new Date() });
      }
      fetchVitals();
      setForm({
        systolic: '',
        diastolic: '',
        pulse: '',
        respiratoryRate: '',
        oxygenSaturation: '',
        heightFt: '',
        heightIn: '',
        heightM: '',
        weightLb: '',
        weightKg: '',
        temperatureF: '',
        temperatureC: '',
      });
    } catch (error) {
      console.error('Error adding/updating document: ', error);
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-xl lg:max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Enter Vitals</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label>Systolic</label>
            <input
              type="number"
              name="systolic"
              placeholder="mmHg"
              value={form.systolic}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
            />
            <span className="text-sm text-gray-500"> mmHg</span>
          </div>
          <div>
            <label>Diastolic</label>
            <input
              type="number"
              name="diastolic"
              placeholder="mmHg"
              value={form.diastolic}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
            />
            <span className="text-sm text-gray-500"> mmHg</span>
          </div>
          <div>
            <label>Pulse (BPM)</label>
            <input
              type="number"
              name="pulse"
              placeholder="BPM"
              value={form.pulse}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
            />
            <span className="text-sm text-gray-500"> BPM</span>
          </div>
          <div>
            <label>Respiratory Rate</label>
            <input
              type="number"
              name="respiratoryRate"
              placeholder="Breaths/min"
              value={form.respiratoryRate}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
            />
            <span className="text-sm text-gray-500"> Breaths/min</span>
          </div>
          <div>
            <label>Oxygen Saturation (%)</label>
            <input
              type="number"
              name="oxygenSaturation"
              placeholder="%"
              value={form.oxygenSaturation}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
            />
            <span className="text-sm text-gray-500"> %</span>
          </div>
          <div>
            <label>Height</label>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <input
                  type="number"
                  name="heightFt"
                  placeholder="Feet"
                  value={form.heightFt}
                  onChange={handleHeightChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> ft</span>
              </div>
              <div>
                <input
                  type="number"
                  name="heightIn"
                  placeholder="Inches"
                  value={form.heightIn}
                  onChange={handleHeightChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> in</span>
              </div>
              <div>
                <input
                  type="number"
                  name="heightM"
                  placeholder="Meters"
                  value={form.heightM}
                  onChange={handleHeightChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm                   focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> m</span>
              </div>
            </div>
          </div>
          <div>
            <label>Weight</label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <input
                  type="number"
                  name="weightLb"
                  placeholder="Pounds"
                  value={form.weightLb}
                  onChange={handleWeightChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> lbs</span>
              </div>
              <div>
                <input
                  type="number"
                  name="weightKg"
                  placeholder="Kilograms"
                  value={form.weightKg}
                  onChange={handleWeightChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> kg</span>
              </div>
            </div>
          </div>
          <div>
            <label>Temperature</label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <input
                  type="number"
                  name="temperatureF"
                  placeholder="Fahrenheit"
                  value={form.temperatureF}
                  onChange={handleTemperatureChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> °F</span>
              </div>
              <div>
                <input
                  type="number"
                  name="temperatureC"
                  placeholder="Celsius"
                  value={form.temperatureC}
                  onChange={handleTemperatureChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:bg-white focus:ring-indigo-500 sm:text-sm p-2"
                />
                <span className="text-sm text-gray-500"> °C</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="w-full inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Vitals
          </button>
        </div>
      </form>
    </div>
  );
};

export default VitalsForm;