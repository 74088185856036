import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getMessaging, onMessage, getToken, isSupported } from 'firebase/messaging';
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const vertexAI = getVertexAI(app);
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

let messaging = null;

const initializeMessaging = async () => {
  if (await isSupported()) {
    messaging = getMessaging(app);

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered with scope:', registration.scope);

        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY, serviceWorkerRegistration: registration });
        if (currentToken) {
          // console.log('FCM Token:', currentToken);
        } else {
          console.warn('No registration token available. Request permission to generate one.');
        }

        if (messaging) {
          onMessage(messaging, (payload) => {
            console.log('Message received: ', payload);
          });
        }
      } catch (error) {
        console.error('Error during messaging initialization: ', error);
      }
    } else {
      console.warn("Service workers and push notifications are not supported in this browser.");
    }
  } else {
    console.warn("Firebase Messaging is not supported in this browser.");
  }
};

// Call the function to initialize messaging
initializeMessaging();

export { auth, db, storage, analytics, vertexAI, model, messaging };