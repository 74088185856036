import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust the import based on your Firebase setup
import { addDoc, collection } from 'firebase/firestore';
import { Button, Modal, message } from 'antd';

const today = new Date().toISOString().split('T')[0];

const SensationSurvey = ({patientId}) => {
  const [formData, setFormData] = useState({
    headacheFrequency: '',
    headacheSeverity: 1,
    headacheImpact: '',
    neckPainFrequency: '',
    neckPainSeverity: 1,
    neckPainImpact: '',
    handNumbnessFrequency: '',
    handNumbnessImpact: '',
    upperBackPainFrequency: '',
    upperBackPainSeverity: 1,
    upperBackPainImpact: '',
    chestNumbnessFrequency: '',
    chestNumbnessImpact: '',
    lowerBackPainFrequency: '',
    lowerBackPainSeverity: 1,
    lowerBackPainImpact: '',
    legNumbnessFrequency: '',
    legNumbnessImpact: '',
    signature: '',
    printName: '',
    relationshipToPatient: '',
    date: today,
  });

  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showSuccessMessage = () => {
    messageApi.open({
      type: 'success',
      content: 'Sensation Survey saved successfully!',
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSubmitted(true); // Ensure form does not reappear
  };

  const handleTextChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formTitle = `Sensation Survey - ${new Date().toLocaleDateString()}`;
      await addDoc(collection(db, 'patients', patientId, 'forms'), {
        title: formTitle,
        ...formData,
        timestamp: new Date(),
      });
      showSuccessMessage();
      setSubmitted(true);
      localStorage.setItem(`submitted-sensation-${patientId}-${today}`, true);
    } catch (error) {
      console.error('Error saving form data: ', error);
    }
  };

  useEffect(() => {
    const isSubmitted = localStorage.getItem(`submitted-sensation-${patientId}-${today}`);
    if (isSubmitted) {
      setIsModalOpen(true); // Open modal for resubmission confirmation
    }
  }, [patientId]);

  return (
    <div className={`p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-3xl mx-auto ${submitted ? 'animate-fadeOut' : ''}`}>
      {contextHolder}
      {!submitted && (
        <>
          <h2 className="text-2xl font-bold mb-6 text-center">Sensation Survey</h2>
          <form onSubmit={handleSubmit}>
            {/* Headache Section */}
            <div className="mb-6">
              <label className="block mb-2">1. How frequently do you get headaches?</label>
              <select
                value={formData.headacheFrequency}
                onChange={(e) => handleTextChange('headacheFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">2. On a scale of 1-10, how bad are they when they come?</label>
              <input
                type="range"
                min="1"
                max="10"
                value={formData.headacheSeverity}
                onChange={(e) => handleTextChange('headacheSeverity', e.target.value)}
                className="w-full"
              />
              <div className="flex justify-between">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            <div className="mb-6">
              <label className="block mb-2">3. Do they negatively affect your life in any way? If yes, please explain:</label>
              <textarea
                value={formData.headacheImpact}
                onChange={(e) => handleTextChange('headacheImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Neck Pain Section */}
            <div className="mb-6">
              <label className="block mb-2">4. How often do you have pain, stiffness, or soreness in your neck?</label>
              <select
                value={formData.neckPainFrequency}
                onChange={(e) => handleTextChange('neckPainFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">5. On a scale of 1-10 how bad is the discomfort?</label>
              <input
                type="range"
                min="1"
                max="10"
                value={formData.neckPainSeverity}
                onChange={(e) => handleTextChange('neckPainSeverity', e.target.value)}
                className="w-full"
              />
              <div className="flex justify-between">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            <div className="mb-6">
              <label className="block mb-2">6. Does it negatively affect your life in any way?</label>
              <textarea
                value={formData.neckPainImpact}
                onChange={(e) => handleTextChange('neckPainImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Hand Numbness Section */}
            <div className="mb-6">
              <label className="block mb-2">7. Do you ever get numbness/tingling/pain in your arms or hands?</label>
              <select
                value={formData.handNumbnessFrequency}
                onChange={(e) => handleTextChange('handNumbnessFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">8. Does it negatively affect your life in any way?</label>
              <textarea
                value={formData.handNumbnessImpact}
                onChange={(e) => handleTextChange('handNumbnessImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Upper Back Pain Section */}
            <div className="mb-6">
              <label className="block mb-2">9. How often do you have pain, stiffness, or soreness in your mid to upper back?</label>
              <select
                value={formData.upperBackPainFrequency}
                onChange={(e) => handleTextChange('upperBackPainFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">10. On a scale of 1-10, how bad is the discomfort?</label>
              <input
                type="range"
                min="1"
                max="10"
                value={formData.upperBackPainSeverity}
                onChange={(e) => handleTextChange('upperBackPainSeverity', e.target.value)}
                className="w-full"
              />
              <div className="flex justify-between">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            <div className="mb-6">
              <label className="block mb-2">11. Does it negatively affect your life in any way?</label>
              <textarea
                value={formData.upperBackPainImpact}
                onChange={(e) => handleTextChange('upperBackPainImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Chest Numbness Section */}
            <div className="mb-6">
              <label className="block mb-2">12. Do you ever get numbness/tingling/pain in your ribs or chest?</label>
              <select
                value={formData.chestNumbnessFrequency}
                onChange={(e) => handleTextChange('chestNumbnessFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">13. Does it negatively affect your life in any way?</label>
              <textarea
                value={formData.chestNumbnessImpact}
                onChange={(e) => handleTextChange('chestNumbnessImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Lower Back Pain Section */}
            <div className="mb-6">
              <label className="block mb-2">14. How often do you have pain, stiffness, or soreness in your lower back?</label>
              <select
                value={formData.lowerBackPainFrequency}
                onChange={(e) => handleTextChange('lowerBackPainFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">15. On a scale of 1-10, how bad is the discomfort?</label>
              <input
                type="range"
                min="1"
                max="10"
                value={formData.lowerBackPainSeverity}
                onChange={(e) => handleTextChange('lowerBackPainSeverity', e.target.value)}
                className="w-full"
              />
              <div className="flex justify-between">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            <div className="mb-6">
              <label className="block mb-2">16. Does it negatively affect your life in any way?</label>
              <textarea
                value={formData.lowerBackPainImpact}
                onChange={(e) => handleTextChange('lowerBackPainImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Leg Numbness Section */}
            <div className="mb-6">
              <label className="block mb-2">17. Do you ever get numbness/tingling/pain in your legs or feet?</label>
              <select
                value={formData.legNumbnessFrequency}
                onChange={(e) => handleTextChange('legNumbnessFrequency', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select an option</option>
                <option value="never">Never</option>
                <option value="lessThan1xMonth">Less than 1x/month</option>
                <option value="1-4xMonth">1-4x/month</option>
                <option value="moreThan1xWeek">More than 1x/week</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block mb-2">18. Does it negatively affect your life in any way?</label>
              <textarea
                value={formData.legNumbnessImpact}
                onChange={(e) => handleTextChange('legNumbnessImpact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            {/* Signature Section */}
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Signature"
                value={formData.signature}
                onChange={(e) => handleTextChange('signature', e.target.value)}
                className="p-2 border rounded"
              />
              <input
                type="text"
                placeholder="Print Name"
                value={formData.printName}
                onChange={(e) => handleTextChange('printName', e.target.value)}
                className="p-2 border rounded"
              />
              <input
                type="text"
                placeholder="Relationship to Patient (if minor)"
                value={formData.relationshipToPatient}
                onChange={(e) => handleTextChange('relationshipToPatient', e.target.value)}
                className="p-2 border rounded"
              />
              <input
                type="date"
                value={formData.date}
                readOnly
                className="p-2 border rounded"
              />
            </div>

            <button type="submit" className="w-full bg-blue-500 text-white py-2 mt-6 rounded-md">
              Submit Sensation Survey
            </button>
          </form>
        </>
      )}
      <Modal
        title="Confirm Resubmission"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>You have already submitted this form for today. Are you sure you want to resubmit?</p>
      </Modal>
    </div>
  );
};

export default SensationSurvey;