import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const PublicNavbar = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/signin');
  };

  const menu = (
    <Menu>
      <Menu.Item key="about">
        <Link to="/about-us">About Us</Link>
      </Menu.Item>
      <Menu.Item key="pricing">
        <Link to="/pricing">Pricing</Link>
      </Menu.Item>
      <Menu.Item key="contact">
        <Link to="/contact-us">Contact</Link>
      </Menu.Item>
      <Menu.Item key="signin">
        <Button type="link" onClick={handleSignIn}>
          Sign In
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="bg-indigo-900 shadow">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="text-lg font-bold text-white">
          <Link to="/" style={{ color: 'white' }}>PosturePortal</Link>
        </div>
        <div className="flex items-center">
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="link" className="text-white hover:text-gray-200" style={{ color: 'white' }}>
              Menu <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default PublicNavbar;