import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const TreatmentPlans = () => {
  const { id } = useParams();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPlan, setNewPlan] = useState({
    startDate: '',
    endDate: '',
    description: '',
    goals: '',
  });
  const [editingPlan, setEditingPlan] = useState(null);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      const q = query(collection(db, 'treatmentPlans'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const plansList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPlans(plansList);
      setLoading(false);
    };

    fetchPlans();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlan({ ...newPlan, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingPlan({ ...editingPlan, [name]: value });
  };

  const handleAddPlan = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'treatmentPlans'), {
        ...newPlan,
        patientId: id,
        timestamp: new Date(),
      });
      setNewPlan({
        startDate: '',
        endDate: '',
        description: '',
        goals: '',
      });
      const q = query(collection(db, 'treatmentPlans'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const plansList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPlans(plansList);
    } catch (error) {
      console.error('Error adding plan:', error);
    }
  };

  const handleEditPlan = async (e) => {
    e.preventDefault();
    try {
      const planRef = doc(db, 'treatmentPlans', editingPlan.id);
      await updateDoc(planRef, editingPlan);
      setEditingPlan(null);
      const q = query(collection(db, 'treatmentPlans'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const plansList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPlans(plansList);
    } catch (error) {
      console.error('Error editing plan:', error);
    }
  };

  const handleStartEditing = (plan) => {
    setEditingPlan(plan);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestPlan = plans.length > 0 ? plans[0] : null;

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Treatment Plans
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show Form & History' : 'Hide Form & History'}
        </button>
      </h2>
      {latestPlan && !editingPlan && (
        <div className="bg-gray-100 p-4 rounded-md shadow mb-4">
          <p><strong>Start Date:</strong> {latestPlan.startDate}</p>
          <p><strong>End Date:</strong> {latestPlan.endDate}</p>
          <p><strong>Description:</strong> {latestPlan.description}</p>
          <p><strong>Goals:</strong> {latestPlan.goals}</p>
          <button
            onClick={() => handleStartEditing(latestPlan)}
            className="text-blue-500 underline mt-2"
          >
            Edit
          </button>
        </div>
      )}
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {plans.slice(1).map(plan => (
              <li key={plan.id} className="bg-gray-100 p-4 rounded-md shadow">
                {editingPlan && editingPlan.id === plan.id ? (
                  <form onSubmit={handleEditPlan}>
                    <div>
                      <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date</label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={editingPlan.startDate}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date</label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={editingPlan.endDate}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                      <textarea
                        id="description"
                        name="description"
                        value={editingPlan.description}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="goals" className="block text-sm font-medium text-gray-700">Goals</label>
                      <textarea
                        id="goals"
                        name="goals"
                        value={editingPlan.goals}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Save
                    </button>
                  </form>
                ) : (
                  <>
                    <p><strong>Start Date:</strong> {plan.startDate}</p>
                    <p><strong>End Date:</strong> {plan.endDate}</p>
                    <p><strong>Description:</strong> {plan.description}</p>
                    <p><strong>Goals:</strong> {plan.goals}</p>
                    <button
                      onClick={() => handleStartEditing(plan)}
                      className="text-blue-500 underline mt-2"
                    >
                      Edit
                    </button>
                  </>
                )}
              </li>
            ))}
          </ul>
          {!editingPlan && (
            <form onSubmit={handleAddPlan} className="space-y-4">
              <div>
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date</label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={newPlan.startDate}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date</label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={newPlan.endDate}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  id="description"
                  name="description"
                  value={newPlan.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="goals" className="block text-sm font-medium text-gray-700">Goals</label>
                <textarea
                  id="goals"
                  name="goals"
                  value={newPlan.goals}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Treatment Plan
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default TreatmentPlans;