import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { db } from '../../firebase';
import { collection, getDoc, getDocs, addDoc, deleteDoc, doc, setDoc, collectionGroup, updateDoc } from 'firebase/firestore';
import { Card, Row, Col, Select, List, Button, Form, TimePicker, DatePicker, message, Popconfirm, Checkbox, Modal, Switch, Avatar } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import DefaultAvatar from '../../assets/default-profile-pic.png'

const localizer = momentLocalizer(moment);
const { Option } = Select;

const StaffSchedule = ({ role }) => {
  const [users, setUsers] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null); // Assume this is fetched from auth context or similar
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [form] = Form.useForm();
  const [repeatOptions, setRepeatOptions] = useState({
    nextWeek: false,
    twoWeeks: false,
    oneMonth: false
  });
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentWorkHour, setCurrentWorkHour] = useState(null);
  const [showAppointments, setShowAppointments] = useState(true);
  const [eightHourShift, setEightHourShift] = useState(false);
  const [permanentSchedule, setPermanentSchedule] = useState({
    monday: { start: null, end: null, eightHourShift: false },
    tuesday: { start: null, end: null, eightHourShift: false },
    wednesday: { start: null, end: null, eightHourShift: false },
    thursday: { start: null, end: null, eightHourShift: false },
    friday: { start: null, end: null, eightHourShift: false },
    saturday: { start: null, end: null, eightHourShift: false },
    sunday: { start: null, end: null, eightHourShift: false },
  });
  const [showAllSchedules, setShowAllSchedules] = useState(false);
  const [showMySchedule, setShowMySchedule] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUsers(usersList);
    };

    const fetchAppointments = async () => {
      const appointmentsCollection = collection(db, 'appointments');
      const appointmentsSnapshot = await getDocs(appointmentsCollection);
      const appointmentsList = appointmentsSnapshot.docs.map(doc => ({
        ...doc.data(),
        start: new Date(doc.data().start.seconds * 1000),
        end: new Date(doc.data().end.seconds * 1000),
        id: doc.id
      }));
      setAppointments(appointmentsList);
    };

    const fetchWorkHours = async () => {
      const workHoursCollection = collectionGroup(db, 'workHours');
      const workHoursSnapshot = await getDocs(workHoursCollection);
      const workHoursList = workHoursSnapshot.docs.map(doc => ({
        ...doc.data(),
        start: new Date(doc.data().start.seconds * 1000),
        end: new Date(doc.data().end.seconds * 1000),
        id: doc.id,
        userId: doc.ref.parent.parent.id
      }));
      setWorkHours(workHoursList);
    };

    fetchUsers();
    fetchAppointments();
    fetchWorkHours();
  }, []);

  useEffect(() => {
    const fetchPermanentSchedule = async () => {
      if (!selectedUser) return;
  
      try {
        const scheduleRef = collection(db, 'users', selectedUser.id, 'permanentSchedule');
        const scheduleSnapshot = await getDocs(scheduleRef);
        const scheduleData = {};
  
        scheduleSnapshot.forEach(doc => {
          scheduleData[doc.id] = doc.data();
        });
  
        setPermanentSchedule(scheduleData);
      } catch (error) {
        console.error('Error fetching permanent schedule:', error);
      }
    };
  
    fetchPermanentSchedule();
  }, [selectedUser]);

  const handleAddWorkHours = async (values) => {
    if (!selectedUser) {
      message.error('Please select a user to add work hours.');
      return;
    }

    const { start, end, date } = values;
    const endTime = eightHourShift ? moment(start).add(8.5, 'hours').toDate() : end.toDate();
    const dates = [date];

    if (repeatOptions.nextWeek) dates.push(moment(date).add(1, 'week'));
    if (repeatOptions.twoWeeks) dates.push(moment(date).add(2, 'weeks'));
    if (repeatOptions.oneMonth) dates.push(moment(date).add(4, 'weeks'));

    try {
      for (const d of dates) {
        const newStart = moment(d).set({ hour: start.hour(), minute: start.minute() }).toDate();
        const newEnd = moment(d).set({ hour: end.hour(), minute: end.minute() }).toDate();

        const isDuplicate = workHours.some(wh =>
          wh.userId === selectedUser.id &&
          wh.start.getTime() === newStart.getTime() &&
          wh.end.getTime() === newEnd.getTime()
        );

        if (isDuplicate) {
          message.warning('Duplicate work hours detected. Skipping.');
          continue;
        }

        const workHoursData = {
          start: newStart,
          end: newEnd,
          color: users.find(user => user.id === selectedUser.id)?.color || 'lightblue'
        };
        await addDoc(collection(db, 'users', selectedUser.id, 'workHours'), workHoursData);
        setWorkHours([...workHours, { ...workHoursData, userId: selectedUser.id }]);
      }
      message.success('Work hours added successfully.');
      form.resetFields();
    } catch (error) {
      message.error('Failed to add work hours.');
    }
  };

  const handleDeleteWorkHour = async (workHourId) => {
    try {
      await deleteDoc(doc(db, 'users', selectedUser.id, 'workHours', workHourId));
      setWorkHours(workHours.filter(wh => wh.id !== workHourId));
      message.success('Work hour deleted successfully.');
    } catch (error) {
      message.error('Failed to delete work hour.');
    }
  };

  const handleEditWorkHour = async (values) => {
    if (!currentWorkHour) return;

    const { start, end } = values;
    const updatedWorkHour = {
      start: moment(currentWorkHour.start).set({ hour: start.hour(), minute: start.minute() }).toDate(),
      end: moment(currentWorkHour.end).set({ hour: end.hour(), minute: end.minute() }).toDate(),
    };

    try {
      await updateDoc(doc(db, 'users', currentWorkHour.userId, 'workHours', currentWorkHour.id), updatedWorkHour);
      setWorkHours(workHours.map(wh => (wh.id === currentWorkHour.id ? { ...wh, ...updatedWorkHour } : wh)));
      message.success('Work hour updated successfully.');
      setEditModalVisible(false);
    } catch (error) {
      message.error('Failed to update work hour.');
    }
  };

  const handlePermanentScheduleChange = (day, field, value) => {
    setPermanentSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value,
      },
    }));
  };

  const savePermanentSchedule = async () => {
    if (!selectedUser) {
      message.error('Please select a user to save the permanent schedule.');
      return;
    }
  
    try {
      for (const [day, schedule] of Object.entries(permanentSchedule)) {
        if (schedule.start) {
          const endTime = schedule.eightHourShift
            ? moment(schedule.start).add(8.5, 'hours').toDate()
            : schedule.end ? schedule.end.toDate() : null;
  
          if (!endTime) {
            message.error(`End time is required for ${day}.`);
            continue;
          }
  
          const scheduleData = {
            start: schedule.start.toDate(),
            end: endTime,
            eightHourShift: schedule.eightHourShift,
          };
  
          // Use the day name as the document ID
          await setDoc(doc(db, 'users', selectedUser.id, 'permanentSchedule', day), scheduleData);
        }
      }
      message.success('Permanent schedule saved successfully.');
    } catch (error) {
      console.error('Error saving permanent schedule:', error);
      message.error('Failed to save permanent schedule.');
    }
  };

  const updatePermanentSchedule = async (day, updatedSchedule) => {
    try {
      await setDoc(doc(db, 'users', selectedUser.id, 'permanentSchedule', day), updatedSchedule);
      message.success('Permanent schedule updated successfully.');
    } catch (error) {
      console.error('Error updating permanent schedule:', error);
      message.error('Failed to update permanent schedule.');
    }
  };

  const deletePermanentSchedule = async (day) => {
    try {
      const emptySchedule = {
        start: null,
        end: null,
        eightHourShift: false,
      };
  
      await setDoc(doc(db, 'users', selectedUser.id, 'permanentSchedule', day), emptySchedule);
      message.success('Permanent schedule cleared successfully.');
    } catch (error) {
      console.error('Error clearing permanent schedule:', error);
      message.error('Failed to clear permanent schedule.');
    }
  };

  const filteredUsers = selectedRole
    ? users.filter(user => user.role === selectedRole)
    : users;

  const filteredAppointments = selectedUser
    ? appointments.filter(appointment => appointment.providerId === selectedUser.id)
    : appointments;

  const filteredWorkHours = showAllSchedules
    ? workHours
    : selectedUser
    ? workHours.filter(workHour => workHour.userId === selectedUser.id)
    : workHours;

    const canEditSchedule = role === 'admin' || role === 'managingDoctor';
    console.log('User Role:', role);
  
    const calendarEvents = [
      ...appointments,
      ...workHours,
      ...Object.entries(permanentSchedule).map(([day, schedule]) => ({
        start: schedule.start,
        end: schedule.end,
        title: `Permanent: ${day.charAt(0).toUpperCase() + day.slice(1)}`,
        color: 'lightgreen'
      }))
    ];

  return (
    <div className="container dark:bg-gray-800 mx-auto my-4">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Card title="Select Staff" bordered={false}>
          <Select
            showSearch
            filterOption={(input, option) => {
              const user = users.find(user => user.id === option.value);
              const userName = `${user.firstName} ${user.lastName}`.toLowerCase();
              return userName.includes(input.toLowerCase());
            }}
            options={users.map(user => ({
              value: user.id,
              label: (
                <div className="flex items-center">
                  <Avatar 
                    src={user.photoURL || DefaultAvatar} // Fallback to a default image
                    size="large" 
                    className="mr-2" 
                    alt={`${user.firstName} ${user.lastName}`}
                  />
                  {`${user.firstName} ${user.lastName}`}
                </div>
              )
            }))}
            placeholder="Search for a provider"
            value={selectedUser?.id}
            onChange={(userId) => setSelectedUser(users.find(user => user.id === userId))}
            style={{ width: '100%' }}
            className='h-12'
          />
            <Button onClick={() => setShowAllSchedules(!showAllSchedules)}>
              {showAllSchedules ? 'Show Selected' : 'Show Everyone'}
            </Button>
            <Button onClick={() => setShowMySchedule(!showMySchedule)}>
              {showMySchedule ? 'Hide My Schedule' : 'Show My Schedule'}
            </Button>
          </Card>
        </Col>
        {canEditSchedule && selectedUser && (
          <Col xs={24} lg={12}>
            <Card title="Add Work Hours" bordered={false}>
              <Form form={form} onFinish={handleAddWorkHours} layout="inline">
                <Form.Item name="date" rules={[{ required: true, message: 'Date is required' }]}>
                  <DatePicker placeholder="Select Date" />
                </Form.Item>
                <Form.Item name="start" rules={[{ required: true, message: 'Start time is required' }]}>
                  <TimePicker placeholder="Start Time" format="h:mm a" use12Hours />
                </Form.Item>
                {!eightHourShift && (
                  <Form.Item name="end" rules={[{ required: true, message: 'End time is required' }]}>
                    <TimePicker placeholder="End Time" format="h:mm a" use12Hours />
                  </Form.Item>
                )}
                <Form.Item>
                  <Checkbox onChange={(e) => setEightHourShift(e.target.checked)}>
                    8 Hour Shift
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Checkbox onChange={(e) => setRepeatOptions({ ...repeatOptions, nextWeek: e.target.checked })}>
                    Repeat Next Week
                  </Checkbox>
                  <Checkbox onChange={(e) => setRepeatOptions({ ...repeatOptions, twoWeeks: e.target.checked })}>
                    Repeat Two Weeks
                  </Checkbox>
                  <Checkbox onChange={(e) => setRepeatOptions({ ...repeatOptions, oneMonth: e.target.checked })}>
                    Repeat for Next Four Weeks
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]}>
        {canEditSchedule && selectedUser && (
          <Col xs={24} lg={12}>
            <Card title="Permanent Schedule" bordered={false}>
              {Object.keys(permanentSchedule).map((day) => (
                <div key={day} className="mb-4 flex items-center">
                  <h4 className="font-bold mr-2">{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
                  <TimePicker
                    placeholder="Start Time"
                    format="h:mm a"
                    use12Hours
                    value={permanentSchedule[day].start}
                    onChange={(time) => handlePermanentScheduleChange(day, 'start', time)}
                    className="mr-2"
                  />
                  {!permanentSchedule[day].eightHourShift && (
                    <TimePicker
                      placeholder="End Time"
                      format="h:mm a"
                      use12Hours
                      value={permanentSchedule[day].end}
                      onChange={(time) => handlePermanentScheduleChange(day, 'end', time)}
                      className="mr-2"
                    />
                  )}
                  <Checkbox
                    checked={permanentSchedule[day].eightHourShift}
                    onChange={(e) => handlePermanentScheduleChange(day, 'eightHourShift', e.target.checked)}
                    className="mr-2"
                  >
                    8 Hour Shift
                  </Checkbox>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => updatePermanentSchedule(day, permanentSchedule[day])}
                    className="mr-2"
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => deletePermanentSchedule(day)}
                    danger
                  />
                </div>
              ))}
              <Button type="primary" onClick={savePermanentSchedule}>
                Save Permanent Schedule
              </Button>
            </Card>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card title="Schedule" bordered={false}>
            <label className='mr-2 ml-2 text-md font-bold dark:text-gray-400'>Appointments:</label>
            <Switch
              checked={showAppointments}
              onChange={setShowAppointments}
              checkedChildren="Hide"
              unCheckedChildren="Show"
              style={{ marginBottom: 16 }}
            />
            <Calendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectSlot={(slotInfo) => setSelectedDate(slotInfo.start)}
              selectable
              onSelectEvent={(event) => {
                if (event.userId) {
                  setCurrentWorkHour(event);
                  setEditModalVisible(true);
                }
              }}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: event.color || 'lightblue'
                }
              })}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Edit Work Hours"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <Form
          initialValues={{
            start: moment(currentWorkHour?.start),
            end: moment(currentWorkHour?.end)
          }}
          onFinish={handleEditWorkHour}
          layout="inline"
        >
          <Form.Item name="start" rules={[{ required: true, message: 'Start time is required' }]}>
            <TimePicker placeholder="Start Time" format="h:mm a" use12Hours />
          </Form.Item>
          <Form.Item name="end" rules={[{ required: true, message: 'End time is required' }]}>
            <TimePicker placeholder="End Time" format="h:mm a" use12Hours />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title="Are you sure to delete this work hour?"
              onConfirm={() => handleDeleteWorkHour(currentWorkHour.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default StaffSchedule;