import React from 'react';
import { Typography, Row, Col, Card, Button } from 'antd';
import { SmileOutlined, TeamOutlined, RocketOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const AboutUs = () => {
  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-6xl mx-auto">
      <Title level={2} className="text-center text-indigo-900 dark:text-indigo-300 mb-8">
        About Us
      </Title>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} md={12}>
          <Card className="dark:bg-gray-800">
            <Title level={3} className="text-indigo-900 dark:text-indigo-300">
              Our Mission
            </Title>
            <Text className="text-gray-700 dark:text-gray-300">
              At PosturePortal, we are dedicated to revolutionizing the chiropractic industry by integrating all essential services into one seamless app. Our mission is to empower chiropractors with the tools they need to provide exceptional care and grow their practice.
            </Text>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="dark:bg-gray-800">
            <Title level={3} className="text-indigo-900 dark:text-indigo-300">
              Our Story
            </Title>
            <Text className="text-gray-700 dark:text-gray-300">
              Founded by a team of passionate chiropractors and tech enthusiasts, PosturePortal was born out of the need for a comprehensive solution that addresses the unique challenges faced by chiropractic practices. We believe in the power of technology to transform healthcare and are committed to making that a reality.
            </Text>
          </Card>
        </Col>
      </Row>

      <div className="my-8 text-center">
        <Title level={3} className="text-indigo-900 dark:text-indigo-300 mb-4">
          Why Choose Us?
        </Title>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} md={8}>
            <Card className="dark:bg-gray-800 text-center">
              <SmileOutlined className="text-4xl text-green-500 mb-4" />
              <Title level={4} className="text-indigo-900 dark:text-indigo-300">User-Friendly</Title>
              <Text className="text-gray-700 dark:text-gray-300">
                Our app is designed with simplicity in mind, ensuring that you can focus on what matters most: your patients.
              </Text>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card className="dark:bg-gray-800 text-center">
              <TeamOutlined className="text-4xl text-blue-500 mb-4" />
              <Title level={4} className="text-indigo-900 dark:text-indigo-300">Comprehensive</Title>
              <Text className="text-gray-700 dark:text-gray-300">
                From scheduling to billing, PosturePortal covers all aspects of your practice, so you don't have to juggle multiple apps.
              </Text>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card className="dark:bg-gray-800 text-center">
              <RocketOutlined className="text-4xl text-red-500 mb-4" />
              <Title level={4} className="text-indigo-900 dark:text-indigo-300">Innovative</Title>
              <Text className="text-gray-700 dark:text-gray-300">
                We are constantly updating our features to ensure you have access to the latest tools and technologies.
              </Text>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="text-center mt-8">
        <Button type="primary" size="large" className="bg-indigo-600 hover:bg-indigo-700">
          Join Us Today
        </Button>
      </div>
    </div>
  );
};

export default AboutUs;