import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailLink, isSignInWithEmailLink, confirmPasswordReset } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import logo from '../../assets/logo.png';
import { message } from 'antd';

const FinishSignUp = () => {
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const completeAction = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get('mode');

      if (mode === 'resetPassword') {
        setMessageText('Please enter your new password.');
      } else if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          setError('No email found. Please try again.');
          return;
        }
        try {
          const userCredential = await signInWithEmailLink(auth, email, window.location.href);
          const user = userCredential.user;
          window.localStorage.removeItem('emailForSignIn');
          setMessageText('Successfully signed in!');

          // Check if the email exists in the patients collection
          const patientDoc = await getDoc(doc(db, 'patients', email));
          const collectionName = patientDoc.exists() ? 'patients' : 'users';

          const userRef = doc(db, collectionName, user.uid);
          await setDoc(userRef, {
            email,
            role: collectionName === 'users' ? 'doctor' : 'patient',
            uid: user.uid,
            displayName: email.split('@')[0],
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          });

          message.success(`Welcome, ${user.displayName}!`);
          navigate('/');
        } catch (error) {
          console.error('Error during sign-in:', error);
          setError(error.message);
          setMessageText('');
        }
      } else if (mode === 'verify') {
        setMessageText('Email verified successfully!');
      } else if (mode === 'signIn') {
        setMessageText('You can now sign in with your email and password.');
      }
    };

    completeAction();
  }, [navigate]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get('oobCode');

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessageText('Password has been reset successfully!');
      navigate('/signin');
    } catch (error) {
      console.error('Error during password reset:', error);
      setError(error.message);
      setMessageText('');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-lg shadow-md">
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Logo" className="h-16" />
        </div>
        <h1 className="text-2xl font-bold text-center">Processing...</h1>
        {messageText && <p className="text-green-500">{messageText}</p>}
        {error && <p className="text-red-500">{error}</p>}
        {new URLSearchParams(window.location.search).get('mode') === 'resetPassword' && (
          <form onSubmit={handlePasswordReset} className="space-y-6">
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Reset Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default FinishSignUp;