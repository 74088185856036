import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

export const useVitals = (patientId) => {
  const [vitals, setVitals] = useState([]);

  useEffect(() => {
    if (!patientId) return;

    const q = query(collection(db, 'patients', patientId, 'vitals'), where('patientId', '==', patientId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const vitalsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVitals(vitalsData);
    });

    return () => unsubscribe();
  }, [patientId]);

  return vitals;
};