import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const PrescriptionManagement = () => {
  const { id } = useParams();
  const [prescriptions, setPrescriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPrescription, setNewPrescription] = useState({
    date: '',
    medication: '',
    totalDosage: '',
    instructions: '',
  });
  const [editingPrescriptionId, setEditingPrescriptionId] = useState(null);
  const [editingData, setEditingData] = useState({});
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      const q = query(collection(db, 'prescriptions'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const prescriptionsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPrescriptions(prescriptionsList);
      setLoading(false);
    };

    fetchPrescriptions();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPrescription({ ...newPrescription, [name]: value });
  };

  const handleAddPrescription = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'prescriptions'), {
        ...newPrescription,
        patientId: id,
        timestamp: new Date(),
      });
      setNewPrescription({
        date: '',
        medication: '',
        totalDosage: '',
        instructions: '',
      });
      const q = query(collection(db, 'prescriptions'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const prescriptionsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPrescriptions(prescriptionsList);
    } catch (error) {
      console.error('Error adding prescription:', error);
    }
  };

  const handleEditPrescription = (prescription) => {
    setEditingPrescriptionId(prescription.id);
    setEditingData(prescription);
  };

  const handleUpdatePrescription = async () => {
    try {
      const docRef = doc(db, 'prescriptions', editingPrescriptionId);
      await updateDoc(docRef, editingData);
      setEditingPrescriptionId(null);
      const q = query(collection(db, 'prescriptions'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const prescriptionsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPrescriptions(prescriptionsList);
    } catch (error) {
      console.error('Error updating prescription:', error);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestPrescription = prescriptions[0];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Prescriptions
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show Form & History' : 'Hide Form & History'}
        </button>
      </h2>
      {latestPrescription && !editingPrescriptionId && (
        <div className="bg-gray-100 p-4 rounded-md shadow mb-4">
          <p><strong>Date:</strong> {latestPrescription.date}</p>
          <p><strong>Medication:</strong> {latestPrescription.medication}</p>
          <p><strong>Total Dosage:</strong> {latestPrescription.totalDosage}</p>
          <p><strong>Instructions:</strong> {latestPrescription.instructions}</p>
          <button
            onClick={() => handleEditPrescription(latestPrescription)}
            className="text-blue-500 underline mt-2"
          >
            Edit
          </button>
        </div>
      )}
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {prescriptions.slice(1).map(prescription => (
              <div key={prescription.id} className="mb-4">
                {editingPrescriptionId === prescription.id ? (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <label className="block text-sm font-medium text-gray-700">Date</label>
                    <input
                      type="date"
                      name="date"
                      value={editingData.date}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Medication</label>
                    <input
                      type="text"
                      name="medication"
                      value={editingData.medication}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Total Dosage</label>
                    <input
                      type="text"
                      name="total-dosage"
                      value={editingData.totalDosage}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Instructions</label>
                    <textarea
                      name="instructions"
                      value={editingData.instructions}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <button
                      onClick={handleUpdatePrescription}
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <p><strong>Date:</strong> {prescription.date}</p>
                    <p><strong>Medication:</strong> {prescription.medication}</p>
                    <p><strong>Total Dosage:</strong> {prescription.totalDosage}</p>
                    <p><strong>Instructions:</strong> {prescription.instructions}</p>
                    <button
                      onClick={() => handleEditPrescription(prescription)}
                      className="text-blue-500 underline mt-2"
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </ul>
          {!editingPrescriptionId && (
            <form onSubmit={handleAddPrescription} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newPrescription.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="medication" className="block text-sm font-medium text-gray-700">Medication</label>
                <input
                  type="text"
                  id="medication"
                  name="medication"
                  value={newPrescription.medication}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="total-dosage" className="block text-sm font-medium text-gray-700">Total Dosage</label>
                <input
                  type="text"
                  id="total-dosage"
                  name="total-dosage"
                  value={newPrescription.totalDosage}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">Instructions</label>
                <textarea
                  id="instructions"
                  name="instructions"
                  value={newPrescription.instructions}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Prescription
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default PrescriptionManagement;