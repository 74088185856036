import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Avatar, Input, Form, Select } from 'antd';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { collection, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Client } from 'square';
import { Breadcrumb } from 'antd';
import { IdcardOutlined, DollarOutlined, TeamOutlined, EditOutlined, FileAddOutlined, HeartOutlined, FolderOutlined, MedicineBoxOutlined, FileDoneOutlined } from '@ant-design/icons';

const DirectPaymentPage = ({ appId, locationId }) => {
  const { id } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [additionalFees, setAdditionalFees] = useState(0);
  const [copay, setCopay] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const squareClient = new Client({
    accessToken: process.env.SQUARE_ACCESS_TOKEN,
    environment: 'sandbox',
  });

  const { customersApi } = squareClient;

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!id) return;

      try {
        const patientDoc = await getDoc(doc(db, 'patients', id));
        if (patientDoc.exists()) {
          setPatientData(patientDoc.data());
          console.log('Patient data:', patientDoc.data());
        } else {
          console.log('No such patient!');
        }

        const appointmentsCollection = collection(db, 'appointments');
        const appointmentsSnapshot = await getDocs(appointmentsCollection);
        const appointmentsList = appointmentsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(appointment => appointment.patientId === id);
        setAppointments(appointmentsList);

        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    fetchPatientData();
  }, [id]);

  const handlePayment = async (token) => {
    try {
      const customerResponse = await customersApi.createCustomer({
        givenName: patientData.firstName,
        familyName: patientData.lastName,
        emailAddress: patientData.email,
        phoneNumber: patientData.contact,
        referenceId: id,
      });

      const customerId = customerResponse.result.customer.id;

      console.log('Processing payment with token:', token);

      const transactionTime = new Date();

      const paymentData = {
        amount: copay + additionalFees,
        copay: copay,
        additionalFees: additionalFees,
        date: transactionTime,
        appointmentId: selectedAppointment,
        tokenId: token.id,
        customerId: customerId,
        patientId: id,
        locationId: locationId,
        timestamp: transactionTime.toISOString(),
        transactionTime: transactionTime.toLocaleString(),
      };

      await addDoc(collection(db, 'patients', id, 'payments'), paymentData);

      console.log('Payment information saved successfully.');

    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${id}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${id}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${id}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${id}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
    {
      key: '6',
      label: (
        <Link to={`/patient-payment/${id}`}><DollarOutlined /> Patient Payment</Link>
      ),
    }
  ];

//   const patientPaymentMenuItems = [
//     {
//       key: '1',
//       label: (
//         <Link to={`/patient-payment/${id}`}><FileAddOutlined /> Patient Payment</Link>
//       ),
//     },
//   ];

  const totalAmount = copay + additionalFees;

  return (
    <div className="p-4 md:p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-7xl mx-auto">
      <Breadcrumb
        className="text-xl font-bold dark:text-white"
        items={[
          {
            title: (
              <Link to={`/patients`} className="flex items-center dark:text-gray-400">
                <TeamOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-xl">Patient List</span>
              </Link>
            ),
          },
          {
            title: (
              <Link to={`/dashboard/${id}`} className="flex items-center">
                <IdcardOutlined style={{ fontSize: '1rem' }} className='text-indigo-900 dark:text-indigo-300' /> <span className="text-sm sm:text-base md:text-lg">Dashboard</span>
              </Link>
            ),
            menu: { items: dashboardMenuItems },
          },
          {
            title: (
              <Link to={`/patient-payment/${id}`} className="flex items-center">
                <DollarOutlined style={{ fontSize: '1.50rem' }} className='text-indigo-900 dark:text-indigo-300' /> <span className="text-2xl text-indigo-900 dark:text-indigo-300">Patient Payment</span>
              </Link>
            ),
            // menu: { items: patientPaymentMenuItems },
          },
        ]}
      />

      {patientData && (
        <div className="mb-6 p-4 bg-indigo-100 dark:bg-indigo-800 rounded-md">
          <h2 className="text-xl font-semibold text-indigo-900 dark:text-indigo-300">Patient: {patientData.firstName} {patientData.lastName}</h2>
          <div className="flex items-center mt-2">
            <Avatar src={patientData.photoUrl} className="mr-4" size={96} />
            <div>
              <p className="text-lg font-semibold text-black dark:text-white">{patientData.email}</p>
              <p className="text-gray-600 dark:text-gray-400">{patientData.contact}</p>
              <p className="text-gray-600 dark:text-gray-400">{patientData.address}</p>
            </div>
          </div>
        </div>
      )}

      <Form layout="vertical">
        {patientData && (
          <Form.Item label="Select Appointment" className="mb-4">
            <Select
              placeholder="Select an appointment"
              value={selectedAppointment}
              onChange={setSelectedAppointment}
              className="w-full"
            >
              {appointments.map(appointment => (
                <Select.Option key={appointment.id} value={appointment.id}>
                  {appointment.date} - {appointment.visitType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Copay Amount" className="mb-4">
          <Input
            type="number"
            value={copay}
            onChange={e => setCopay(Number(e.target.value))}
            prefix="$"
            className="px-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-indigo-300"
          />
        </Form.Item>

        <Form.Item label="Additional Fees" className="mb-4">
          <Input
            type="number"
            value={additionalFees}
            onChange={e => setAdditionalFees(Number(e.target.value))}
            prefix="$"
            className="px-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-indigo-300"
          />
        </Form.Item>

        <div className="mb-6 p-4 bg-gray-100 dark:bg-gray-800 rounded-md">
          <h2 className="text-xl font-semibold text-indigo-900 dark:text-indigo-300">Total Amount</h2>
          <p className="text-lg font-semibold text-black dark:text-white">${totalAmount.toFixed(2)}</p>
        </div>

        {isLoaded && (
          <PaymentForm
            applicationId={appId}
            locationId={locationId}
            cardTokenizeResponseReceived={handlePayment}
          >
            <CreditCard />
          </PaymentForm>
        )}
      </Form>
    </div>
  );
};

export default DirectPaymentPage;