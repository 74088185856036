import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { auth, db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Button, Dropdown, Menu, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const InternalMessagesList = ({ messages, onSelectThread, selectedRecipient }) => {
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email,
        name: `${doc.data().firstName} ${doc.data().lastName}` || doc.data().email,
        photoURL: doc.data().photoURL || null
      })).filter(user => user.email !== currentUserEmail);
      setUsers(userList);
    };

    fetchUsers();
  }, [currentUserEmail]);

  const handleNewMessage = (email) => {
    onSelectThread(email);
  };

  const menu = (
    <Menu>
      {users.map(user => (
        <Menu.Item key={user.email} onClick={() => handleNewMessage(user.email)}>
          <div className="flex items-center">
            <Avatar src={user.photoURL} icon={!user.photoURL && <UserOutlined />} size={64} />
            <div className="ml-3">
              <span>{user.name}</span>
              <br />
              <span style={{ fontSize: '0.85em', color: 'gray' }}>({user.email})</span>
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const groupedMessages = messages.reduce((acc, message) => {
    const otherPartyEmail = message.sender === currentUserEmail ? message.recipient : message.sender;
    if (otherPartyEmail !== currentUserEmail) {
      if (!acc[otherPartyEmail]) {
        acc[otherPartyEmail] = [];
      }
      acc[otherPartyEmail].push(message);
    }
    return acc;
  }, {});

  const sortedConversations = Object.entries(groupedMessages)
    .filter(([otherPartyEmail]) => users.some(user => user.email === otherPartyEmail))
    .sort((a, b) => {
      const aLastMessage = a[1][0].timestamp ? a[1][0].timestamp.toDate() : new Date(0);
      const bLastMessage = b[1][0].timestamp ? b[1][0].timestamp.toDate() : new Date(0);
      return bLastMessage - aLastMessage;
    });

  return (
    <div className="internal-messages-list dark:bg-gray-800 h-full">
      <div className="flex justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
        <h2 className="text-xl font-semibold">Conversations</h2>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="primary">New Message</Button>
        </Dropdown>
      </div>
      {sortedConversations.length === 0 ? (
        <p className="p-4 text-gray-500">No messages found.</p>
      ) : (
        <ul className="divide-y divide-gray-200">
          {sortedConversations.map(([otherPartyEmail, messages]) => {
            const user = users.find(user => user.email === otherPartyEmail);
            return (
              <li 
                key={otherPartyEmail} 
                className={`cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out ${selectedRecipient === otherPartyEmail ? 'bg-blue-50' : ''}`}
                onClick={() => onSelectThread(otherPartyEmail)}
              >
                <div className="flex items-center p-4 space-y-2">
                  <Avatar src={user?.photoURL} icon={!user?.photoURL && <UserOutlined />} size={64} />
                  <div className="ml-3">
                    <span className="font-medium text-gray-900">{user?.name}</span>
                    <br />
                    <span style={{ fontSize: '0.85em', color: 'gray' }}>({user?.email})</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

InternalMessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    sender: PropTypes.string.isRequired,
    recipient: PropTypes.string.isRequired,
    timestamp: PropTypes.object,
    content: PropTypes.string.isRequired,
  })).isRequired,
  onSelectThread: PropTypes.func.isRequired,
  selectedRecipient: PropTypes.string,
};

export default InternalMessagesList;