import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust the import based on your Firebase setup
import { addDoc, collection } from 'firebase/firestore';
import { Button, Modal, message } from 'antd';

const today = new Date().toISOString().split('T')[0];

const PatientIntake = ({patientId}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    date: today,
    age: '',
    dob: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    homePhone: '',
    cellPhone: '',
    email: '',
    emergencyContact: '',
    emergencyPhone: '',
    objectives: '',
    symptoms: {},
    effects: {},
    isAccident: '',
    accidentType: '',
    otherAccidentType: '',
    accidentDate: '',
    insuranceType: '',
    otherInsuranceType: '',
    wantToSolveProblem: '',
    healthGoals: {},
    referralSource: '',
    otherReferralSource: '',
    additionalComments: '',
    consentToTreatment: false
  });

  console.log('patientId', patientId)

  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showSuccessMessage = () => {
    messageApi.open({
      type: 'success',
      content: 'Patient intake form saved successfully!',
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSubmitted(true);
  };

  const handleTextChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleCheckboxChange = (field, option, isChecked) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [option]: isChecked,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formTitle = `Patient Intake - ${new Date().toLocaleDateString()}`;
      await addDoc(collection(db, 'patients', patientId, 'forms'), {
        title: formTitle,
        date: today,
        ...formData,
        timestamp: new Date(),
      });
      showSuccessMessage();
      setSubmitted(true);
      localStorage.setItem(`submitted-intake-${patientId}-${today}`, true);
    } catch (error) {
      console.error('Error saving form data: ', error);
    }
  };

  useEffect(() => {
    const isSubmitted = localStorage.getItem(`submitted-intake-${patientId}-${today}`);
    if (isSubmitted) {
      setIsModalOpen(true); // Open modal for resubmission confirmation
    }
  }, [patientId]);

  const symptoms = [
    'Low Back Pain', 'Pain Down Legs', 'Nervous', 'Pain between Shoulder Blades',
    'Numbing/Tingling In Legs or Feet', 'Muscle Tension', 'Tension across Top of Shoulders',
    'Numbing/Tingling In Arms or Hands', 'Wrist Pain', 'Neck Pain', 'Ringing in Ears', 
    'Allergies', 'Tension Headaches', 'Dizziness', 'Digestive Problems', 'Migraine Headaches',
    'Tired, Fatigued', 'Weight Trouble'
  ];

  const effects = [
    { category: 'Does this cause you to be:', options: ['Moody', 'Irritable', 'Interrupt Sleep', 'Restricted on Daily Activities'] },
    { category: 'Does this affect your work:', options: ['Decision Making', 'Poor Attitude', 'Decreased Productivity', 'Exhausted at End of Day'] },
    { category: 'Does this affect your life:', options: ['Lose Patience with Spouse or Children', 'Restricts Household Duties', 'Hinders Ability to Exercise or Participate in Sports', 'Interferes with Ability to Participate in Hobbies'] }
  ];

  const healthGoals = [
    'Get rid of discomfort/pain', 'Prevent Future Health Problems', 'Be Healthier 5 Years From Now'
  ];

  return (
    <div className={`p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-4xl mx-auto ${submitted ? 'animate-fadeOut' : ''}`}>
      {contextHolder}
      {!submitted && (
        <>
          <h2 className="text-2xl font-bold mb-6 text-center">Patient Intake</h2>
          <form onSubmit={handleSubmit}>
            {/* Patient Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                id='first-name'
                autoComplete="given-name"
                placeholder="Patient's First Name"
                value={formData.firstName}
                onChange={(e) => handleTextChange('firstName', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"          
                />
              <input
                type="text"
                id='middle-name'
                placeholder="Patient's Middle Name"
                value={formData.middleName}
                onChange={(e) => handleTextChange('middleName', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"          
                />
              <input
                type="text"
                id='last-name'
                placeholder="Patient's Last Name"
                value={formData.lastName}
                onChange={(e) => handleTextChange('lastName', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"          />
              <input
                type="date"
                id='date'
                placeholder="Today's Datee"
                value={formData.date}
                readOnly
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100"
              />
            </div>

            {/* Age & DOB*/}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <input
                type="number"
                id='age'
                placeholder="Age"
                value={formData.age}
                onChange={(e) => handleTextChange('age', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
              <input
                type="date"
                id='date-of-birth'
                placeholder="DOB"
                value={formData.dob}
                onChange={(e) => handleTextChange('dob', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Address */}
            <div className='grid grid-cols-1 gap-4 mt-4'>
              <input
                type="text"
                id='address'
                autoComplete="shipping street-address"
                placeholder="Address"
                value={formData.address}
                onChange={(e) => handleTextChange('address', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>

            {/* City, State, Zip*/}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <input
                type="text"
                id='city'
                placeholder="City"
                value={formData.city}
                onChange={(e) => handleTextChange('city', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
              <input
                type="text"
                id='state'
                placeholder="State"
                value={formData.state}
                onChange={(e) => handleTextChange('state', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
              <input
                type="text"
                id='zip'
                placeholder="Zip"
                value={formData.zip}
                onChange={(e) => handleTextChange('zip', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>

            {/* Contact Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <input
                type="tel"
                id='home-phone'
                placeholder="Home Phone"
                value={formData.homePhone}
                onChange={(e) => handleTextChange('homePhone', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
              <input
                type="tel"
                id='cell-phone'
                placeholder="Cell Phone"
                value={formData.cellPhone}
                onChange={(e) => handleTextChange('cellPhone', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>

            <input
              type="email"
              id='email'
              autoComplete="off"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => handleTextChange('email', e.target.value)}
              className="w-full px-4 py-2 mt-4 border rounded-md"
            />

            {/* Emergency Contact */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <input
                type="text"
                id='emergency-contact'
                placeholder="Emergency Contact"
                value={formData.emergencyContact}
                onChange={(e) => handleTextChange('emergencyContact', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
              <input
                type="tel"
                id='emergency-contact-phone'
                placeholder="Emergency Contact Phone"
                value={formData.emergencyPhone}
                onChange={(e) => handleTextChange('emergencyPhone', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Objectives */}
            <div className="mt-4">
              <label 
              className="block mb-2"
              htmlFor="objectives"
              >
                What are your objectives in consulting our office?
              </label>
              <textarea
                value={formData.objectives}
                id='objectives'
                onChange={(e) => handleTextChange('objectives', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                rows="3"
              />
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Symptoms */}
            <div className="mt-6">
              <h3 className="font-semibold mb-2">Check any of the following symptoms you have experienced in the past 6 months:</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {symptoms.map((symptom, index) => (
                  <label key={index} className="flex items-center" htmlFor={`symptom-${index}`}>
                    <input
                      type="checkbox"
                      id={`symptom-${index}`}
                      checked={formData.symptoms[symptom] || false}
                      onChange={(e) => handleCheckboxChange('symptoms', symptom, e.target.checked)}
                      className="mr-2"
                    />
                    {symptom}
                  </label>
                ))}
              </div>
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Effects */}
            {effects.map((effect, index) => (
              <div key={index} className="mt-6">
                <h3 className="font-semibold mb-2">{effect.category}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  {effect.options.map((option, optionIndex) => (
                    <label key={optionIndex} className="flex items-center" htmlFor={`effect-${index}-${optionIndex}`}>
                      <input
                        type="checkbox"
                        id={`effect-${index}-${optionIndex}`}
                        checked={formData.effects[option] || false}
                        onChange={(e) => handleCheckboxChange('effects', option, e.target.checked)}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </div>
            ))}

            <hr className="my-9 border-purple-500" />

            {/* Accident and Insurance */}
            <div className="mt-6">
              <label 
              className="block mb-2"
              htmlFor='isAccident'
              >
                Is your condition due to an accident?</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="isAccident"
                    id='isAccident'
                    value="yes"
                    checked={formData.isAccident === 'yes'}
                    onChange={() => handleTextChange('isAccident', 'yes')}
                    className="mr-2"
                  />
                  Yes
                </label>
                <label className="flex items-center" htmlFor='isAccident'>
                  <input
                    type="radio"
                    name="isAccident"
                    value="no"
                    checked={formData.isAccident === 'no'}
                    onChange={() => handleTextChange('isAccident', 'no')}
                    className="mr-2"
                  />
                  No
                </label>
              </div>
            </div>

            {formData.isAccident === 'yes' && (
              <div className="mt-4">
                <label className="block mb-2">Type of Accident:</label>
                <div className="flex space-x-4">
                  {['Auto', 'Work', 'Home', 'Other'].map((type) => (
                    <label key={type} className="flex items-center" htmlFor='accidentType'>
                      <input
                        type="radio"
                        name="accidentType"
                        value={type.toLowerCase()}
                        checked={formData.accidentType === type.toLowerCase()}
                        onChange={() => handleTextChange('accidentType', type.toLowerCase())}
                        className="mr-2"
                      />
                      {type}
                    </label>
                  ))}
                </div>
                {formData.accidentType === 'other' && (
                  <input
                    type="text"
                    id='other-accident-type'
                    placeholder="Specify other accident type"
                    value={formData.otherAccidentType}
                    onChange={(e) => handleTextChange('otherAccidentType', e.target.value)}
                    className="mt-2 w-full px-4 py-2 border rounded-md"
                  />
                )}
                  <div className="mt-4">
                    <label className='block mb-2'>Accident Date:</label>
                    <input
                      type="date"
                      id='accident-date'
                      placeholder="Accident Date"
                      value={formData.accidentDate}
                      onChange={(e) => handleTextChange('accidentDate', e.target.value)}
                      className="w-full px-4 py-2 border rounded-md"                />
                  </div>
              </div>
            )}

            <hr className="my-9 border-purple-500" />

            <div className="mt-6">
              <label 
              className="block mb-2"
              htmlFor='insuranceType'
              >
                What type of insurance do you have?
              </label>
              <select
                value={formData.insuranceType}
                onChange={(e) => handleTextChange('insuranceType', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                id='insuranceType'
              >
                <option value="">Select insurance type</option>
                <option value="none">None</option>
                <option value="hmo">HMO</option>
                <option value="ppo">PPO</option>
                <option value="kaiser">Kaiser</option>
                <option value="anthem">Anthem</option>
                <option value="other">Other</option>
              </select>
              {formData.insuranceType === 'other' && (
                <input
                  type="text"
                  id='other-insurance-type'
                  placeholder="Specify other insurance type"
                  value={formData.otherInsuranceType}
                  onChange={(e) => handleTextChange('otherInsuranceType', e.target.value)}
                  className="mt-2 w-full px-4 py-2 border rounded-md"
                />
              )}
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Health Goals */}
            <div className="mt-6">
              <h3 className="font-semibold mb-2">What are your overall health goals? (Check all that apply)</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {healthGoals.map((goal, index) => (
                  <label key={index} className="flex items-center" htmlFor={`health-goal-${index}`}>
                    <input
                      type="checkbox"
                      id={`health-goal-${index}`}
                      checked={formData.healthGoals[goal] || false}
                      onChange={(e) => handleCheckboxChange('healthGoals', goal, e.target.checked)}
                      className="mr-2"
                    />
                    {goal}
                  </label>
                ))}
              </div>
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Referral Source */}
            <div className="mt-6">
              <label 
              className="block mb-2"
              htmlFor='referralSource'
              >
                How did you hear about us?
              </label>
              <select
                value={formData.referralSource}
                onChange={(e) => handleTextChange('referralSource', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                id='referralSource'
              >
                <option value="">Select referral source</option>
                <option value="friend">Friend or Family</option>
                <option value="doctor">Doctor Referral</option>
                <option value="internet">Internet Search</option>
                <option value="social">Social Media</option>
                <option value="advertisement">Advertisement</option>
                <option value="other">Other</option>
              </select>
              {formData.referralSource === 'other' && (
                <input
                  type="text"
                  placeholder="Please specify"
                  value={formData.otherReferralSource}
                  onChange={(e) => handleTextChange('otherReferralSource', e.target.value)}
                  className="mt-2 w-full px-4 py-2 border rounded-md"
                />
              )}
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Additional Comments */}
            <div className="mt-6">
              <label 
              className="block mb-2"
              htmlFor='additionalComments'
              >
                Additional Comments or Concerns
              </label>
              <textarea
                value={formData.additionalComments}
                onChange={(e) => handleTextChange('additionalComments', e.target.value)}
                className="w-full px-4 py-2 border rounded-md"
                id='additionalComments'
                rows="4"
              />
            </div>

            <hr className="my-9 border-purple-500" />

            {/* Consent to Treatment */}
            <div className="mt-6">
              <label className="flex items-center" htmlFor='consent-to-treatment'>
                <input
                  type="checkbox"
                  id='consent-to-treatment'
                  checked={formData.consentToTreatment}
                  onChange={(e) => handleTextChange('consentToTreatment', e.target.checked)}
                  className="mr-2"
                />
                <span>I consent to treatment and agree to the terms and conditions</span>
              </label>
            </div>

            {/* Submit Button */}
            <button type="submit" className="w-full bg-blue-500 text-white py-2 mt-6 rounded-md">
              Submit Form
            </button>
          </form>
        </>
      )}
      <Modal
        title="Confirm Resubmission"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>You have already submitted this form for today. Are you sure you want to resubmit?</p>
      </Modal>
    </div>
  );
};

export default PatientIntake;