import React from 'react';
import { Line } from 'react-chartjs-2';

const VitalsGraph = ({ vitals }) => {
  const prepareChartData = () => {
    if (!vitals || vitals.length === 0) {
      return {
        labels: [],
        datasets: []
      };
    }

    const sortedVitals = [...vitals].sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
    
    const labels = sortedVitals.map(vital => new Date(vital.createdAt.seconds * 1000).toLocaleDateString());
    const systolicData = sortedVitals.map(vital => vital.systolic);
    const diastolicData = sortedVitals.map(vital => vital.diastolic);
    const pulseData = sortedVitals.map(vital => vital.pulse);
    const respiratoryRateData = sortedVitals.map(vital => vital.respiratoryRate);
    const oxygenSaturationData = sortedVitals.map(vital => vital.oxygenSaturation);
    const weightData = sortedVitals.map(vital => vital.weightLb);
    const temperatureData = sortedVitals.map(vital => vital.temperatureF);

    return {
      labels,
      datasets: [
        {
          label: 'Systolic',
          data: systolicData,
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
        },
        {
          label: 'Diastolic',
          data: diastolicData,
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
        },
        {
          label: 'Pulse',
          data: pulseData,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
        {
          label: 'Respiratory Rate',
          data: respiratoryRateData,
          borderColor: 'rgba(153, 102, 255, 1)',
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
        },
        {
          label: 'Oxygen Saturation',
          data: oxygenSaturationData,
          borderColor: 'rgba(255, 159, 64, 1)',
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
        },
        {
          label: 'Weight (lbs)',
          data: weightData,
          borderColor: 'rgba(255, 206, 86, 1)',
          backgroundColor: 'rgba(255, 206, 86, 0.2)',
        },
        {
          label: 'Temperature (°F)',
          data: temperatureData,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
      ],
    };
  };

  if (!vitals || vitals.length === 0) {
    return <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-4xl mx-auto">Loading vitals data...</div>;
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Vitals Over Time</h2>
      <Line data={prepareChartData()} />
    </div>
  );
};

export default VitalsGraph;