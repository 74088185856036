import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, addDoc, getDoc, getDocs, query, where, orderBy, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import axios from 'axios';
import { HeartOutlined, DollarOutlined, IdcardOutlined, FileDoneOutlined, TeamOutlined, MedicineBoxOutlined, FolderOutlined, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Flex, Select } from 'antd';
import PatientHeader from '../common/PatientHeader';


const PrescriptionManagementPage = () => {
  const { id } = useParams();
  const [prescriptions, setPrescriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [newPrescription, setNewPrescription] = useState({
    date: '',
    medication: '',
    dosage: '',
    instructions: '',
    genericName: '',
    activeIngredients: [],
    numberOfDoses: 1, // New field for number of doses
    dosageForm: '', // New field for dosage form
    dosageOptions: [], // New field for dosage options
  });
  const [totalDosage, setTotalDosage] = useState(0); // State to hold total dosage
  const [editingPrescriptionId, setEditingPrescriptionId] = useState(null);
  const [editingData, setEditingData] = useState({});
  const [patientData, setPatientData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState([]);

  // Fetch prescriptions from Firestore
  const fetchPrescriptions = async () => {
    const q = query(collection(db, 'prescriptions'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(q);
    const prescriptionsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setPrescriptions(prescriptionsList);
    setLoading(false);
  };

  useEffect(() => {
    fetchPrescriptions(); // Call fetchPrescriptions when the component mounts
  }, [id]);

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!id) return;

      try {
        const patientDoc = await getDoc(doc(db, 'patients', id));
        if (patientDoc.exists()) {
          setPatientData(patientDoc.data());
          console.log('Patient data:', patientDoc.data());
        } else {
          console.log('No such patient!');
        }

        const appointmentsCollection = collection(db, 'appointments');
        const appointmentsSnapshot = await getDocs(appointmentsCollection);
        const appointmentsList = appointmentsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(appointment => appointment.patientId === id);
        setAppointments(appointmentsList);

        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    fetchPatientData();
  }, [id]);

  const handleSearchChange = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length > 2) { 
      try {
        const response = await axios.get(`https://api.fda.gov/drug/drugsfda.json?search=products.brand_name:"${term}" OR openfda.generic_name:"${term}"&limit=5`);
        console.log('Search Results:', response.data.results); 
        setSearchResults(response.data.results);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSelectMedication = (medication) => {
    const productOptions = medication.products; // Get all product options
    const firstProduct = productOptions[0]; // Assuming you want the first product for initial selection
    const strength = parseFloat(firstProduct.active_ingredients[0].strength.match(/(\d+(\.\d+)?)/)[0]) || 0; // Extract numeric strength

    // Attempt to set the generic name from the openfda object
    let genericName = (medication.openfda && medication.openfda.generic_name && medication.openfda.generic_name.length > 0) 
                   ? medication.openfda.generic_name[0] 
                   : ''; // First method

    // Backup method: Use the first active ingredient name if generic name is not available
    if (!genericName) {
        const activeIngredients = firstProduct.active_ingredients.map(ingredient => ingredient.name);
        genericName = activeIngredients.length > 0 ? activeIngredients.join(', ') : ''; // Join active ingredient names
    }

    setNewPrescription({
      ...newPrescription,
      medication: firstProduct.brand_name,
      dosage: strength, // Set dosage from active ingredient strength
      dosageForm: firstProduct.dosage_form, // Set dosage form
      genericName: genericName, // Set the generic name
      activeIngredients: firstProduct.active_ingredients, // Store active ingredients
      numberOfDoses: 1, // Reset number of doses when a new medication is selected
      dosageOptions: productOptions, // Store all dosage options
    });
    setTotalDosage(strength); // Initialize total dosage
    setSearchResults([]);
  };

  const handleDoseChange = (e) => {
    const numberOfDoses = parseInt(e.target.value, 10) || 0; // Get the number of doses
    setNewPrescription({ ...newPrescription, numberOfDoses });
    
    // Calculate total dosage based on the selected medication's dosage
    const dosageValue = parseFloat(newPrescription.dosage) || 0; // Assuming dosage is in mg or similar
    const total = dosageValue * numberOfDoses;
    setTotalDosage(total); // Update total dosage
  };

  const handleAddPrescription = async (e) => {
    e.preventDefault();
    try {
        if (editingPrescriptionId) {
            // Update existing prescription
            const prescriptionRef = doc(db, 'prescriptions', editingPrescriptionId);
            await updateDoc(prescriptionRef, {
                ...newPrescription,
                totalDosage,
                patientId: id,
                timestamp: new Date(),
            });
            setEditingPrescriptionId(null); // Reset editing state
        } else {
            // Add new prescription
            await addDoc(collection(db, 'prescriptions'), {
                ...newPrescription,
                totalDosage,
                patientId: id,
                timestamp: new Date(),
            });
        }
        resetForm(); // Reset the form after adding/updating
        fetchPrescriptions(); // Refresh prescriptions
    } catch (error) {
        console.error('Error adding/updating prescription:', error);
    }
  };

  const handleEditPrescription = (prescription) => {
    setEditingPrescriptionId(prescription.id);
    setEditingData({
        date: prescription.date,
        medication: prescription.medication,
        dosage: prescription.dosage,
        instructions: prescription.instructions,
        genericName: prescription.genericName,
        activeIngredients: prescription.activeIngredients,
        numberOfDoses: prescription.numberOfDoses,
        dosageForm: prescription.dosageForm,
        dosageOptions: prescription.dosageOptions,
    });
    setNewPrescription({
        ...newPrescription,
        ...editingData, // Populate the form with the editing data
    });
  };

  // Render active ingredients
  const renderActiveIngredients = () => {
    return newPrescription.activeIngredients.map((ingredient, index) => (
      <div key={index}>
        <strong>{ingredient.name}:</strong> {ingredient.strength}
      </div>
    ));
  };

  const resetForm = () => {
    setNewPrescription({
        date: '',
        medication: '',
        dosage: '',
        instructions: '',
        genericName: '',
        activeIngredients: [],
        numberOfDoses: 1,
        dosageForm: '',
        dosageOptions: [],
    });
    setTotalDosage(0);
  };

  const handleDeletePrescription = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this prescription?");
    if (confirmDelete) {
        try {
            const prescriptionRef = doc(db, 'prescriptions', id);
            await deleteDoc(prescriptionRef);
            fetchPrescriptions(); // Refresh prescriptions after deletion
        } catch (error) {
            console.error('Error deleting prescription:', error);
        }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${id}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${id}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${id}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${id}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
    {
      key: '6',
      label: (
        <Link to={`/patient-payment/${id}`}><DollarOutlined /> Patient Payment</Link>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-2xl mx-auto mt-8 lg:max-w-4xl">
      <Breadcrumb
        className='text-xl font-bold'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Patient List</span></Link>,
          },
          {
            title: 
              <Link to={`/dashboard/${id}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Dashboard</span></Link>,
              menu: { items: dashboardMenuItems },
          },
          {
            title: 
              <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined style={{ fontSize: "1.50rem" }} className='text-indigo-900'/><span className='text-2xl text-indigo-900'> Prescription Management</span></Link>,
          },
        ]}
      />

      {patientData && <PatientHeader patientData={patientData} patientId={id} />}

      <h2 className="text-xl text-gray-500 font-bold mb-4">Input a Prescription</h2>
      <input
        type="text"
        placeholder="Search for medication..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md"
      />
      {searchResults.length > 0 && (
        <ul className="bg-gray-100 border border-gray-300 rounded-md mt-2">
          {searchResults.map((medication) => {
            const product = medication.products[0]; // Correctly access the product from the medication
            return (
              <li
                key={medication.application_number}
                onClick={() => handleSelectMedication(medication)}
                className="p-2 cursor-pointer hover:bg-gray-200"
              >
                <div>
                  <strong>{product.brand_name}</strong> - {product.dosage_form}
                </div>
                <div className="text-sm text-gray-600">
                  {product.active_ingredients.map((ingredient) => (
                    <span key={ingredient.name}>
                      {ingredient.name} ({ingredient.strength}){' '}
                    </span>
                  ))}
                </div>
              </li>
            );
          })}
        </ul>
      )}
      <form onSubmit={handleAddPrescription} className="space-y-4 mt-4">
        <div>
          <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
          <input
            type="date"
            id="date"
            name="date"
            value={newPrescription.date}
            onChange={(e) => setNewPrescription({ ...newPrescription, date: e.target.value })}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="medication" className="block text-sm font-medium text-gray-700">Medication</label>
          <input
            type="text"
            id="medication"
            name="medication"
            value={newPrescription.medication}
            onChange={(e) => setNewPrescription({ ...newPrescription, medication: e.target.value })}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="dosage" className="block text-sm font-medium text-gray-700">Dosage</label>
          <select
            id="dosage"
            name="dosage"
            value={newPrescription.dosage}
            onChange={(e) => {
              const selectedDosage = newPrescription.dosageOptions.find(option => option.active_ingredients[0].strength === e.target.value);
              setNewPrescription({ 
                ...newPrescription, 
                dosage: selectedDosage ? selectedDosage.active_ingredients[0].strength : '' 
              });
            }}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
            required
          >
            {newPrescription.dosageOptions && newPrescription.dosageOptions.map((option, index) => (
              <option key={index} value={option.active_ingredients[0].strength}>
                {option.brand_name} - {option.active_ingredients.map(ing => `${ing.name} (${ing.strength})`).join(', ')}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="dosageForm" className="block text-sm font-medium text-gray-700">Dosage Form</label>
          <input
            type="text"
            id="dosageForm"
            name="dosageForm"
            value={newPrescription.dosageForm}
            readOnly // Make it read-only since it's populated from the search
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="genericName" className="block text-sm font-medium text-gray-700">Generic Name</label>
          <input
            type="text"
            id="genericName"
            name="genericName"
            value={newPrescription.genericName}
            readOnly // Make it read-only since it's populated from the search
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="numberOfDoses" className="block text-sm font-medium text-gray-700">Number of Doses</label>
          <input
            type="number"
            id="numberOfDoses"
            name="numberOfDoses"
            value={newPrescription.numberOfDoses}
            onChange={handleDoseChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
            min="1"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Total Dosage</label>
          <input
            type="text"
            value={`${totalDosage} mg`} // Display total dosage
            readOnly
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Active Ingredients</label>
          <div className="mt-1">
            {renderActiveIngredients()}
          </div>
        </div>
        <div>
          <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">Instructions</label>
          <textarea
            id="instructions"
            name="instructions"
            value={newPrescription.instructions}
            onChange={(e) => setNewPrescription({ ...newPrescription, instructions: e.target.value })}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
        >
          Add Prescription
        </button>
      </form>
      {/* Add code to display existing prescriptions */}
      <ul className="mt-4">
        {prescriptions.map((prescription) => (
          <li key={prescription.id} className="bg-gray-100 p-4 rounded-md shadow">
            <p><strong>Date:</strong> {prescription.date}</p>
            <p><strong>Medication:</strong> {prescription.medication}</p>
            <p><strong>Dosage:</strong> {prescription.dosage}</p>
            <p><strong>Dosage Form:</strong> {prescription.dosageForm}</p>
            <p><strong>Total Dosage:</strong> {prescription.totalDosage} mg</p>
            <p><strong>Instructions:</strong> {prescription.instructions}</p>
            <button
              onClick={() => handleEditPrescription(prescription)}
              className="text-blue-500 underline mt-2"
            >
              Edit
            </button>
            <button
              onClick={() => handleDeletePrescription(prescription.id)}
              className="text-red-500 underline mt-2 ml-2"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrescriptionManagementPage;