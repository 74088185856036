import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const BillingAndPayments = () => {
  const { id } = useParams();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPayment, setNewPayment] = useState({
    date: '',
    amount: '',
    method: '',
    description: '',
  });
  const [editingPaymentId, setEditingPaymentId] = useState(null);
  const [editingData, setEditingData] = useState({});
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      const q = query(collection(db, 'payments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const paymentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPayments(paymentsList);
      setLoading(false);
    };

    fetchPayments();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPayment({ ...newPayment, [name]: value });
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'payments'), {
        ...newPayment,
        patientId: id,
        timestamp: new Date(),
      });
      setNewPayment({
        date: '',
        amount: '',
        method: '',
        description: '',
      });
      const q = query(collection(db, 'payments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const paymentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPayments(paymentsList);
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  const handleEditPayment = (payment) => {
    setEditingPaymentId(payment.id);
    setEditingData(payment);
  };

  const handleUpdatePayment = async () => {
    try {
      const docRef = doc(db, 'payments', editingPaymentId);
      await updateDoc(docRef, editingData);
      setEditingPaymentId(null);
      const q = query(collection(db, 'payments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const paymentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPayments(paymentsList);
    } catch (error) {
      console.error('Error updating payment:', error);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestPayment = payments[0];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Billing and Payments
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show Form & History' : 'Hide Form & History'}
        </button>
      </h2>
      {latestPayment && !editingPaymentId && (
        <div className="bg-gray-100 p-4 rounded-md shadow mb-4">
          <p><strong>Date:</strong> {latestPayment.date}</p>
          <p><strong>Amount:</strong> ${latestPayment.amount}</p>
          <p><strong>Method:</strong> {latestPayment.method}</p>
          <p><strong>Description:</strong> {latestPayment.description}</p>
          <button
            onClick={() => handleEditPayment(latestPayment)}
            className="text-blue-500 underline mt-2"
          >
            Edit
          </button>
        </div>
      )}
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {payments.slice(1).map(payment => (
              <div key={payment.id} className="mb-4">
                {editingPaymentId === payment.id ? (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <label className="block text-sm font-medium text-gray-700">Date</label>
                    <input
                      type="date"
                      name="date"
                      value={editingData.date}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Amount</label>
                    <input
                      type="number"
                      name="amount"
                      value={editingData.amount}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Method</label>
                    <input
                      type="text"
                      name="method"
                      value={editingData.method}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                      name="description"
                      value={editingData.description}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <button
                      onClick={handleUpdatePayment}
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <p><strong>Date:</strong> {payment.date}</p>
                    <p><strong>Amount:</strong> ${payment.amount}</p>
                    <p><strong>Method:</strong> {payment.method}</p>
                    <p><strong>Description:</strong> {payment.description}</p>
                    <button
                      onClick={() => handleEditPayment(payment)}
                      className="text-blue-500 underline mt-2"
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </ul>
          {!editingPaymentId && (
            <form onSubmit={handleAddPayment} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newPayment.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={newPayment.amount}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="method" className="block text-sm font-medium text-gray-700">Method</label>
                <input
                  type="text"
                  id="method"
                  name="method"
                  value={newPayment.method}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  id="description"
                  name="description"
                  value={newPayment.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Payment
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default BillingAndPayments;