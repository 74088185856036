import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const PatientProgress = () => {
  const { id } = useParams();
  const [progressEntries, setProgressEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newEntry, setNewEntry] = useState({
    date: '',
    painLevel: '',
    mobility: '',
    flexibility: '',
    strength: '',
    endurance: '',
    otherMetrics: '',
  });
  const [editEntry, setEditEntry] = useState(null);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchProgressEntries = async () => {
      const q = query(collection(db, 'progressEntries'), where('patientId', '==', id));
      const querySnapshot = await getDocs(q);
      const entriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProgressEntries(entriesList);
      setLoading(false);
    };

    fetchProgressEntries();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editEntry) {
      setEditEntry({ ...editEntry, [name]: value });
    } else {
      setNewEntry({ ...newEntry, [name]: value });
    }
  };

  const handleAddEntry = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'progressEntries'), {
        ...newEntry,
        patientId: id,
        timestamp: new Date(),
      });
      setNewEntry({
        date: '',
        painLevel: '',
        mobility: '',
        flexibility: '',
        strength: '',
        endurance: '',
        otherMetrics: '',
      });
      const q = query(collection(db, 'progressEntries'), where('patientId', '==', id));
      const querySnapshot = await getDocs(q);
      const entriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProgressEntries(entriesList);
    } catch (error) {
      console.error('Error adding progress entry:', error);
    }
  };

  const handleEditEntry = async (e) => {
    e.preventDefault();
    try {
      const entryRef = doc(db, 'progressEntries', editEntry.id);
      await updateDoc(entryRef, {
        ...editEntry,
        timestamp: new Date(),
      });
      setEditEntry(null);
      const q = query(collection(db, 'progressEntries'), where('patientId', '==', id));
      const querySnapshot = await getDocs(q);
      const entriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProgressEntries(entriesList);
    } catch (error) {
      console.error('Error editing progress entry:', error);
    }
  };

  const handleEditClick = (entry) => {
    setEditEntry(entry);
  };

  const data = {
    labels: progressEntries.map(entry => entry.date),
    datasets: [
      {
        label: 'Pain Level',
        data: progressEntries.map(entry => entry.painLevel),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
      {
        label: 'Mobility',
        data: progressEntries.map(entry => entry.mobility),
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: true,
      },
      {
        label: 'Flexibility',
        data: progressEntries.map(entry => entry.flexibility),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
      {
        label: 'Strength',
        data: progressEntries.map(entry => entry.strength),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        fill: true,
      },
      {
        label: 'Endurance',
        data: progressEntries.map(entry => entry.endurance),
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        fill: true,
      },
      {
        label: 'Other Metrics',
        data: progressEntries.map(entry => entry.otherMetrics),
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        fill: true,
      },
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Patient Progress
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show Form & History' : 'Hide Form & History'}
        </button>
      </h2>
      <div className="chart-container mb-8">
        <Line data={data} />
      </div>
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {progressEntries.map(entry => (
              <li key={entry.id} className="bg-gray-100 p-4 rounded-md shadow">
                <p><strong>Date:</strong> {entry.date}</p>
                <p><strong>Pain Level:</strong> {entry.painLevel}</p>
                <p><strong>Mobility:</strong> {entry.mobility}</p>
                <p><strong>Flexibility:</strong> {entry.flexibility}</p>
                <p><strong>Strength:</strong> {entry.strength}</p>
                <p><strong>Endurance:</strong> {entry.endurance}</p>
                <p><strong>Other Metrics:</strong> {entry.otherMetrics}</p>
                <button
                  onClick={() => handleEditClick(entry)}
                  className="mt-2 text-sm text-indigo-600 hover:text-indigo-900"
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
          {editEntry ? (
            <form onSubmit={handleEditEntry} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={editEntry.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="painLevel" className="block text-sm font-medium text-gray-700">Pain Level</label>
                <input
                  type="number"
                  id="painLevel"
                  name="painLevel"
                  value={editEntry.painLevel}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="mobility" className="block text-sm font-medium text-gray-700">Mobility</label>
                <input
                  type="number"
                  id="mobility"
                  name="mobility"
                  value={editEntry.mobility}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="flexibility" className="block text-sm font-medium text-gray-700">Flexibility</label>
                <input
                  type="number"
                  id="flexibility"
                  name="flexibility"
                  value={editEntry.flexibility}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="strength" className="block text-sm font-medium text-gray-700">Strength</label>
                <input
                  type="number"
                  id="strength"
                  name="strength"
                  value={editEntry.strength}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="endurance" className="block text-sm font-medium text-gray-700">Endurance</label>
                <input
                  type="number"
                  id="endurance"
                  name="endurance"
                  value={editEntry.endurance}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="otherMetrics" className="block text-sm font-medium text-gray-700">Other Metrics</label>
                <textarea
                  id="otherMetrics"
                  name="otherMetrics"
                  value={editEntry.otherMetrics}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Changes
              </button>
              <button
                type="button"
                onClick={() => setEditEntry(null)}
                className="w-full px-4 py-2 mt-2 font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Cancel
              </button>
            </form>
          ) : (
            <form onSubmit={handleAddEntry} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newEntry.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="painLevel" className="block text-sm font-medium text-gray-700">Pain Level</label>
                <input
                  type="number"
                  id="painLevel"
                  name="painLevel"
                  value={newEntry.painLevel}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="mobility" className="block text-sm font-medium text-gray-700">Mobility</label>
                <input
                  type="number"
                  id="mobility"
                  name="mobility"
                  value={newEntry.mobility}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="flexibility" className="block text-sm font-medium text-gray-700">Flexibility</label>
                <input
                  type="number"
                  id="flexibility"
                  name="flexibility"
                  value={newEntry.flexibility}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="strength" className="block text-sm font-medium text-gray-700">Strength</label>
                <input
                  type="number"
                  id="strength"
                  name="strength"
                  value={newEntry.strength}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="endurance" className="block text-sm font-medium text-gray-700">Endurance</label>
                <input
                  type="number"
                  id="endurance"
                  name="endurance"
                  value={newEntry.endurance}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="otherMetrics" className="block text-sm font-medium text-gray-700">Other Metrics</label>
                <textarea
                  id="otherMetrics"
                  name="otherMetrics"
                  value={newEntry.otherMetrics}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Progress Entry
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default PatientProgress;