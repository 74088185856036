import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const MedicalRecords = () => {
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newRecord, setNewRecord] = useState({
    date: '',
    description: '',
    doctor: '',
    notes: '',
  });
  const [editingRecordId, setEditingRecordId] = useState(null);
  const [editingData, setEditingData] = useState({});
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchRecords = async () => {
      const q = query(collection(db, 'medicalRecords'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const recordsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRecords(recordsList);
      setLoading(false);
    };

    fetchRecords();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord({ ...newRecord, [name]: value });
  };

  const handleAddRecord = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'medicalRecords'), {
        ...newRecord,
        patientId: id,
        timestamp: new Date(),
      });
      setNewRecord({
        date: '',
        description: '',
        doctor: '',
        notes: '',
      });
      const q = query(collection(db, 'medicalRecords'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const recordsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRecords(recordsList);
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  const handleEditRecord = (record) => {
    setEditingRecordId(record.id);
    setEditingData(record);
  };

  const handleUpdateRecord = async () => {
    try {
      const docRef = doc(db, 'medicalRecords', editingRecordId);
      await updateDoc(docRef, editingData);
      setEditingRecordId(null);
      const q = query(collection(db, 'medicalRecords'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const recordsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRecords(recordsList);
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestRecord = records[0];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Medical Records
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show Form & History' : 'Hide Form & History'}
        </button>
      </h2>
      {latestRecord && !editingRecordId && (
        <div className="bg-gray-100 p-4 rounded-md shadow mb-4">
          <p><strong>Date:</strong> {latestRecord.date}</p>
          <p><strong>Description:</strong> {latestRecord.description}</p>
          <p><strong>Doctor:</strong> {latestRecord.doctor}</p>
          <p><strong>Notes:</strong> {latestRecord.notes}</p>
          <button
            onClick={() => handleEditRecord(latestRecord)}
            className="text-blue-500 underline mt-2"
          >
            Edit
          </button>
        </div>
      )}
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {records.slice(1).map(record => (
              <div key={record.id} className="mb-4">
                {editingRecordId === record.id ? (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <label className="block text-sm font-medium text-gray-700">Date</label>
                    <input
                      type="date"
                      name="date"
                      value={editingData.date}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                      type="text"
                      name="description"
                      value={editingData.description}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Doctor</label>
                    <input
                      type="text"
                      name="doctor"
                      value={editingData.doctor}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Notes</label>
                    <textarea
                      name="notes"
                      value={editingData.notes}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <button
                      onClick={handleUpdateRecord}
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <p><strong>Date:</strong> {record.date}</p>
                    <p><strong>Description:</strong> {record.description}</p>
                    <p><strong>Doctor:</strong> {record.doctor}</p>
                    <p><strong>Notes:</strong> {record.notes}</p>
                    <button
                      onClick={() => handleEditRecord(record)}
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </ul>
          {!editingRecordId && (
            <form onSubmit={handleAddRecord} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newRecord.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  value={newRecord.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="doctor" className="block text-sm font-medium text-gray-700">Doctor</label>
                <input
                  type="text"
                  id="doctor"
                  name="doctor"
                  value={newRecord.doctor}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="notes" className="block text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={newRecord.notes}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Record
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default MedicalRecords;