import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { List, Button, Spin } from 'antd';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const notificationsCollection = collection(db, 'notifications'); // Ensure this collection name matches your Firestore setup
        const notificationSnapshot = await getDocs(notificationsCollection);
        const notificationList = notificationSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(notificationList);
      } catch (error) {
        console.error("Error fetching notifications: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="container dark:bg-gray-800 dark:text-gray-300 mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold mb-4">Notifications</h1>
      <Button onClick={handleBack} type="link" className="mb-4 dark:text-gray-300">Back</Button>
      {loading ? (
        <Spin tip="Loading notifications..." />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={notification => (
            <List.Item>
              <List.Item.Meta
                title={<strong>{notification.title || 'Notification'}</strong>}
                description={notification.body || 'No content available'}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default Notifications;