import React, { useEffect, useState } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const MessageViewer = ({ conversation, userId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const q = query(collection(db, 'messages'), where('conversationId', '==', conversation.id), orderBy('timestamp', 'asc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const msgs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(msgs);
    });

    return unsubscribe;
  }, [conversation.id]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    await addDoc(collection(db, 'messages'), {
      conversationId: conversation.id,
      senderId: userId,
      message: newMessage,
      timestamp: new Date(),
    });

    setNewMessage('');
  };

  return (
    <div>
      <div className="mb-4 dark:bg-gray-800">
        {messages.map((msg) => (
          <div key={msg.id} className={`p-2 my-2 ${msg.senderId === userId ? 'bg-blue-200' : 'bg-gray-200'}`}>
            <p>{msg.message}</p>
            <p className="text-xs text-gray-500">{new Date(msg.timestamp.seconds * 1000).toLocaleString()}</p>
          </div>
        ))}
      </div>
      <div className="flex">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="flex-grow p-2 border rounded"
        />
        <button onClick={handleSendMessage} className="p-2 bg-blue-500 text-white rounded ml-2">Send</button>
      </div>
    </div>
  );
};

export default MessageViewer;