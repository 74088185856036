import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust this import based on your Firebase setup
import { addDoc, collection } from 'firebase/firestore';
import { Button, Modal, message } from 'antd';

const today = new Date().toISOString().split('T')[0];

const ConsentToChiropracticCare = ({patientId}) => {
  const [formData, setFormData] = useState({
    understandRisks: false,
    consentToTreatment: false,
    signature: '',
    date: today,
  });

  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showSuccessMessage = () => {
    messageApi.open({
      type: 'success',
      content: 'Consent to Chiropractic Care form saved successfully!',
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSubmitted(true);
  };

  const handleTextChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formTitle = `Consent to Chiropractic Care - ${new Date().toLocaleDateString()}`;
      await addDoc(collection(db, 'patients', patientId, 'forms'), {
        title: formTitle,
        ...formData,
        timestamp: new Date(),
      });
      showSuccessMessage();
      setSubmitted(true);
      localStorage.setItem(`submitted-${patientId}-${today}`, true);
    } catch (error) {
      console.error('Error saving form data: ', error);
    }
  };

  useEffect(() => {
    const isSubmitted = localStorage.getItem(`submitted-${patientId}-${today}`);
    if (isSubmitted) {
      setIsModalOpen(true); // Open modal for resubmission confirmation
    }
  }, [patientId]);

  return (
    <div className={`p-6 bg-white rounded-lg shadow-md max-w-3xl mx-auto ${submitted ? 'animate-fadeOut' : ''}`}>
      {contextHolder}
      {!submitted && (
        <>
          <h2 className="text-2xl font-bold mb-4 text-center">CONSENT TO CHIROPRACTIC CARE</h2>
          
          <p className="mb-4 text-center italic">
            Congratulations for having chosen the safest and most natural health care program ever conceived: Chiropractic.
          </p>
          
          <p className="mb-4">
            This painless, logical, and effective approach to health has been serving everyday people for over 100 years. It is
            licensed in every state, and in many countries as well. Chiropractic has the least chance of side effects of any other
            type of health care. Mild headaches and muscles soreness may sometimes occur.
          </p>
          
          <h3 className="text-lg font-semibold mb-2">Let's look at a few statistics about possible serious side effects:</h3>
          
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              The #1 cause of death in the US is from correctly and incorrectly prescribed pharmaceutical drugs. (CDC, FDA,
              NIH sites, also Gary Null: Death By Medicine)
            </li>
            <li>
              Stroke is one of the most common causes of death in the US. With people going to doctors all the time it is probable
              that many will have had a recent doctor visit. But causation is another matter entirely.
            </li>
            <li>
              There is no absolutely known material risk of chiropractic care being greater than risks from medical treatment.
              In fact, when all the factors are taken together, deaths and injuries from a combination of medical mistakes and
              intentional drugs dwarf any injuries from chiropractic.
            </li>
            <li>
              Risk of stroke from chiropractic? Virtually zero chance of stroke from chiropractic. The largest study ever done –
              the 2008 study in Canada – www.ncbi.nlm.nih.gov/pmc/articles/PMC2271108 – looking at 12 million people over 9
              years, showed that 53% of strokes had visited their MD within 30 days prior, while only 4% had visited their DC.
              No evidence of excess risk of stroke associated with chiropractic care.
            </li>
            <li>
              In 2001 the Canadian Medical Association Journal found there is only a one-in-5.85-million risk that a cervical
              manipulation from an MD, PT, or DC would be followed by a stroke. Author David Cassidy, a professor of
              epidemiology at the University of Toronto said patients had already damaged the artery before seeking help from
              either a medical doctor or a chiropractor, and then the stroke occurred after the visit.
            </li>
          </ul>
          
          <p className="mb-4">
            Speaking of risks associated with chiropractic, we should look also at the risk associated with NOT GETTING
            adjusted. This risk was one of the 4 components of risk in the Association of Chiropractic Colleges guidelines on
            informed consent in 2008. Disc degeneration, loss of mobility, loss of overall tone, decreased quality of life – these
            are real risks of the untreated spine as time goes by.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-6 dark:bg-gray-800">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  id="understand-risks"
                  checked={formData.understandRisks || false}
                  onChange={(e) => handleTextChange('understandRisks', e.target.checked)}
                  className="mr-2"
                />
                <span>I fully understand these risks, the doctor has explained them to me, and I consent to chiropractic care.</span>
              </label>
            </div>
            
            <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  id="consent-to-treatment"
                  checked={formData.consentToTreatment || false}
                  onChange={(e) => handleTextChange('consentToTreatment', e.target.checked)}
                  className="mr-2"
                />
                <span>I consent to treatment.</span>
              </label>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                id='sign'
                placeholder="Sign"
                value={formData.signature || ''}
                onChange={(e) => handleTextChange('signature', e.target.value)}
                className="p-2 border rounded"
              />
              <input
                type="date"
                id='date'
                value={formData.date}
                readOnly
                className="p-2 border rounded"
              />
            </div>
            <button type="submit" className="w-full bg-blue-500 text-white py-2 mt-4 rounded-md">
              Submit Consent Form
            </button>
          </form>
        </>
      )}

      <Modal
        title="Confirm Resubmission"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>You have already submitted this form for today. Are you sure you want to resubmit?</p>
      </Modal>
    </div>
  );
};

export default ConsentToChiropracticCare;