import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDoc, getDocs, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import VitalsForm from '../forms/VitalsForm';
import VitalsList from '../lists/VitalsList';
import VitalsGraph from '../charts/VitalsGraph';
import { HeartOutlined, DollarOutlined, IdcardOutlined, FileDoneOutlined, TeamOutlined, MedicineBoxOutlined, FolderOutlined, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Flex, Select } from 'antd';
import PatientHeader from '../common/PatientHeader';


const VitalsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vitals, setVitals] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState([]);

  const fetchVitals = async () => {
    const q = query(collection(db, 'patients', id, 'vitals'), where('patientId', '==', id));
    const querySnapshot = await getDocs(q);
    const vitalsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setVitals(vitalsData);
  };

  useEffect(() => {
    const q = query(collection(db, 'patients', id, 'vitals'), where('patientId', '==', id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const vitalsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVitals(vitalsData);
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!id) return;

      try {
        const patientDoc = await getDoc(doc(db, 'patients', id));
        if (patientDoc.exists()) {
          setPatientData(patientDoc.data());
          console.log('Patient data:', patientDoc.data());
        } else {
          console.log('No such patient!');
        }

        const appointmentsCollection = collection(db, 'appointments');
        const appointmentsSnapshot = await getDocs(appointmentsCollection);
        const appointmentsList = appointmentsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(appointment => appointment.patientId === id);
        setAppointments(appointmentsList);


        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    fetchPatientData();
  }, [id]);

  const handleEdit = (vital) => {
    setEditingId(vital.id);
  };

  const handleDelete = async (vitalId) => {
    await deleteDoc(doc(db, 'patients', id, 'vitals', vitalId));
    fetchVitals();
  };

  const handleBackToPatientList = () => {
    navigate('/patients');
  };

  const handleGoToDashboard = () => {
    navigate(`/dashboard/${id}`);
  };

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${id}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${id}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${id}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${id}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
    {
      key: '6',
      label: (
        <Link to={`/patient-payment/${id}`}><DollarOutlined /> Patient Payment</Link>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-7xl mx-auto">
      <Breadcrumb
        className='text-xl font-bold'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Patient List</span></Link>,
          },
          {
            title: 
              <Link to={`/dashboard/${id}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Dashboard</span></Link>,
              menu: { items: dashboardMenuItems },
          },
          {
            title: 
              <Link to={`/vitals/${id}`}><HeartOutlined style={{ fontSize: "1.50rem" }} className='text-indigo-900'/><span className='text-2xl text-indigo-900'> Vitals</span></Link>,
          },
        ]}
      />
      {patientData && <PatientHeader patientData={patientData} patientId={id} />}

      {/* <div className="flex justify-between mb-4">
        <button
          onClick={handleBackToPatientList}
          className="px-4 py-2 font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Back to Patient List
        </button>
        <button
          onClick={handleGoToDashboard}
          className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to Dashboard
        </button>
      </div> */}
      {/* <h1 className="text-3xl font-bold text-center mb-6">Vitals Management</h1> */}
      <VitalsForm editingId={editingId} setEditingId={setEditingId} fetchVitals={fetchVitals} />
      <VitalsGraph vitals={vitals} />
      <VitalsList vitals={vitals} handleEdit={handleEdit} handleDelete={handleDelete} />
    </div>
  );
};

export default VitalsPage;