import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import InternalMessagesList from '../lists/InternalMessagesList';
import InternalMessagesThread from '../viewers/InternalMessagesThread';
import ComposeInternalMessage from '../messages/ComposeInternalMessage';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const InternalMessagesDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const fetchMessages = async () => {
    try {
      const q = query(
        collection(db, 'internalMessages'),
        where('archived', '==', false),
        orderBy('timestamp', 'desc')
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedMessages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).filter(message => 
          message.sender === currentUserEmail || message.recipient === currentUserEmail
        );
        setMessages(fetchedMessages);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [currentUserEmail]);

  const handleThreadSelect = async (recipient) => {
    setLoading(true);
    setSelectedRecipient(recipient);
    try {
      const userQuery = query(
        collection(db, 'internalMessages'),
        where('archived', '==', false),
        where('recipient', 'in', [recipient, currentUserEmail]),
        where('sender', 'in', [recipient, currentUserEmail]),
        orderBy('timestamp', 'asc')
      );
      const userSnapshot = await getDocs(userQuery);
      const userMessages = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSelectedThread(userMessages);
    } catch (error) {
      console.error('Error fetching user messages:', error);
      setSelectedThread([]);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageSent = () => {
    fetchMessages();
    if (selectedRecipient) {
      handleThreadSelect(selectedRecipient);
    }
  };

  if (loading) {
    return (
      <div className="flex dark:bg-gray-800 justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="internal-messages-dashboard bg-gray-100 dark:bg-gray-800 h-screen p-6">
      <div className="max-w-7xl mx-auto h-full flex flex-col">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Internal Messages Dashboard</h1>
        <div className="dashboard-content flex flex-grow bg-white rounded-lg shadow-lg overflow-hidden">
          <div className={`w-full md:w-1/3 border-r border-gray-200 ${isSidebarVisible ? 'block' : 'hidden'} md:block`}>
            <InternalMessagesList 
              messages={messages} 
              onSelectThread={handleThreadSelect} 
              selectedRecipient={selectedRecipient}
            />
          </div>
          <div className="w-full md:w-2/3 flex flex-col h-full">
            <Button 
              className="md:hidden mb-4" 
              icon={<MenuOutlined />} 
              onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            >
              Toggle Conversations
            </Button>
            <div className="flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100vh - 12rem)' }}>
              <InternalMessagesThread thread={selectedThread} />
            </div>
            <div className="border-t border-gray-200">
              <ComposeInternalMessage 
                onMessageSent={handleMessageSent} 
                selectedRecipient={selectedRecipient}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalMessagesDashboard;