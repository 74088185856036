import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const addMedicalRecord = async (recordData) => {
  try {
    await addDoc(collection(db, 'medicalRecords'), {
      ...recordData,
      timestamp: new Date(),
    });
  } catch (error) {
    console.error('Error adding medical record:', error);
  }
};