import React, { useState, useEffect } from 'react';
import { List, Avatar } from 'antd';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const PaymentHistoryPage = ({ patientId }) => {
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      if (!patientId) return;

      const paymentsCollection = collection(db, 'patients', patientId, 'payments');
      const paymentsSnapshot = await getDocs(paymentsCollection);
      const paymentsList = paymentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPaymentHistory(paymentsList);
    };

    fetchPaymentHistory();
  }, [patientId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-4xl mx-auto mt-8">
      <h1 className="text-2xl font-bold text-center mb-6 text-indigo-900 dark:text-indigo-300">Payment History</h1>

      <div className="max-h-96 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-md">
        <List
          itemLayout="horizontal"
          dataSource={paymentHistory}
          renderItem={payment => (
            <List.Item className="p-4 hover:bg-gray-100 dark:hover:bg-gray-700">
              <List.Item.Meta
                avatar={<Avatar icon={<i className="fas fa-receipt"></i>} />}
                title={<span className="text-lg font-semibold text-black dark:text-white">Transaction ID: {payment.id}</span>}
                description={
                  <div className="text-gray-600 dark:text-gray-400">
                    <p>Amount: ${payment.amount.toFixed(2)}</p>
                    <p>Copay: ${payment.copay.toFixed(2)}</p>
                    <p>Additional Fees: ${payment.additionalFees.toFixed(2)}</p>
                    <p>Date: {new Date(payment.date.seconds * 1000).toLocaleString()}</p>
                    <p>Location ID: {payment.locationId}</p>
                    <p>Transaction Time: {payment.transactionTime}</p>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default PaymentHistoryPage;