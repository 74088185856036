import React, { useState, useEffect } from 'react';
import { Avatar, Input, Form, List, Select } from 'antd';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { collection, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Client } from 'square';

const PaymentPage = ({ appId, locationId }) => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [additionalFees, setAdditionalFees] = useState(0);
  const [copay, setCopay] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const squareClient = new Client({
    accessToken: process.env.SQUARE_ACCESS_TOKEN,
    environment: 'sandbox',
  });

  const { customersApi } = squareClient;

  useEffect(() => {
    const fetchPatients = async () => {
      const patientsCollection = collection(db, 'patients');
      const patientsSnapshot = await getDocs(patientsCollection);
      const patientsList = patientsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPatients(patientsList);
      setFilteredPatients(patientsList);
      setIsLoaded(true); // Set loaded state to true after data is fetched
    };

    fetchPatients();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = patients.filter(patient =>
      `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(value)
    );
    setFilteredPatients(filtered);
  };

  const handlePatientSelect = async (patientId) => {
    setSelectedPatient(patientId);
    const appointmentsCollection = collection(db, 'appointments');
    const appointmentsSnapshot = await getDocs(appointmentsCollection);
    const appointmentsList = appointmentsSnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(appointment => appointment.patientId === patientId);
    setAppointments(appointmentsList);
  };

  const handlePayment = async (token) => {
    try {
      const patientDoc = await getDoc(doc(db, 'patients', selectedPatient));
      const patientData = patientDoc.data();

      const customerResponse = await customersApi.createCustomer({
        givenName: patientData.firstName,
        familyName: patientData.lastName,
        emailAddress: patientData.email,
        phoneNumber: patientData.contact,
        referenceId: selectedPatient,
      });

      const customerId = customerResponse.result.customer.id;

      console.log('Processing payment with token:', token);

      const transactionTime = new Date();

      const paymentData = {
        amount: copay + additionalFees,
        copay: copay,
        additionalFees: additionalFees,
        date: transactionTime,
        appointmentId: selectedAppointment,
        tokenId: token.id,
        customerId: customerId,
        patientId: selectedPatient,
        locationId: locationId,
        timestamp: transactionTime.toISOString(),
        transactionTime: transactionTime.toLocaleString(),
      };

      await addDoc(collection(db, 'patients', selectedPatient, 'payments'), paymentData);

      console.log('Payment information saved successfully.');

    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const selectedPatientData = patients.find(patient => patient.id === selectedPatient);

  const totalAmount = copay + additionalFees;

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-4xl mx-auto mt-8">
      <h1 className="text-2xl font-bold text-center mb-6 text-indigo-900 dark:text-indigo-300">Process Payment</h1>

      {selectedPatientData && (
        <div className="mb-6 p-4 bg-indigo-100 dark:bg-indigo-800 rounded-md">
          <h2 className="text-xl font-semibold text-indigo-900 dark:text-indigo-300">Selected Patient</h2>
          <div className="flex items-center mt-2">
            <Avatar src={selectedPatientData.photoUrl} className="mr-4" />
            <div>
              <p className="text-lg font-semibold text-black dark:text-white">{selectedPatientData.firstName} {selectedPatientData.lastName}</p>
              <p className="text-gray-600 dark:text-gray-400">{selectedPatientData.email}</p>
            </div>
          </div>
        </div>
      )}

      <Form layout="vertical">
        <Form.Item label="Search Patient" className="mb-4">
          <Input
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
            className="px-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-indigo-300"
          />
        </Form.Item>

        <div className="max-h-64 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-md mb-4">
          <List
            itemLayout="horizontal"
            dataSource={filteredPatients}
            renderItem={patient => (
              <List.Item
                onClick={() => handlePatientSelect(patient.id)}
                className={`cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${selectedPatient === patient.id ? 'bg-indigo-100 dark:bg-indigo-800' : ''}`}
              >
                <List.Item.Meta
                  avatar={<Avatar src={patient.photoUrl} />}
                  title={<span className="text-lg font-semibold text-black dark:text-white">{patient.firstName} {patient.lastName}</span>}
                />
              </List.Item>
            )}
          />
        </div>

        {selectedPatient && (
          <Form.Item label="Select Appointment" className="mb-4">
            <Select
              placeholder="Select an appointment"
              value={selectedAppointment}
              onChange={setSelectedAppointment}
              className="w-full"
            >
              {appointments.map(appointment => (
                <Select.Option key={appointment.id} value={appointment.id}>
                  {appointment.date} - {appointment.visitType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Copay Amount" className="mb-4">
          <Input
            type="number"
            value={copay}
            onChange={e => setCopay(Number(e.target.value))}
            prefix="$"
            className="px-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-indigo-300"
          />
        </Form.Item>

        <Form.Item label="Additional Fees" className="mb-4">
          <Input
            type="number"
            value={additionalFees}
            onChange={e => setAdditionalFees(Number(e.target.value))}
            prefix="$"
            className="px-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-indigo-300"
          />
        </Form.Item>

        <div className="mb-6 p-4 bg-gray-100 dark:bg-gray-800 rounded-md">
          <h2 className="text-xl font-semibold text-indigo-900 dark:text-indigo-300">Total Amount</h2>
          <p className="text-lg font-semibold text-black dark:text-white">${totalAmount.toFixed(2)}</p>
        </div>

        {isLoaded && (
          <PaymentForm
            applicationId={appId}
            locationId={locationId}
            cardTokenizeResponseReceived={handlePayment}
          >
            <CreditCard />
          </PaymentForm>
        )}
      </Form>
    </div>
  );
};

export default PaymentPage;