import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import PatientIntake from './PatientIntake';
import SensationSurvey from './SensationSurvey';
import ConsentToChiropracticCare from './ConsentToChiropracticCare';
import { Breadcrumb } from 'antd';
import { HeartOutlined, MedicineBoxOutlined, FolderOutlined, FileAddOutlined, FileDoneOutlined, EditOutlined, TeamOutlined, IdcardOutlined } from '@ant-design/icons';

const MultiStepForm = () => {
  const { patientId } = useParams(); 
  console.log("MultiStepForm patientId:", patientId);
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate(); 

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <PatientIntake patientId={patientId}/>;
      case 1:
        return <SensationSurvey patientId={patientId}/>;
      case 2:
        return <ConsentToChiropracticCare patientId={patientId}/>;
      default:
        return null;
    }
  };

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${patientId}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${patientId}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${patientId}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${patientId}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${patientId}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
  ];

  const formViewerMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/patient-forms/${patientId}`}><FileAddOutlined /> Fill Out Patient Forms</Link>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto sm:max-w-lg md:max-w-xl lg:max-w-3xl xl:max-w-4xl">
      <Breadcrumb
        className='text-xl font-bold'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-sm sm:text-base md:text-lg">Patient List</span></Link>,
          },
          {
            title: 
              <Link to={`/dashboard/${patientId}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-sm sm:text-base md:text-lg">Dashboard</span></Link>,
              menu: { items: dashboardMenuItems },
          },
          {
            title: 
              <Link to={`/view-forms/${patientId}`}><FileDoneOutlined /> <span className="text-sm sm:text-base md:text-lg">View Forms</span></Link>,
              menu: { items: formViewerMenuItems },
          },
          {
            title: <Link to={`/patient-forms/${patientId}`}><FileAddOutlined style={{ fontSize: "1.50rem" }} className='text-indigo-900'/> <span className="text-2xl text-indigo-900">Fill Out Patient Forms</span></Link>,
          },
        ]}
      />

      {renderStep()}
      <div className="flex justify-between mt-4">
        {currentStep > 0 && (
          <button
            type="button"
            onClick={handlePrevious}
            className="px-4 py-2 font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700"
          >
            Previous
          </button>
        )}
        {currentStep < 2 && (
          <button
            type="button"
            onClick={handleNext}
            className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;