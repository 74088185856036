import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db, model } from '../../firebase'; 
import { doc, getDoc, updateDoc, collection, query, orderBy, limit, getDocs, where, addDoc, deleteDoc } from 'firebase/firestore';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it'; 
import ReactMarkdown from 'react-markdown'; 
import jsPDF from 'jspdf'; 
import html2canvas from 'html2canvas'; 
import { DownloadOutlined, DollarOutlined, SendOutlined, HeartOutlined, IdcardOutlined, FileDoneOutlined, TeamOutlined, MedicineBoxOutlined, FolderOutlined, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Flex, Select } from 'antd';
import PatientHeader from '../common/PatientHeader';

const mdParser = new MarkdownIt(); 

const icd10Codes = {
  "M54.5": {
    description: "Low back pain",
    specifics: [
      { code: "M54.50", description: "Low back pain, unspecified" },
      { code: "M54.51", description: "Acute low back pain" },
      { code: "M54.52", description: "Chronic low back pain" },
    ],
  },
  "M25.5": {
    description: "Pain in joint",
    specifics: [
      { code: "M25.50", description: "Pain in unspecified joint" },
      { code: "M25.51", description: "Pain in shoulder joint" },
      { code: "M25.52", description: "Pain in elbow joint" },
    ],
  },
  "R51": {
    description: "Headache",
    specifics: [
      { code: "R51.0", description: "Tension-type headache" },
      { code: "R51.1", description: "Migraine" },
      { code: "R51.9", description: "Headache, unspecified" },
    ],
  },
  "R10": {
    description: "Abdominal pain",
    specifics: [
      { code: "R10.0", description: "Acute abdomen" },
      { code: "R10.1", description: "Localized abdominal pain" },
      { code: "R10.9", description: "Abdominal pain, unspecified" },
    ],
  },
  "M79.1": {
    description: "Myalgia",
    specifics: [
      { code: "M79.10", description: "Myalgia, unspecified" },
      { code: "M79.11", description: "Myalgia, right side" },
      { code: "M79.12", description: "Myalgia, left side" },
    ],
  },
  "G89": {
    description: "Pain, not elsewhere classified",
    specifics: [
      { code: "G89.0", description: "Central pain syndrome" },
      { code: "G89.1", description: "Acute pain" },
      { code: "G89.2", description: "Chronic pain" },
    ],
  },
  "M99": {
    description: "Subluxation",
    specifics: [
      { code: "M99.0", description: "Subluxation of cervical spine" },
      { code: "M99.1", description: "Subluxation of thoracic spine" },
      { code: "M99.2", description: "Subluxation of lumbar spine" },
      { code: "M99.3", description: "Subluxation of sacral spine" },
      { code: "M99.4", description: "Subluxation of pelvic region" },
      { code: "M99.50", description: "Subluxation of unspecified site" },
      { code: "M99.51", description: "Subluxation of upper extremity" },
      { code: "M99.52", description: "Subluxation of lower extremity" },
    ],
  },
  "M54.2": {
    description: "Cervicalgia",
    specifics: [
      { code: "M54.20", description: "Cervicalgia, unspecified" },
      { code: "M54.21", description: "Cervicalgia, acute" },
      { code: "M54.22", description: "Cervicalgia, chronic" },
    ],
  },
  "M54.6": {
    description: "Pain in thoracic spine",
    specifics: [
      { code: "M54.60", description: "Pain in thoracic spine, unspecified" },
      { code: "M54.61", description: "Acute pain in thoracic spine" },
      { code: "M54.62", description: "Chronic pain in thoracic spine" },
    ],
  },
  "M54.3": {
    description: "Sciatica",
    specifics: [
      { code: "M54.30", description: "Sciatica, unspecified" },
      { code: "M54.31", description: "Sciatica, acute" },
      { code: "M54.32", description: "Sciatica, chronic" },
    ],
  },
  "M51": {
    description: "Intervertebral disc disorders",
    specifics: [
      { code: "M51.0", description: "Intervertebral disc disorder, cervical region" },
      { code: "M51.1", description: "Intervertebral disc disorder, thoracic region" },
      { code: "M51.2", description: "Intervertebral disc disorder, lumbar region" },
      { code: "M51.3", description: "Intervertebral disc disorder, sacral and sacrococcygeal region" },
    ],
  },
  "M54.4": {
    description: "Lumbago",
    specifics: [
      { code: "M54.40", description: "Lumbago, unspecified" },
      { code: "M54.41", description: "Lumbago, acute" },
      { code: "M54.42", description: "Lumbago, chronic" },
    ],
  },
  "M54.1": {
    description: "Radiculopathy",
    specifics: [
      { code: "M54.10", description: "Radiculopathy, unspecified" },
      { code: "M54.11", description: "Radiculopathy, cervical region" },
      { code: "M54.12", description: "Radiculopathy, lumbar region" },
    ],
  },
  "M54.7": {
    description: "Fibromyalgia",
    specifics: [
      { code: "M54.70", description: "Fibromyalgia, unspecified" },
      { code: "M54.71", description: "Fibromyalgia, localized" },
    ],
  },
  "M53.2": {
    description: "Cervical spondylosis",
    specifics: [
      { code: "M53.20", description: "Cervical spondylosis without myelopathy" },
      { code: "M53.21", description: "Cervical spondylosis with myelopathy" },
    ],
  },
  "M53.3": {
    description: "Thoracic spondylosis",
    specifics: [
      { code: "M53.30", description: "Thoracic spondylosis without myelopathy" },
      { code: "M53.31", description: "Thoracic spondylosis with myelopathy" },
    ],
  },
  "M53.4": {
    description: "Lumbar spondylosis",
    specifics: [
      { code: "M53.40", description: "Lumbar spondylosis without myelopathy" },
      { code: "M53.41", description: "Lumbar spondylosis with myelopathy" },
    ],
  },
  "M54.8": {
    description: "Other dorsopathies",
    specifics: [
      { code: "M54.80", description: "Dorsopathy, unspecified" },
      { code: "M54.81", description: "Dorsopathy, cervical region" },
      { code: "M54.82", description: "Dorsopathy, thoracic region" },
      { code: "M54.83", description: "Dorsopathy, lumbar region" },
    ],
  },
};
const PatientNotesEditor = () => {
  const { id } = useParams();
  const [editorState, setEditorState] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shorthandInput, setShorthandInput] = useState(''); 
  const [aiResponse, setAiResponse] = useState(''); 
  const [latestPrescription, setLatestPrescription] = useState(null);
  const [latestVitals, setLatestVitals] = useState(null);
  const [appointmentInfo, setAppointmentInfo] = useState(null); 
  const [dropdownVisible, setDropdownVisible] = useState(null); 
  const [appointmentId, setAppointmentId] = useState(''); 
  const [appointments, setAppointments] = useState([]); 
  const [patientName, setPatientName] = useState(''); 
  const [medicalRecordNumber, setMedicalRecordNumber] = useState(''); 
  const [pastNotes, setPastNotes] = useState([]); 
  const [selectedNoteId, setSelectedNoteId] = useState(''); 
  const [patientData, setPatientData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);


  const fetchPastNotes = async () => {
    try {
      console.log("Fetching notes for patient ID:", id);
      const notesRef = collection(db, `patients/${id}/notes`);
      const notesQuery = query(notesRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(notesQuery);

      // console.log("Query snapshot:", querySnapshot);

      if (querySnapshot.empty) {
        console.log("No notes found for this patient");
        setPastNotes([]);
        return;
      }
      
      const notesData = await Promise.all(querySnapshot.docs.map(async (noteDoc) => { // Change 'doc' to 'noteDoc'
        const noteData = { id: noteDoc.id, ...noteDoc.data() }; // Use 'noteDoc' here
        
        // console.log('Note Data:', noteData);
        
        if (noteData.appointmentId) { 
          // console.log('Fetching appointment with ID:', noteData.appointmentId); 
          const appointmentRef = doc(db, 'appointments', noteData.appointmentId); 
          const appointmentSnap = await getDoc(appointmentRef);
          
          if (appointmentSnap.exists()) {
            noteData.appointmentDate = appointmentSnap.data().date; 
          } else {
            console.warn(`No appointment found for ID: ${noteData.appointmentId}`);
          }
        } else {
          console.warn('No appointmentId found in note data:', noteData);
        }
        
        return noteData;
      }));
      
      setPastNotes(notesData); 
    } catch (err) {
      console.error('Error fetching past notes:', err);
      setError('Failed to fetch past notes');
    }
  };

  const fetchAppointments = async () => {
    try {
      const appointmentsRef = collection(db, 'appointments');
      const q = query(appointmentsRef, where('patientId', '==', id), orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        console.log('No appointments found for this patient.');
        setAppointments([]); 
        return;
      }

      const appointmentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAppointments(appointmentsData); 
    } catch (err) {
      console.error('Error fetching appointments:', err);
      setError('Failed to fetch appointments');
    }
  };

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!id) return;

      try {
        const patientDoc = await getDoc(doc(db, 'patients', id));
        if (patientDoc.exists()) {
          setPatientData(patientDoc.data());
          console.log('Patient data:', patientDoc.data());
        } else {
          console.log('No such patient!');
        }

        const appointmentsCollection = collection(db, 'appointments');
        const appointmentsSnapshot = await getDocs(appointmentsCollection);
        const appointmentsList = appointmentsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(appointment => appointment.patientId === id);
        setAppointments(appointmentsList);

        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    const fetchNotes = async () => {
      try {
        const notesRef = collection(db, `patients/${id}/notes`); 
        const notesQuery = query(notesRef, orderBy('createdAt', 'desc')); 
        const querySnapshot = await getDocs(notesQuery);
        if (!querySnapshot.empty) {
          const notesData = querySnapshot.docs.map(doc => doc.data());
          setEditorState(notesData[0]?.content || ''); 
        } else {
          console.log('No notes found for this patient.');
        }
      } catch (err) {
        console.error('Error fetching patient notes:', err);
        setError('Failed to fetch patient notes');
      }
    };

    const fetchLatestPrescription = async () => {
      try {
        const prescriptionsRef = collection(db, 'prescriptions');
        const q = query(prescriptionsRef, where('patientId', '==', id), orderBy('date', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const prescriptionData = querySnapshot.docs[0].data();
          setLatestPrescription(prescriptionData);
        }
      } catch (err) {
        console.error('Error fetching latest prescription:', err);
      }
    };

    const fetchLatestVitals = async () => {
      try {
        const vitalsRef = collection(db, 'patients', id, 'vitals'); 
        const q = query(vitalsRef, orderBy('createdAt', 'desc'), limit(1)); 
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const vitalsData = querySnapshot.docs[0].data();
          setLatestVitals(vitalsData);
        }
      } catch (err) {
        console.error('Error fetching latest vitals:', err);
      }
    };

    const fetchAppointmentInfo = async () => {
      try {
        const appointmentsRef = collection(db, 'appointments');
        const q = query(appointmentsRef, where('patientId', '==', id), orderBy('date', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const appointmentData = querySnapshot.docs[0].data();
          setAppointmentInfo(appointmentData);
          setAppointmentId(appointmentData.id); 
        }
      } catch (err) {
        console.error('Error fetching appointment info:', err);
      }
    };

    const fetchPatientInfo = async () => {
      try {
        const patientRef = doc(db, 'patients', id);
        const patientSnap = await getDoc(patientRef);
        if (patientSnap.exists()) {
          const patientData = patientSnap.data();
          setPatientName(`${patientData.firstName} ${patientData.lastName}`); 
          setMedicalRecordNumber(patientData.medicalRecordNumber); 
        }
      } catch (err) {
        console.error('Error fetching patient info:', err);
      }
    };

    fetchNotes();
    fetchPastNotes(); 
    fetchAppointments();
    fetchLatestPrescription();
    fetchLatestVitals();
    fetchAppointmentInfo();
    fetchPatientInfo();
    fetchPatientData();
    setLoading(false);
  }, [id]);

  const handleSave = async () => {
    setLoading(true);
    setError(null);

    try {
        if (!appointmentId) {
            throw new Error("Appointment ID is not set.");
        }

        const notesRef = collection(db, `patients/${id}/notes`);
        await addDoc(notesRef, { 
            content: editorState, 
            createdAt: new Date(), 
            appointmentId: appointmentId 
        }); 
        alert('Notes saved successfully!');
    } catch (err) {
        console.error('Error saving notes:', err);
        setError(`Failed to save notes: ${err.message}`); 
    } finally {
        setLoading(false);
    }
  };

  const handleShorthandSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (!model) throw new Error("Model is not initialized"); 
      
      const today = new Date().toLocaleDateString();
      
      const prescriptionInfo = latestPrescription ? 
        `Latest Prescription: ${latestPrescription.medication} (${latestPrescription.genericName}), Dosage: ${latestPrescription.dosage}, Instructions: ${latestPrescription.instructions}, Total Dosage: ${latestPrescription.totalDosage} on ${latestPrescription.date}` : 
        'No recent prescriptions.';
      
      const vitalsInfo = latestVitals ? 
        `Latest Vitals: BP: ${latestVitals.systolic}/${latestVitals.diastolic}, HR: ${latestVitals.pulse} on ${latestVitals.createdAt.toDate().toLocaleDateString()}` : 
        'No recent vitals.';
      
      const appointmentDetails = appointmentInfo ? 
        `Appointment on ${appointmentInfo.date}: Provider: ${appointmentInfo.provider}, Title: ${appointmentInfo.title}, Visit Type: ${appointmentInfo.visitType} (If the appointment matches the current date, do not say it was a prior appointment. Treat that appointment as the appointment the SOAP note is for.)` : 
        'No recent appointments.';
      
      const shorthandPrompt = `
        Convert the following shorthand into full doctor's notes in SOAP format:
        Date: ${appointmentInfo.date} Last Edited: ${today}
        Subjective: ${shorthandInput} (If some of this belongs in the subjective section, put it there. If some belongs in the objective, put it there. If some belongs in the assessment, put it there. If some belongs in the plan, put it there.)
        Objective: ${prescriptionInfo} | ${vitalsInfo} | ${appointmentDetails}
        Assessment: [Insert assessment with the actual ICD-10 codes here (if unsure, use the most specific code that applies and give alternatives if there are multiple codes that apply)]
        Plan: [Insert plan of care here]
      `;
      console.log(shorthandPrompt);
      const result = await model.generateContent(shorthandPrompt); 
      const text = result.response.text();
      setAiResponse(text); 
    } catch (error) {
      console.error('Error generating AI response:', error);
      setAiResponse('Error generating response. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const acceptAiResponse = () => {
    setEditorState(editorState + aiResponse); 
    setShorthandInput(''); 
    setAiResponse(''); 
  };

  const handleCodeClick = (code) => {
    setDropdownVisible(code);
  };

  const handleDropdownMouseLeave = () => {
    setDropdownVisible(null);
  };

  const insertText = (text) => {
    setEditorState(editorState + text); 
    setDropdownVisible(null); 
  };

  const exportAsMarkdown = () => {
    const blob = new Blob([editorState], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${patientName}_notes_${medicalRecordNumber}_${new Date().toLocaleDateString()}.md`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const exportAsPDF = async () => {
    // Create a temporary div to hold the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = mdParser.render(editorState); // Convert Markdown to HTML
    tempDiv.style.fontSize = '12pt'; // Set font size to 12pt
    tempDiv.style.width = '100%'; // Set width to fit the PDF
    tempDiv.style.padding = '10px'; // Add some padding for better appearance
    tempDiv.style.boxSizing = 'border-box'; // Ensure padding is included in width
    tempDiv.style.wordWrap = 'break-word'; // Ensure text wraps correctly
    tempDiv.style.maxWidth = '190mm'; // Set max width to fit within PDF margins

    // Append the temporary div to the body (hidden)
    document.body.appendChild(tempDiv);

    // Use html2canvas to capture the div as an image with higher resolution
    const canvas = await html2canvas(tempDiv, {
        scale: 2, // Increase scale for better resolution
        useCORS: true, // Enable CORS if needed
    });
    const imgData = canvas.toDataURL('image/png');

    // Create a new jsPDF instance with A4 size
    const jsPDFDoc = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 190; // Set image width to fit within margins
    const pageHeight = jsPDFDoc.internal.pageSize.height; // Get page height
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate image height
    let heightLeft = imgHeight;

    let position = 10; // Initial position on the page

    // Add the image to the PDF
    doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add new pages if the content is taller than the page height
    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }

    // Save the PDF
    jsPDFDoc.save(`${patientName}_notes_MR#${medicalRecordNumber}_${appointmentInfo.date}.pdf`);

    // Remove the temporary div from the body
    document.body.removeChild(tempDiv);
  };

  const shareViaEmail = () => {
    const subject = 'Patient Notes';
    const body = encodeURIComponent(editorState);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const handleNoteSelect = (noteId) => {
    const selectedNote = pastNotes.find(note => note.id === noteId);
    if (selectedNote) {
      setEditorState(selectedNote.content); // Populate editor with selected note content
      setSelectedNoteId(noteId); // Set selected note ID
    } else {
      setEditorState(''); // Clear editor for new note
      setSelectedNoteId(''); // Reset selected note ID
    }
  };

  const handleDeleteNote = async () => {
    const confirmation = window.prompt(`Type "delete ${appointmentInfo.date} note for ${patientName}" to confirm deletion.`);
    if (confirmation === `delete ${appointmentInfo.date} note for ${patientName}`) {
      try {
        const noteRef = doc(db, `patients/${id}/notes`, selectedNoteId);
        await deleteDoc(noteRef); 
        alert('Note deleted successfully!');
        fetchPastNotes(); 
      } catch (err) {
        console.error('Error deleting note:', err);
        setError('Failed to delete note');
      }
    } else {
      alert('Deletion cancelled. Confirmation text did not match.');
    }
  };

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${id}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${id}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${id}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${id}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
    {
      key: '6',
      label: (
        <Link to={`/patient-payment/${id}`}><DollarOutlined /> Patient Payment</Link>
      ),
    },
  ];

  return (
    <div className="p-4 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-7xl mx-auto">
      <Breadcrumb
        className='text-xl font-bold'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Patient List</span></Link>,
          },
          {
            title: 
            <Link to={`/dashboard/${id}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Dashboard</span></Link>,
            menu: { items: dashboardMenuItems },
          },
          {
            title: 
            <Link to={`/patient-notes/${id}`}><EditOutlined style={{ fontSize: "1.50rem" }}className='text-indigo-900'/><span className='text-2xl text-indigo-900'> Patient Notes</span></Link>,
          },
        ]}
        />
        {patientData && <PatientHeader patientData={patientData} patientId={id} />}
      <div className="flex items-center mb-4">
        <h1 className="text-xl text-gray-500 md:text-3xl font-boldr w-full">Patient Notes Editor</h1>
        {/* <Link
          to={`/dashboard/${id}`}
          className="px-4 py-2 font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Back to Dashboard
        </Link> */}
      </div>

      {/* Export Buttons */}
      {/* <div className="mt-4 flex justify-between">
        <button onClick={exportAsMarkdown} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Export as Markdown
        </button>
        <button onClick={exportAsPDF} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Export as PDF
        </button>
        <button onClick={shareViaEmail} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Share via Email
        </button>
      </div> */}
      
      <div className="mt-4 flex flex-col md:flex-row justify-between">
        <Button 
          type="primary" 
          icon={<DownloadOutlined />} 
          size="large" 
          className="mb-2 md:mb-0 md:mr-2 w-full md:w-auto"
          onClick={exportAsMarkdown}
        >
          Download Markdown
        </Button>
        <Button 
          type="primary" 
          icon={<DownloadOutlined />} 
          size="large" 
          className="mb-2 md:mb-0 md:mr-2 w-full md:w-auto"
          onClick={exportAsPDF}
        >
          Download PDF
        </Button>
        <Button 
          type="primary" 
          icon={<SendOutlined />} 
          size="large" 
          className="w-full md:w-auto"
          onClick={shareViaEmail}
        >
          Share via Email
        </Button>
      </div>
      {error && <div className="text-red-600 mb-4">{error}</div>}

      {/* Appointment Selector */}
      <div className="mb-4">
        {/* <label htmlFor="appointmentSelect" className="block text-sm font-medium text-gray-700">Select Appointment:</label> */}
          <Select
            id='appointmentSelect'
            showSearch
            placeholder="Select an Appointment"
            value={appointmentId}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(value) => {
              setAppointmentId(value);
              handleNoteSelect(''); 
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={appointments.map((appointment) => ({
              value: appointment.id,
              label: `${appointment.date} - ${appointment.provider} - ${appointment.visitType} - ${appointment.title}`,
            }))}
          />      
        </div>

      {/* Past Notes Selector */}
      <div className="mb-4">
        {/* <label htmlFor="noteSelect" className="block text-sm font-medium text-gray-700">Select Past Notes:</label> */}
        <Select
          id="noteSelect"
          showSearch
          placeholder="Select a Note"
          value={selectedNoteId || undefined} 
          onChange={(value) => handleNoteSelect(value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={pastNotes.map((note) => ({
            value: note.id,
            label: `${note.appointmentDate ? new Date(note.appointmentDate).toLocaleDateString() : 'Unknown Date'} - ${note.content.substring(0, 20)}...`,
          }))}
        />    
      </div>

      <div className="flex flex-wrap justify-between mb-4">
        <div className="w-full md:w-1/4 mb-4 md:mb-0">
          <h2 className="text-lg font-bold mb-2">Common ICD-10 Codes</h2>
          <ul>
            {Object.entries(icd10Codes).map(([code, { description, specifics }]) => (
              <li key={code} className="relative">
                <span 
                  onClick={() => handleCodeClick(code)} 
                  className="cursor-pointer text-blue-600 hover:underline"
                >
                  {code} - {description}
                </span>
                {dropdownVisible === code && specifics.length > 0 && (
                  <div 
                    className="absolute left-0 mt-1 bg-white border border-gray-300 shadow-lg z-10"
                    onMouseLeave={handleDropdownMouseLeave}
                  >
                    <ul className="p-2">
                      {specifics.map((spec) => (
                        <li key={spec.code}>
                          <button
                            onClick={() => insertText(`${spec.code} - ${spec.description}\n`)}
                            className="block w-full text-left px-2 py-1 text-gray-700 hover:bg-gray-200"
                          >
                            {spec.code} - {spec.description}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-3/4">
          <MarkdownEditor
            value={editorState}
            style={{ height: '500px' }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ text }) => setEditorState(text)} 
          />
        </div>
      </div>
      {/* <button
        onClick={handleSave}
        className="mt-4 px-4 py-2 w-full font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Save Notes
      </button> */}
      <Button
        type="primary"
        size="large"
        onClick={handleSave}
        className="mt-4 w-full font-medium rounded-md bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Save Notes
      </Button>


      {/* Delete Note Button */}
      {/* <button
        onClick={handleDeleteNote}
        className="mt-4 px-4 py-2 w-full font-medium text-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Delete Patient Note
      </button> */}
      <Flex wrap gap="small">
        <Button
          type="dashed"
          danger
          size="large"
          onClick={handleDeleteNote}
          className="mt-4 w-full font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Delete Patient Note
        </Button>
      </Flex>

      {/* Shorthand Input Section */}
      <form onSubmit={handleShorthandSubmit} className="mt-4">
        <textarea
          value={shorthandInput}
          onChange={(e) => setShorthandInput(e.target.value)}
          placeholder="Type shorthand notes here..."
          required
          className="w-full h-32 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button
          type="submit"
          disabled={loading}
          className={`mt-4 w-full py-2 text-white font-semibold rounded-md ${loading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500'}`}
        >
          {loading ? 'Loading...' : 'Convert to Notes'}
        </button>
      </form>

      {/* AI Response Section */}
      {aiResponse && (
        <div className="mt-4 p-4 border border-gray-300 rounded-md">
          <h3 className="font-semibold">AI Response:</h3>
          <ReactMarkdown>{aiResponse}</ReactMarkdown> {/* Render AI response as markdown */}
          {/* <button 
            onClick={acceptAiResponse} 
            className="mt-2 w-full py-2 text-white font-semibold bg-green-600 hover:bg-green-700 rounded-md"
          >
            Accept Response
          </button> */}
          <Button 
            type="primary"
            size="large"
            onClick={acceptAiResponse}
            className="mt-2 w-full font-medium rounded-md"
          >
            Accept Response
          </Button>
        </div>
      )}
    </div>
  );
};

export default PatientNotesEditor;