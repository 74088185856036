import React from 'react';

const VitalsList = ({ vitals, handleEdit, handleDelete }) => {
  const sortedVitals = vitals.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

  return (
    <div className="mt-6 dark:bg-gray-800 overflow-x-auto">
      <h2 className="text-2xl font-bold mb-4">Past Vitals</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 text-sm md:text-base">Date</th>
            <th className="py-2 text-sm md:text-base">Weight (lbs)</th>
            <th className="py-2 text-sm md:text-base">Blood Pressure</th>
            <th className="py-2 text-sm md:text-base">Heart Rate (BPM)</th>
            <th className="py-2 text-sm md:text-base">Temperature (°F)</th>
            <th className="py-2 text-sm md:text-base">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedVitals.map(vital => (
            <tr key={vital.id}>
              <td className="py-2 text-sm md:text-base">{new Date(vital.createdAt.seconds * 1000).toLocaleString()}</td>
              <td className="py-2 text-sm md:text-base">{vital.weightLb}</td>
              <td className="py-2 text-sm md:text-base">{`${vital.systolic}/${vital.diastolic}`}</td>
              <td className="py-2 text-sm md:text-base">{vital.pulse}</td>
              <td className="py-2 text-sm md:text-base">{vital.temperatureF}</td>
              <td className="py-2 text-sm md:text-base">
                <button onClick={() => handleEdit(vital)} className="text-blue-500 hover:text-blue-700 mr-4">Edit</button>
                <button onClick={() => handleDelete(vital.id)} className="text-red-500 hover:text-red-700">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VitalsList;