import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DatePicker, Button, Card, Tag, Row, Col, Typography, Space } from 'antd';
import { CheckCircleOutlined } from "@ant-design/icons"

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title, Text } = Typography;

const CheckInPage = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [scheduledAppointments, setScheduledAppointments] = useState([]);
  const [checkedInAppointments, setCheckedInAppointments] = useState([]);
  const [checkedOutAppointments, setCheckedOutAppointments] = useState([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      const q = query(collection(db, 'appointments'), where('date', '==', selectedDate));
      const querySnapshot = await getDocs(q);
      const tempScheduled = [];
      const tempCheckedIn = [];
      const tempCheckedOut = [];
    
      for (const docSnap of querySnapshot.docs) {
        const appointmentData = docSnap.data();
        const patientDoc = await getDoc(doc(db, 'patients', appointmentData.patientId));
    
        if (patientDoc.exists()) {
          const patientData = patientDoc.data();
          const appointmentWithPatient = {
            ...appointmentData,
            id: docSnap.id,
            patientName: `${patientData.firstName} ${patientData.lastName}`,
            photoUrl: patientData.photoUrl
          };
    
          const now = dayjs().tz('America/Los_Angeles');
          const appointmentDateTime = dayjs.tz(`${selectedDate}T${appointmentData.time}`, 'America/Los_Angeles');
          const isLate = appointmentDateTime.isBefore(now);
    
          if (appointmentData.checkInStatus === 'scheduled') {
            tempScheduled.push({ ...appointmentWithPatient, isLate });
          } else if (appointmentData.checkInStatus === 'checked-in') {
            tempCheckedIn.push(appointmentWithPatient);
          } else if (appointmentData.checkInStatus === 'checked-out') {
            tempCheckedOut.push(appointmentWithPatient);
          }
        } else {
          console.warn(`No patient found for ID: ${appointmentData.patientId}`);
        }
      }
    
      setScheduledAppointments(tempScheduled);
      setCheckedInAppointments(tempCheckedIn);
      setCheckedOutAppointments(tempCheckedOut);
    };

    fetchAppointments();
  }, [selectedDate]);

  const handleCheckIn = async (appointmentId) => {
    await updateDoc(doc(db, 'appointments', appointmentId), { checkInStatus: 'checked-in' });
    const updatedScheduledAppointments = scheduledAppointments.filter(appt => appt.id !== appointmentId);
    const updatedCheckedInAppointment = scheduledAppointments.find(appt => appt.id === appointmentId);
    setScheduledAppointments(updatedScheduledAppointments);
    setCheckedInAppointments([...checkedInAppointments, { ...updatedCheckedInAppointment, checkInStatus: 'checked-in' }]);
  };

  const handleCheckOut = async (appointmentId) => {
    const checkedOutAt = dayjs().tz('America/Los_Angeles').toISOString(); // Get current time in ISO format
    await updateDoc(doc(db, 'appointments', appointmentId), { checkInStatus: 'checked-out', checkedOutAt });
    const updatedCheckedInAppointments = checkedInAppointments.filter(appt => appt.id !== appointmentId);
    const updatedCheckedOutAppointment = checkedInAppointments.find(appt => appt.id === appointmentId);
    setCheckedInAppointments(updatedCheckedInAppointments);
    setCheckedOutAppointments([...checkedOutAppointments, { ...updatedCheckedOutAppointment, checkInStatus: 'checked-out', checkedOutAt }]);
  };

  const getAppointmentStyles = (visitType, isLate) => {
    let borderColor, backgroundColor;
    if (isLate) {
      borderColor = '#FFC107'; // Darker yellow for late appointments
      backgroundColor = '#FFF8E1'; // Lighter yellow
    } else {
      switch (visitType) {
        case 'chiropractic':
          borderColor = '#64B5F6'; // Darker blue
          backgroundColor = '#E3F2FD'; // Lighter blue
          break;
        case 'massage':
          borderColor = '#81C784'; // Darker green
          backgroundColor = '#E8F5E9'; // Lighter green
          break;
        case 'evaluation':
          borderColor = '#FFD54F'; // Darker yellow
          backgroundColor = '#FFFDE7'; // Lighter yellow
          break;
        case 'second visit':
          borderColor = '#BA68C8'; // Darker purple
          backgroundColor = '#F3E5F5'; // Lighter purple
          break;
        default:
          borderColor = '#BDBDBD'; // Darker gray
          backgroundColor = '#F5F5F5'; // Lighter gray
          break;
      }
    }
    return { borderColor, backgroundColor };
  };

  const renderCountdown = (appointmentTime) => {
    const now = dayjs().tz('America/Los_Angeles');
    const appointmentDateTime = dayjs.tz(`${selectedDate}T${appointmentTime}`, 'America/Los_Angeles');
    const diff = Math.floor((appointmentDateTime - now) / 60000); // difference in minutes

    if (diff <= 0) {
      const countUp = -diff;
      return <Text type="danger">+{countUp} min</Text>;
    } else if (diff <= 15) {
      return <Text>{diff} min</Text>;
    }
    return null;
  };

  return (
    <div className="checkin-page dark:bg-gray-800 p-4">
      <Title level={3} className='dark:text-gray-300 text-indigo-900 text-xl'><CheckCircleOutlined className='text-xl text-indigo-900' style={{ fontSize: '1.5rem' }} /> Check-In Page</Title>
      <DatePicker
        value={dayjs(selectedDate)}
        onChange={(date) => {
          if (date) {
            setSelectedDate(date.format('YYYY-MM-DD'));
          }
        }}
        className="mb-4"
      />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8}>
          <Card title="Scheduled Appointments" bordered={false} className='dark:bg-gray-500'>
            {scheduledAppointments.map((appointment) => {
              const { borderColor, backgroundColor } = getAppointmentStyles(appointment.visitType, appointment.isLate);
              return (
                <Card.Grid 
                  key={appointment.id} 
                  style={{ 
                    width: '100%', 
                    textAlign: 'left', 
                    border: `3px solid ${borderColor}`, 
                    backgroundColor: backgroundColor,
                    transition: 'border-color 0.3s, border-width 0.3s',
                    ':hover': {
                      borderWidth: '4px'
                    },
                    position: 'relative'
                  }}
                >
                  <Space direction="vertical">
                    {appointment.photoUrl && (
                      <img src={appointment.photoUrl} alt="Patient" style={{ width: 48, height: 48, borderRadius: '50%' }} />
                    )}
                    <Text strong>{appointment.title}</Text>
                    <Link to={`/dashboard/${appointment.patientId}`}>{appointment.patientName}</Link>
                    <Link to={`/patient-forms/${appointment.patientId}`}>Fill Out Form</Link>
                    <Text>{appointment.time} - {renderCountdown(appointment.time)}</Text>
                    <Button type="primary" onClick={() => handleCheckIn(appointment.id)}>Check-In</Button>
                    <Text strong style={{ position: 'absolute', bottom: 8, right: 8, color: 'gray' }}>
                      {appointment.visitType}
                    </Text>
                  </Space>
                </Card.Grid>
              );
            })}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card title="Checked-In Appointments" bordered={false} className='dark:bg-gray-500'>
            {checkedInAppointments.map((appointment) => {
              const { borderColor, backgroundColor } = getAppointmentStyles(appointment.visitType, false);
              return (
                <Card.Grid 
                  key={appointment.id} 
                  style={{ 
                    width: '100%', 
                    textAlign: 'left', 
                    border: `3px solid ${borderColor}`, 
                    backgroundColor: backgroundColor,
                    transition: 'border-color 0.3s, border-width 0.3s',
                    ':hover': {
                      borderWidth: '4px'
                    },
                    position: 'relative'
                  }}
                >
                  <Space direction="vertical">
                    {appointment.photoUrl && (
                      <img src={appointment.photoUrl} alt="Patient" style={{ width: 48, height: 48, borderRadius: '50%' }} />
                    )}
                    <Text strong>{appointment.title}</Text>
                    <Link to={`/dashboard/${appointment.patientId}`}>{appointment.patientName}</Link>
                    <Text>{appointment.time}</Text>
                    <Button type="primary" onClick={() => handleCheckOut(appointment.id)}>Check-Out</Button>
                    <Text strong style={{ position: 'absolute', bottom: 8, right: 8, color: 'gray' }}>
                      {appointment.visitType}
                    </Text>
                  </Space>
                </Card.Grid>
              );
            })}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card title="Checked-Out Appointments" bordered={false} className='dark:bg-gray-500'>
            {checkedOutAppointments.map((appointment) => {
              const { borderColor, backgroundColor } = getAppointmentStyles(appointment.visitType, false);
              return (
                <Card.Grid 
                  key={appointment.id} 
                  style={{ 
                    width: '100%', 
                    textAlign: 'left', 
                    border: `3px solid ${borderColor}`, 
                    backgroundColor: backgroundColor,
                    transition: 'border-color 0.3s, border-width 0.3s',
                    ':hover': {
                      borderWidth: '4px'
                    },
                    position: 'relative'
                  }}
                >
                  <Space direction="vertical">
                    {appointment.photoUrl && (
                      <img src={appointment.photoUrl} alt="Patient" style={{ width: 48, height: 48, borderRadius: '50%' }} />
                    )}
                    <Text strong>{appointment.title}</Text>
                    <Link to={`/dashboard/${appointment.patientId}`}>{appointment.patientName}</Link>
                    <Text>{appointment.time}</Text>
                    <Text type="secondary">
                      {appointment.checkedOutAt 
                        ? `Checked Out At: ${dayjs(appointment.checkedOutAt).tz('America/Los_Angeles').format('h:mm A')}` 
                        : 'Check Out Time Not Available'}
                    </Text>
                    <Text strong style={{ position: 'absolute', bottom: 8, right: 8, color: 'gray' }}>
                      {appointment.visitType}
                    </Text>
                  </Space>
                </Card.Grid>
              );
            })}
          </Card>
        </Col>
      </Row>
      <div className="legend mt-4">
        <Title level={3}>Legend</Title>
        <Space>
          <Tag color="blue">Chiropractic</Tag>
          <Tag color="green">Massage</Tag>
          <Tag color="yellow">Evaluation</Tag>
          <Tag color="purple">Second Visit</Tag>
          {/* <Tag color="gray">Other</Tag> */}
        </Space>
      </div>
    </div>
  );
};

export default CheckInPage;