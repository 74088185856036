import React from 'react';

const MessageList = ({ conversations, onSelectConversation }) => {
  return (
    <div className='dark:bg-gray-800'>
      <h2 className="text-2xl font-bold mb-4">Conversations</h2>
      <ul>
        {conversations.map((conversation) => (
          <li key={conversation.id} className="mb-2 cursor-pointer" onClick={() => onSelectConversation(conversation)}>
            <div className="p-2 bg-gray-100 rounded">
              <p>{conversation.lastMessage}</p>
              <p className="text-xs text-gray-500">{new Date(conversation.lastMessageTimestamp?.seconds * 1000).toLocaleString()}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MessageList;