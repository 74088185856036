import React from 'react';
import { Avatar, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const PatientHeader = ({ patientData, patientId }) => {
  return (
    <div className="flex items-center mb-6">
      <Link to={`/dashboard/${patientId}`} className="flex items-center">
        <Avatar
          src={patientData.photoUrl}
          size={64} // Large size
          className="mr-4"
        />
        <Title level={3} className="text-indigo-900 dark:text-indigo-300">
          {patientData.firstName} {patientData.lastName}
        </Title>
      </Link>
    </div>
  );
};

export default PatientHeader;