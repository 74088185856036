import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Breadcrumb } from 'antd';
import { HeartOutlined, IdcardOutlined, FileDoneOutlined, FileSearchOutlined, TeamOutlined, MedicineBoxOutlined, FolderOutlined, EditOutlined } from '@ant-design/icons';

const MedicalRecordViewer = () => {
  const { patientId, recordId } = useParams();
  const [record, setRecord] = useState(null);

  useEffect(() => {
    const fetchRecord = async () => {
      const docRef = doc(db, 'patients', patientId, 'medicalRecords', recordId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setRecord(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchRecord();
  }, [patientId, recordId]);

  if (!record) {
    return <div>Loading...</div>;
  }

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${patientId}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${patientId}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${patientId}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${patientId}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${patientId}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-3xl mx-auto mt-4 lg:max-w-5xl xl:max-w-7xl lg:min-h-[50vh] xl:min-h-[60vh]">      
      <Breadcrumb
        className='text-xl font-bold'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg">Patient List</span></Link>,
          },
          {
            title: 
              <Link to={`/dashboard/${patientId}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg">Dashboard</span></Link>,
              menu: { items: dashboardMenuItems },
          },
          {
            title: 
              <Link to={`/upload-medical-records/${patientId}`}><FolderOutlined style={{ fontSize: "1.25rem" }}/><span className="text-sm sm:text-base md:text-lg"> Medical Records</span></Link>,
          },
          {
            title: 
              <a href={record.url} className="flex items-center">
                <FileSearchOutlined style={{ fontSize: '1.50rem'}} className='text-indigo-900'/>
                <span className='text-2xl text-indigo-900 ml-2'>Medical Record Details</span>
              </a>
          },
        ]}
      />
      <div className="flex justify-center items-center mb-4">
        {/* <h1 className="text-xl text-gray-500 font-bold">Medical Record Viewer</h1> */}
      </div>
      <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
        <p className="mb-2"><strong>Title:</strong> {record.title}</p>
        <p className="mb-2"><strong>Description:</strong> {record.description}</p>
        <p className="mb-2"><strong>Date:</strong> {new Date(record.createdAt.seconds * 1000).toLocaleDateString()}</p>
        {record.url && (
          <div>
            <strong>Document:</strong>
            <iframe src={record.url} className="w-full h-64 lg:h-[24rem] xl:h-[32rem] mt-2 border rounded-md" title="Medical Record Document" />
            <a
              href={record.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 block mt-2"
            >
              Open in New Window
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicalRecordViewer;