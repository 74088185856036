import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, message } from 'antd';
import { FormOutlined } from '@ant-design/icons';

const MedicalRecordsList = ({ patientId, onRecordClick }) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchRecords = async () => {
      const q = query(collection(db, 'patients', patientId, 'medicalRecords'));
      const querySnapshot = await getDocs(q);
      const recordsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecords(recordsData);
    };

    fetchRecords();
  }, [patientId]);

  const handleDelete = async (recordId) => {
    try {
      await deleteDoc(doc(db, 'patients', patientId, 'medicalRecords', recordId));
      setRecords(records.filter(record => record.id !== recordId));
      message.success('Record deleted successfully');
    } catch (error) {
      console.error('Delete failed', error);
      message.error('Failed to delete record');
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt.seconds * 1000).toLocaleDateString(),
    },
    {
      title: <FormOutlined />,
      key: 'action',
      render: (_, record) => (
        <span>
          <Link to={`/view-medical-record/${patientId}/${record.id}`} className="text-indigo-600 hover:text-indigo-900">
            View
          </Link>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="text-red-600 hover:text-red-900 ml-4">Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Table
      dataSource={records}
      columns={columns}
      rowKey="id"
      pagination={false}
      className='dark:bg-gray-800'
    />
  );
};

export default MedicalRecordsList;