import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import MessageList from '../lists/MessageList';
import MessageViewer from '../viewers/MessageViewer';
import NewMessage from '../forms/NewMessage';

const MessageCenter = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchConversations(currentUser.uid);
      }
    });

    return unsubscribe;
  }, []);

  const fetchConversations = async (userId) => {
    const q = query(collection(db, 'conversations'), where('participants', 'array-contains', userId));
    onSnapshot(q, (querySnapshot) => {
      const convos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setConversations(convos);
    });
  };

  const handleConversationSelect = (conversation) => {
    setSelectedConversation(conversation);
  };

  return (
    <div className="flex dark:bg-gray-800">
      <div className="w-1/3">
        <NewMessage userId={user?.uid} />
        <MessageList conversations={conversations} onSelectConversation={handleConversationSelect} />
      </div>
      <div className="w-2/3">
        {selectedConversation ? (
          <MessageViewer conversation={selectedConversation} userId={user?.uid} />
        ) : (
          <div>Select a conversation to view messages</div>
        )}
      </div>
    </div>
  );
};

export default MessageCenter;