import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const AdjustmentHistory = () => {
  const { id } = useParams();
  const [adjustments, setAdjustments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAdjustment, setNewAdjustment] = useState({
    date: '',
    description: '',
    chiropractor: '',
  });
  const [editingAdjustment, setEditingAdjustment] = useState(null);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchAdjustments = async () => {
      const q = query(collection(db, 'adjustments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const adjustmentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdjustments(adjustmentsList);
      setLoading(false);
    };

    fetchAdjustments();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdjustment({ ...newAdjustment, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingAdjustment({ ...editingAdjustment, [name]: value });
  };

  const handleAddAdjustment = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'adjustments'), {
        ...newAdjustment,
        patientId: id,
        timestamp: new Date(),
      });
      setNewAdjustment({
        date: '',
        description: '',
        chiropractor: '',
      });
      const q = query(collection(db, 'adjustments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const adjustmentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdjustments(adjustmentsList);
    } catch (error) {
      console.error('Error adding adjustment:', error);
    }
  };

  const handleEditAdjustment = async (e) => {
    e.preventDefault();
    try {
      const adjustmentRef = doc(db, 'adjustments', editingAdjustment.id);
      await updateDoc(adjustmentRef, editingAdjustment);
      setEditingAdjustment(null);
      const q = query(collection(db, 'adjustments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const adjustmentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdjustments(adjustmentsList);
    } catch (error) {
      console.error('Error editing adjustment:', error);
    }
  };

  const handleStartEditing = (adjustment) => {
    setEditingAdjustment(adjustment);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestAdjustment = adjustments.length > 0 ? adjustments[0] : null;

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Adjustment History
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show History' : 'Hide History'}
        </button>
      </h2>
      {latestAdjustment && !editingAdjustment && (
        <div className="bg-gray-100 p-4 rounded-md shadow mb-4">
          <p><strong>Date:</strong> {latestAdjustment.date}</p>
          <p><strong>Description:</strong> {latestAdjustment.description}</p>
          <p><strong>Chiropractor:</strong> {latestAdjustment.chiropractor}</p>
          <button
            onClick={() => handleStartEditing(latestAdjustment)}
            className="text-blue-500 underline mt-2"
          >
            Edit
          </button>
        </div>
      )}
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {adjustments.slice(1).map(adjustment => (
              <li key={adjustment.id} className="bg-gray-100 p-4 rounded-md shadow">
                {editingAdjustment && editingAdjustment.id === adjustment.id ? (
                  <form onSubmit={handleEditAdjustment}>
                    <div>
                      <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={editingAdjustment.date}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                      <textarea
                        id="description"
                        name="description"
                        value={editingAdjustment.description}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="chiropractor" className="block text-sm font-medium text-gray-700">Chiropractor</label>
                      <input
                        type="text"
                        id="chiropractor"
                        name="chiropractor"
                        value={editingAdjustment.chiropractor}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Save
                    </button>
                  </form>
                ) : (
                  <>
                    <p><strong>Date:</strong> {adjustment.date}</p>
                    <p><strong>Description:</strong> {adjustment.description}</p>
                    <p><strong>Chiropractor:</strong> {adjustment.chiropractor}</p>
                    <button
                      onClick={() => handleStartEditing(adjustment)}
                      className="text-blue-500 underline mt-2"
                    >
                      Edit
                    </button>
                  </>
                )}
              </li>
            ))}
          </ul>
          {!editingAdjustment && (
            <form onSubmit={handleAddAdjustment} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newAdjustment.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  id="description"
                  name="description"
                  value={newAdjustment.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="chiropractor" className="block text-sm font-medium text-gray-700">Chiropractor</label>
                <input
                  type="text"
                  id="chiropractor"
                  name="chiropractor"
                  value={newAdjustment.chiropractor}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Adjustment
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default AdjustmentHistory;