import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Form, Input, Button, Select, message, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const { Option } = Select;

const EditProfile = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    displayName: '',
    photoURL: '',
    firstName: '',
    lastName: '',
    role: ''
  });
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [cropper, setCropper] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleUpload = async () => {
    if (!cropper) {
      message.error('Please select and crop a photo first.');
      return;
    }

    setUploading(true);
    try {
      const canvas = cropper.getCroppedCanvas({
        width: 200,
        height: 200,
      });

      canvas.toBlob(async (blob) => {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePictures/${currentUser.uid}/profile.jpg`);
        await uploadBytes(storageRef, blob);
        const photoURL = await getDownloadURL(storageRef);

        setUserData((prevData) => ({
          ...prevData,
          photoURL,
        }));

        message.success('Photo uploaded successfully');
      }, 'image/jpeg');
    } catch (error) {
      message.error('Failed to upload photo.');
    } finally {
      setUploading(false);
    }
  };

  const handleSave = async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, userData);
      message.success('Profile updated successfully');
      navigate('/'); // Redirect to home page after saving
    } catch (error) {
      message.error('Failed to update profile.');
    }
  };

  return (
    <div className="max-w-2xl dark:bg-gray-800 mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h1 className="text-3xl font-bold mb-6">Edit Profile</h1>
      <Form layout="vertical">
        <Form.Item label="First Name">
          <Input
            value={userData.firstName}
            onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Last Name">
          <Input
            value={userData.lastName}
            onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Display Name">
          <Input
            value={userData.displayName}
            onChange={(e) => setUserData({ ...userData, displayName: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Role">
          <Select
            value={userData.role}
            onChange={(value) => setUserData({ ...userData, role: value })}
          >
            <Option value="admin">Admin</Option>
            <Option value="managingDoctor">Managing Doctor</Option>
            <Option value="doctor">Doctor</Option>
            <Option value="frontOffice">Front Office</Option>
            <Option value="massage">Massage</Option>
            <Option value="laser">Laser</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Profile Picture">
          <input type="file" accept="image/*" onChange={handleFileChange} />
          {file && (
            <Cropper
              src={file}
              style={{ height: 200, width: '100%' }}
              aspectRatio={1}
              guides={false}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => setCropper(instance)}
            />
          )}
          {userData.photoURL && (
            <Avatar src={userData.photoURL} size={64} className="mt-2" />
          )}
          <Button
            onClick={handleUpload}
            disabled={uploading || !file}
            className="mt-2"
          >
            {uploading ? 'Uploading...' : 'Upload Photo'}
          </Button>
        </Form.Item>
        <Button type="primary" onClick={handleSave}>
          Save Profile
        </Button>
      </Form>
    </div>
  );
};

export default EditProfile;