import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams, Link } from 'react-router-dom';
import MedicalRecordList from '../lists/MedicalRecordsList';
import MedicalRecordViewer from '../viewers/MedicalRecordViewer';
import { Breadcrumb, Input, Button, Select, message } from 'antd';
import { HeartOutlined, DollarOutlined, IdcardOutlined, FileDoneOutlined, TeamOutlined, MedicineBoxOutlined, FolderOutlined, EditOutlined } from '@ant-design/icons';
import PatientHeader from '../common/PatientHeader';

const { TextArea } = Input;
const { Option } = Select;

const UploadMedicalRecords = () => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [uploading, setUploading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !title || !description || !documentType) {
      message.error('Please fill in all fields and select a file');
      return;
    }

    setUploading(true);

    const storage = getStorage();
    const storageRef = ref(storage, `medicalRecords/${id}/${file.name}`);
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      await addDoc(collection(db, 'patients', id, 'medicalRecords'), {
        url: downloadURL,
        fileName: file.name,
        title: title,
        description: description,
        documentType: documentType,
        createdAt: new Date(),
        patientId: id,
      });
      setFile(null);
      setTitle('');
      setDescription('');
      setDocumentType('');
      message.success('File uploaded successfully');
    } catch (error) {
      console.error('Upload failed', error);
      message.error('Failed to upload file');
    } finally {
      setUploading(false);
    }
  };

  const handleRecordClick = (record) => {
    setSelectedRecord(record);
  };

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${id}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${id}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${id}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${id}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
    {
      key: '6',
      label: (
        <Link to={`/patient-payment/${id}`}><DollarOutlined /> Patient Payment</Link>
      ),
    }
  ];

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!id) return;

      try {
        const patientDoc = await getDoc(doc(db, 'patients', id));
        if (patientDoc.exists()) {
          setPatientData(patientDoc.data());
          console.log('Patient data:', patientDoc.data());
        } else {
          console.log('No such patient!');
        }

        const appointmentsCollection = collection(db, 'appointments');
        const appointmentsSnapshot = await getDocs(appointmentsCollection);
        const appointmentsList = appointmentsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(appointment => appointment.patientId === id);
        setAppointments(appointmentsList);

        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    fetchPatientData();
  }, [id]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-2xl mx-auto mt-8 lg:max-w-4xl">
      <Breadcrumb
        className='text-xl font-bold mb-6'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Patient List</span></Link>,
          },
          {
            title: 
              <Link to={`/dashboard/${id}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /><span className="text-sm sm:text-base md:text-lg"> Dashboard</span></Link>,
              menu: { items: dashboardMenuItems },
          },
          {
            title: 
              <Link to={`/upload-medical-records/${id}`}><FolderOutlined style={{ fontSize: "1.50rem" }} className='text-indigo-900'/><span className='text-2xl text-indigo-900'> Medical Records</span></Link>,
          },
        ]}
      />
      {patientData && <PatientHeader patientData={patientData} patientId={id} />}
      <div className="flex flex-col items-center">
        <h1 className="text-xl text-gray-500 font-bold mb-6">Upload Medical Records</h1>
        <Input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="mb-4"
        />
        <TextArea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="mb-4"
        />
        <Select
          value={documentType}
          onChange={(value) => setDocumentType(value)}
          className="mb-4 w-full"
          placeholder="Select Document Type"
        >
          <Option value="Lab Results">Lab Results</Option>
          <Option value="X-Ray">X-Ray</Option>
          <Option value="Prescription">Prescription</Option>
          <Option value="Other">Other</Option>
        </Select>
        <Input
          type="file"
          onChange={handleFileChange}
          className="mb-4"
        />
        <Button
          onClick={handleUpload}
          loading={uploading}
          type="primary"
          className="mt-4 w-full"
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>

        <div className="w-full mt-8">
          <MedicalRecordList patientId={id} onRecordClick={handleRecordClick} />
        </div>

        {selectedRecord && (
          <div className="w-full mt-8">
            <MedicalRecordViewer record={selectedRecord} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadMedicalRecords;