import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { auth, db } from '../../firebase';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';

const InternalMessagesThread = ({ thread }) => {
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentUserPhotoURL, setCurrentUserPhotoURL] = useState('');
  const [userPhotos, setUserPhotos] = useState({});
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUserEmail(user.email);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setCurrentUserPhotoURL(userDoc.data().photoURL || '');
        }
      } else {
        setCurrentUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (thread && thread.length > 0) {
      const fetchUserPhotos = async () => {
        const uniqueEmails = [...new Set(thread.map(msg => msg.sender))];
        const photos = {};
        for (const email of uniqueEmails) {
          if (email !== currentUserEmail) {
            const userSnapshot = await getDocs(collection(db, 'users'));
            userSnapshot.forEach(doc => {
              if (doc.data().email === email) {
                photos[email] = doc.data().photoURL || '';
              }
            });
          }
        }
        setUserPhotos(photos);
      };

      fetchUserPhotos();
    }
  }, [thread, currentUserEmail]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [thread]);

  if (!thread || thread.length === 0) {
    return <div className="h-full flex items-center justify-center text-gray-500">Select a message to view the thread</div>;
  }

  return (
    <div className="internal-messages-thread dark:bg-gray-800 h-full overflow-y-auto p-6">
      {thread.map((message) => (
        <div key={message.id} className="message bg-gray-50 rounded-lg p-4 mb-4 flex items-start">
          <Avatar
            src={message.sender === currentUserEmail ? currentUserPhotoURL : userPhotos[message.sender]}
            icon={<UserOutlined />}
            size={40}
            className="mr-3"
          />
          <div className="flex-1">
            <div className="message-header flex justify-between items-center mb-2">
              <span className="font-medium text-gray-900">{message.sender === currentUserEmail ? 'You' : message.sender}</span>
              <span className="text-sm text-gray-500 ml-auto">
                {message.timestamp && message.timestamp.toDate 
                  ? message.timestamp.toDate().toLocaleString() 
                  : 'Date unavailable'}
              </span>
            </div>
            <p className="text-gray-800 whitespace-pre-wrap">{message.content}</p>
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

InternalMessagesThread.propTypes = {
  thread: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    subject: PropTypes.string.string,
    sender: PropTypes.string.isRequired,
    timestamp: PropTypes.object,
    content: PropTypes.string.isRequired,
  })),
};

export default InternalMessagesThread;