import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const Footer = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <footer className="bg-indigo-900 text-white py-2">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="text-center md:text-left">
          <p className="text-sm">&copy; {new Date().getFullYear()} PosturePortal</p>
        </div>
        <Button
          type="link"
          icon={<MenuOutlined />}
          onClick={showDrawer}
          className="text-white"
        >
          More
        </Button>
      </div>
      <Drawer
        title="More Information"
        placement="bottom"
        closable={true}
        onClose={closeDrawer}
        open={drawerVisible}
        height={200}
      >
        <div className="flex flex-col space-y-2">
          <p>Made with <span role="img" aria-label="heart">❤️</span> by <a href="https://github.com/shaunfitzgarald" className="hover:underline">shaunfitzgarald</a>.</p>
          <a href="/privacy-policy" className="hover:underline">
            Privacy Policy
          </a>
          <a href="/terms-of-service" className="hover:underline">
            Terms of Service
          </a>
          <a href="/contact-us" className="hover:underline">
            Contact Us
          </a>
        </div>
      </Drawer>
    </footer>
  );
};

export default Footer;