import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom'; 
import { doc, getDoc, updateDoc, query, collection, where, getDocs, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import PatientNotesDisplay from '../viewers/PatientNotesDisplay';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { Breadcrumb } from 'antd';
import { TeamOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';

const PatientInformationPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [photoFile, setPhotoFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const cropperRef = useRef(null);
  const [cropper, setCropper] = useState(null);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const docRef = doc(db, 'patients', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPatient(docSnap.data());
          setFormData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching patient:', error);
      }
      setLoading(false);
    };

    fetchPatient();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNotesChange = (value) => {
    setFormData({ ...formData, notes: value });
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setPhotoFile(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleCrop = useCallback(() => {
    if (!cropper) {
      console.error('Cropper instance is not initialized.');
      return;
    }
    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedImage(blob);
      notification.success({
        message: 'Success',
        description: 'Image cropped successfully!',
      });
    }, 'image/jpeg');
  }, [cropper]);

  const checkEmailExists = async (email) => {
    const q = query(collection(db, 'patients'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };
  
  const handleSave = async () => {
    try {
      // Check if the email has changed and if it already exists
      if (formData.email !== patient?.email) {
        const emailExists = await checkEmailExists(formData.email);
        if (emailExists) {
          throw new Error('A patient with this email already exists.');
        }
      }
  
      if (croppedImage) {
        const storage = getStorage();
        const storageRef = ref(storage, `patients/${id}/profile.jpg`); // Use a consistent file name
        await uploadBytes(storageRef, croppedImage);
        const photoUrl = await getDownloadURL(storageRef);
        formData.photoUrl = photoUrl; // Update formData with the new photo URL
      }
  
      if (!patient) {
        // If no patient exists, add a new document
        const patientDocRef = await addDoc(collection(db, "patients"), formData);
        setPatient({ ...formData, id: patientDocRef.id });
        console.log("New patient added successfully.");
      } else {
        // If patient exists, update the existing document
        const docRef = doc(db, "patients", id);
        await updateDoc(docRef, formData);
        setPatient(formData);
        console.log("Patient information updated successfully.");
      }
  
      setEditing(false);
    } catch (error) {
      console.error("Error saving patient information:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-2xl mx-auto mt-8 lg:max-w-4xl">
      {/* <div className="flex justify-between mb-4">
        <Link
          to="/patients"
          className="px-4 py-2 font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Back to Patient List
        </Link>
        <Link
          to={`/dashboard/${id}`}
          className="px-4 py-2 font-medium text-white bg-indigo-900 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Back to Dashboard
        </Link>
      </div> */}
      <Breadcrumb
        className="text-xl font-bold"
        items={[
          {
            title: (
              <Link to={`/patients`} className="flex items-center">
                <TeamOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-xl">Patient List</span>
              </Link>
            ),
          },
          {
            title: (
              <Link to={`/patient-information/${id}`} className="flex items-center">
                <InfoCircleOutlined style={{ fontSize: '1.5rem' }} /> <span className="text-2xl text-indigo-900">PatientInformation</span>
              </Link>
            ),
          },
        ]}
      />
      {/* <h1 className="text-3xl text-indigo-900 font-bold text-center mb-4">Patient Information</h1> */}
      <div className="flex items-center mb-4">
        {patient.photoUrl && (
          <img src={patient.photoUrl} alt="Patient" className="w-16 h-16 rounded-full mr-4" />
        )}
        <div>
          <p className="text-2xl font-semibold">{patient.firstName} {patient.lastName}</p>
          <p className="text-xl text-gray-600">{patient.email}</p>
          {patient && (
            <p className="text-xl text-gray-500 mb-4">Medical Record Number: {patient.medicalRecordNumber || 'Not Assigned'}</p>
          )}
        </div>
      </div>
      {editing ? (
        <>
          <div>
            <label htmlFor="photoUrl" className="block text-sm font-medium text-gray-700">Upload Photo</label>
            <input
              type="file"
              id="photoFile"
              name="photoFile"
              onChange={handleFileChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
            {showCropper && photoFile && (
              <>
                <Cropper
                  src={photoFile}
                  style={{ height: 400, width: '100%' }}
                  aspectRatio={1}
                  guides={false}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => setCropper(instance)}
                />
                <button
                  onClick={handleCrop}
                  className="mt-2 px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Crop Image
                </button>
              </>
            )}
          </div>
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="age" className="block text-sm font-medium text-gray-700">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            >
              <option value="">Select</option>
              <option value="Cisgender Male">Cisgender Male (Cis Male)</option>
              <option value="Cisgender Female">Cisgender Female (Cis Female)</option>
              <option value="Transgender Male">Transgender Male (Trans Male)</option>
              <option value="Transgender Female">Transgender Female (Trans Female)</option>
              <option value="Non-Binary">Non-Binary</option>
              <option value="Genderqueer">Genderqueer</option>
              <option value="Genderfluid">Genderfluid</option>
              <option value="Agender">Agender</option>
              <option value="Two-Spirit">Two-Spirit</option>
              <option value="Intersex">Intersex</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">Notes</label>
            <ReactQuill
              value={formData.notes}
              onChange={handleNotesChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSave}
              className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <button
              onClick={() => setEditing(true)}
              className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit
            </button>
          </div>
          <div>
            {/* <p className="text-sm text-gray-500 mb-4">Medical Record Number: {patient.medicalRecordNumber || 'Not Assigned'}</p> */}
            <p className="text-xl text-gray-500 mb-4">First Name: {patient.firstName}</p>
            <p className="text-xl text-gray-500 mb-4">Last Name: {patient.lastName}</p>
            <p className="text-xl text-gray-500 mb-4">Age: {patient.age}</p>
            <p className="text-xl text-gray-500 mb-4">Gender: {patient.gender}</p>
            <p className="text-xl text-gray-500 mb-4">Email: {patient.email}</p>
            <p className="text-xl text-gray-500 mb-4">Phone: {patient.contact}</p>
            <p className="text-xl text-gray-500 mb-4">Address: {patient.address}</p>
            <p className="text-xl text-gray-500 mb-4">Emergency Contact Name: {patient.emergencyContactName}</p>
            <p className="text-xl text-gray-500 mb-4">Emergency Contact Phone: {patient.emergencyContactPhone}</p>
            <p className="text-xl text-gray-500 mb-4">Current Medications: {patient.currentMedications}</p>
            <p className="text-xl text-gray-500 mb-4">Allergies: {patient.allergies}</p>
            <p className="text-xl text-gray-500 mb-4">Primary Care Physician: {patient.primaryCarePhysician}</p>
            <p className="text-xl text-gray-500 mb-4">Insurance Provider: {patient.insuranceProvider}</p>
            <p className="text-xl text-gray-500 mb-4">Insurance Policy Number: {patient.insurancePolicyNumber}</p>
            <p className="text-xl text-gray-500 mb-4">Reason for Visit: {patient.reasonForVisit}</p>
            <p className="text-xl text-gray-500 mb-4">Preferred Appointment Time: {patient.preferredAppointmentTime}</p>
            <div className="text-xl text-gray-500 mb-4">
              <p className="text-lg text-black mb-4">Notes:</p>
              <div dangerouslySetInnerHTML={{ __html: patient.notes }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientInformationPage;