import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { doc, addDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';

const ProviderPatientInvite = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const actionCodeSettings = {
    url: `${process.env.REACT_APP_DOMAIN_URL}/finishSignUp`,
    handleCodeInApp: true,
  };

  const handleBackToPatientList = () => {
    navigate('/patients');
  };

  const generateMedicalRecordNumber = async () => {
    const patientsCollection = collection(db, 'patients');
    const patientsSnapshot = await getDocs(patientsCollection);
    let maxMRN = 0;

    patientsSnapshot.forEach((doc) => {
      const data = doc.data();
      const mrn = parseInt(data.medicalRecordNumber, 10);
      if (!isNaN(mrn) && mrn > maxMRN) {
        maxMRN = mrn;
      }
    });

    return maxMRN + 1;
  };

  const handleSubmit = async (values) => {
    const { email, firstName, lastName, phone } = values;
    setLoading(true);
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);

      const medicalRecordNumber = await generateMedicalRecordNumber();

      const patientRef = collection(db, 'patients');
      await addDoc(patientRef, {
        email,
        firstName,
        lastName,
        phone,
        medicalRecordNumber,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      message.success('Invitation sent successfully!');
      form.resetFields();
    } catch (error) {
      message.error('Error sending invitation: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full items-center justify-center min-h-screen bg-gray-100 pt-8 px-4">
      <div className="flex justify-start mb-4">
        <button
          onClick={handleBackToPatientList}
          className="px-4 py-2 font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Back to Patient List
        </button>
      </div>
      <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-md mx-auto">
        <h1 className="text-2xl font-bold text-center mb-4">Invite Patient</h1>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please enter the first name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please enter the last name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: 'Please enter the phone number' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} className="w-full">
              Send Invitation
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProviderPatientInvite;