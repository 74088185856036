import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const AppointmentSchedule = () => {
  const { id } = useParams();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAppointment, setNewAppointment] = useState({
    date: '',
    time: '',
    reason: '',
    visitType: '',
    provider: ''
  });
  const [editingAppointmentId, setEditingAppointmentId] = useState(null);
  const [editingData, setEditingData] = useState({});
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const fetchAppointments = async () => {
      const q = query(collection(db, 'appointments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const appointmentList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(appointmentList);
      setLoading(false);
    };

    fetchAppointments();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({ ...newAppointment, [name]: value });
  };

  const handleAddAppointment = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'appointments'), {
        ...newAppointment,
        patientId: id,
        timestamp: new Date(),
      });
      setNewAppointment({
        date: '',
        time: '',
        reason: '',
        visitType: '',
        provider: ''
      });
      const q = query(collection(db, 'appointments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const appointmentList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(appointmentList);
    } catch (error) {
      console.error('Error adding appointment:', error);
    }
  };

  const handleEditAppointment = (appointment) => {
    setEditingAppointmentId(appointment.id);
    setEditingData(appointment);
  };

  const handleUpdateAppointment = async () => {
    try {
      const docRef = doc(db, 'appointments', editingAppointmentId);
      await updateDoc(docRef, editingData);
      setEditingAppointmentId(null);
      const q = query(collection(db, 'appointments'), where('patientId', '==', id), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const appointmentList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(appointmentList);
    } catch (error) {
      console.error('Error updating appointment:', error);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestAppointment = appointments[0];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">
        Appointment Schedule
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="ml-4 text-sm text-blue-500"
        >
          {collapsed ? 'Show Form & History' : 'Hide Form & History'}
        </button>
      </h2>
      {latestAppointment && !editingAppointmentId && (
        <div className="bg-gray-100 p-4 rounded-md shadow mb-4">
          <p><strong>Date:</strong> {latestAppointment.date}</p>
          <p><strong>Time:</strong> {latestAppointment.time}</p>
          <p><strong>Reason:</strong> {latestAppointment.reason}</p>
          <p><strong>Visit Type:</strong> {latestAppointment.visitType}</p>
          <p><strong>Provider:</strong> {latestAppointment.provider}</p>
          <button
            onClick={() => handleEditAppointment(latestAppointment)}
            className="text-blue-500 underline mt-2"
          >
            Edit
          </button>
        </div>
      )}
      {!collapsed && (
        <>
          <ul className="space-y-4 mb-4">
            {appointments.slice(1).map(appointment => (
              <div key={appointment.id} className="mb-4">
                {editingAppointmentId === appointment.id ? (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <label className="block text-sm font-medium text-gray-700">Date</label>
                    <input
                      type="date"
                      name="date"
                      value={editingData.date}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Time</label>
                    <input
                      type="time"
                      name="time"
                      value={editingData.time}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Reason</label>
                    <input
                      type="text"
                      name="reason"
                      value={editingData.reason}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <label className="block text-sm font-medium text-gray-700">Visit Type</label>
                    <select
                      name="visitType"
                      value={editingData.visitType}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    >
                      <option value="">Select Visit Type</option>
                      <option value="chiropractic">Chiropractic</option>
                      <option value="massage">Massage</option>
                      <option value="evaluation">Evaluation</option>
                      <option value="second visit">Second Visit</option>
                    </select>
                    <label className="block text-sm font-medium text-gray-700">Provider</label>
                    <input
                      type="text"
                      name="provider"
                      value={editingData.provider}
                      onChange={handleEditInputChange}
                      className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                    />
                    <button
                      onClick={handleUpdateAppointment}
                      className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="bg-gray-100 p-4 rounded-md shadow">
                    <p><strong>Date:</strong> {appointment.date}</p>
                    <p><strong>Time:</strong> {appointment.time}</p>
                    <p><strong>Reason:</strong> {appointment.reason}</p>
                    <p><strong>Visit Type:</strong> {appointment.visitType}</p>
                    <p><strong>Provider:</strong> {appointment.provider}</p>
                    <button
                      onClick={() => handleEditAppointment(appointment)}
                      className="text-blue-500 underline mt-2"
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </ul>
          {!editingAppointmentId && (
            <form onSubmit={handleAddAppointment} className="space-y-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newAppointment.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="time" className="block text-sm font-medium text-gray-700">Time</label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  value={newAppointment.time}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="reason" className="block text-sm font-medium text-gray-700">Reason</label>
                <input
                  type="text"
                  id="reason"
                  name="reason"
                  value={newAppointment.reason}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="visitType" className="block text-sm font-medium text-gray-700">Visit Type</label>
                <select
                  id="visitType"
                  name="visitType"
                  value={newAppointment.visitType}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                >
                  <option value="">Select Visit Type</option>
                  <option value="chiropractic">Chiropractic</option>
                  <option value="massage">Massage</option>
                  <option value="evaluation">Evaluation</option>
                  <option value="second visit">Second Visit</option>
                </select>
              </div>
              <div>
                <label htmlFor="provider" className="block text-sm font-medium text-gray-700">Provider</label>
                <input
                  type="text"
                  id="provider"
                  name="provider"
                  value={newAppointment.provider}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Appointment
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentSchedule;