import React, { useState, useEffect } from 'react';
import { collection, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { db, auth } from '../../firebase';

const ComposeInternalMessage = ({ onMessageSent, selectedRecipient }) => {
  const [content, setContent] = useState('');
  const [users, setUsers] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email,
        name: doc.data().name || doc.data().email // Fallback to email if name is not available
      }));
      setUsers(userList);
    };

    fetchUsers();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedRecipient) {
      console.error('No recipient selected');
      return;
    }
    try {
      await addDoc(collection(db, 'internalMessages'), {
        content,
        sender: currentUserEmail,
        recipient: selectedRecipient,
        timestamp: serverTimestamp(),
        archived: false,
      });
      onMessageSent();
      setContent('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="compose-message bg-white dark:bg-gray-800 border-t border-gray-200 p-6">
      <h2 className="text-xl font-bold text-gray-800 mb-4">
        Compose New Message
      </h2>
      <form onSubmit={handleSubmit} className="flex items-center space-x-4">
        <input
          type="text"
          placeholder="Message"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out">
          Send
        </button>
      </form>
    </div>
  );
};

export default ComposeInternalMessage;