import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db, messaging } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Menu, Dropdown, Button, Avatar, Badge } from 'antd';
import { BellOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { getToken, onMessage } from 'firebase/messaging';
import DefaultAvatar from '../../assets/default-profile-pic.png'

const Navbar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        setUser({ ...currentUser, ...userDoc.data() });

        // Request notification permission
        try {
          const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
          if (currentToken) {
            // console.log('FCM Token:', currentToken);
            // Save the token to the database or use it to send notifications
            await storeToken(currentUser.uid, currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        } catch (err) {
          console.log('An error occurred while retrieving token. ', err);
        }

        // Listen for incoming messages
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          setNotifications((prev) => [...prev, payload.notification]);
          setUnreadCount((prev) => prev + 1);
        });
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  const storeToken = async (userId, token) => {
    try {
      await setDoc(doc(db, 'users', userId), { fcmToken: token }, { merge: true });
      console.log('Token stored successfully');
    } catch (error) {
      console.error('Error storing token:', error);
    }
  };

  const handleLogout = () => {
    auth.signOut();
    navigate('/signin');
  };

  const handleProfile = () => {
    navigate(`/profile/${user.uid}`);
  };

  const handleNotifications = () => {
    navigate('/notifications');
    setUnreadCount(0);
  };

  const notificationMenu = (
    <Menu>
      {notifications.length === 0 ? (
        <Menu.Item key="no-notifications">No notifications</Menu.Item>
      ) : (
        notifications.map((notification, index) => (
          <Menu.Item key={`notification-${index}`}>
            {notification.title}: {notification.body}
          </Menu.Item>
        ))
      )}
      <Menu.Item key="view-all" onClick={handleNotifications}>
        View All Notifications
      </Menu.Item>
    </Menu>
  );

  const userDropdownMenu = (
    <Menu>
      <Menu.Item key="profile" onClick={handleProfile}>
        Edit Profile
      </Menu.Item>
      <Menu.Item key="settings" onClick={() => navigate('/settings')}>
        Settings
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item key="patientList" onClick={() => navigate('/patients')}>
        Patient List
      </Menu.Item>
      <Menu.Item key="checkIn" onClick={() => navigate('/checkin')}>
        Check In
      </Menu.Item>
      <Menu.Item key="payment" onClick={() => navigate('/payment')}>
        Payment
      </Menu.Item>
      <Menu.Item key="schedule" onClick={() => navigate('/schedule')}>
        Patient Schedule
      </Menu.Item>
      <Menu.Item key="staffScheduler" onClick={() => navigate('/staff-scheduler')}>
        Staff Scheduler
      </Menu.Item>
      {/* <Menu.Item key="messages" onClick={() => navigate('/messages')}>
        Messages
      </Menu.Item> */}
      <Menu.Item key="internalMessages" onClick={() => navigate('/internal-messages')}>
        Internal Messages
      </Menu.Item>
      <Menu.Item key="askQuestion" onClick={() => navigate('/ask-question')}>
        Ask a Question
      </Menu.Item>
      {/* <Menu.Item key="profile" onClick={handleProfile}>
        Profile
      </Menu.Item> */}
      {/* <Menu.Item key="settings" onClick={() => navigate('/settings')}>
        Settings
      </Menu.Item> */}
      {/* <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item> */}
    </Menu>
  );

  return (
    <div className="bg-indigo-900 shadow z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="text-lg font-bold text-white">
          <Link to="/" style={{ color: 'white' }}>PosturePortal</Link>
        </div>
        <div className="flex items-center">
          <Dropdown overlay={notificationMenu} trigger={['hover', 'click']}>
            <Badge count={unreadCount} offset={[10, 0]}>
              <Button type="link" className="text-white hover:text-gray-200" style={{ color: 'white' }}>
                <BellOutlined />
              </Button>
            </Badge>
          </Dropdown>
          {user && (
            <Dropdown overlay={userDropdownMenu} trigger={['hover', 'click']}>
              <div className="flex items-center cursor-pointer">
                <span className="text-white ml-2 mr-2 hidden md:block">{user.displayName || user.email}</span>
                <Avatar
                  src={user.photoURL || DefaultAvatar }
                  icon={<UserOutlined />}
                  onClick={handleProfile}
                />
              </div>
            </Dropdown>
          )}
          <Dropdown overlay={userMenu} trigger={['hover', 'click']}>
            <Button type="link" className="text-white hover:text-gray-200" style={{ color: 'white' }}>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navbar;