import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addMedicalRecord } from '../../utils/firebaseUtils'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { DeleteOutlined } from '@ant-design/icons';

const OnboardPatient = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    contact: '',
    email: '',
    address: '',
    dateOfBirth: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    medicalHistory: '',
    currentMedications: '',
    allergies: '',
    primaryCarePhysician: '',
    insuranceProvider: '',
    insurancePolicyNumber: '',
    reasonForVisit: '',
    preferredAppointmentTime: '',
    notes: '',
    photoUrl: '',
    labResults: [],
    medicalRecordNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [photoFile, setPhotoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [labFiles, setLabFiles] = useState([]);
  const navigate = useNavigate();

  const generateMedicalRecordNumber = async () => {
    const patientsCollection = collection(db, 'patients');
    const patientsSnapshot = await getDocs(patientsCollection);
    let maxMRN = 0;
  
    patientsSnapshot.forEach((doc) => {
      const data = doc.data();
      const mrn = parseInt(data.medicalRecordNumber, 10);
      if (!isNaN(mrn) && mrn > maxMRN) {
        maxMRN = mrn;
      }
    });
  
    return maxMRN + 1;
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setPhotoFile(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleLabFilesChange = (e) => {
    const files = Array.from(e.target.files);
    setLabFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeLabFile = (index) => {
    setLabFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (!cropper) {
      setError('Please select and crop a photo first.');
      return;
    }

    setUploading(true);
    try {
      const canvas = cropper.getCroppedCanvas({
        width: 200,
        height: 200,
      });

      return new Promise((resolve, reject) => {
        canvas.toBlob(async (blob) => {
          try {
            const storage = getStorage();
            const storageRef = ref(storage, `patients/photos/${Date.now()}.jpeg`);
            await uploadBytes(storageRef, blob);
            const photoUrl = await getDownloadURL(storageRef);
            resolve(photoUrl);
          } catch (error) {
            reject('Failed to upload photo.');
          }
        }, 'image/jpeg');
      });
    } catch (error) {
      setError('Failed to upload photo.');
      return null;
    } finally {
      setUploading(false);
    }
  };

  const uploadFile = async (file, storageRef) => {
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const checkEmailExists = async (email) => {
    const q = query(collection(db, 'patients'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const emailExists = await checkEmailExists(formData.email);
      if (emailExists) {
        throw new Error('A patient with this email already exists.');
      }
  
      const mrn = await generateMedicalRecordNumber();
      formData.medicalRecordNumber = mrn;
  
      if (photoFile) {
        const photoUrl = await handleUpload();
        if (photoUrl) {
          formData.photoUrl = photoUrl;
        } else {
          throw new Error('Photo upload failed.');
        }
      }
  
      if (labFiles.length > 0) {
        const labResults = await Promise.all(
          labFiles.map(file => uploadFile(file, ref(getStorage(), `patients/labs/${file.name}`)))
        );
        formData.labResults = labResults;
  
        await addMedicalRecord({
          patientId: patientDocRef.id,
          description: 'Lab Results',
          notes: 'Uploaded during patient onboarding',
          labResults,
        });
      }
  
      const patientDocRef = doc(collection(db, "patients"));
      await addDoc(patientDocRef, { ...formData, id: patientDocRef.id });
  
      setLoading(false);
      navigate('/patients');
    } catch (error) {
      setError('Error adding patient: ' + error.message);
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rdark:bg-gray-800 ounded-lg shadow-md max-w-md mx-auto">
      <h1 className="text-2xl font-bold text-center mb-4">Onboard New Patient</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="age" className="block text-sm font-medium text-gray-700">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          >
<option value="">Select</option>
  <option value="Cisgender Male">Cisgender Male (Cis Male)</option>
  <option value="Cisgender Female">Cisgender Female (Cis Female)</option>
  <option value="Transgender Male">Transgender Male (Trans Male)</option>
  <option value="Transgender Female">Transgender Female (Trans Female)</option>
  <option value="Non-Binary">Non-Binary</option>
  <option value="Genderqueer">Genderqueer</option>
  <option value="Genderfluid">Genderfluid</option>
  <option value="Agender">Agender</option>
  <option value="Two-Spirit">Two-Spirit</option>
  <option value="Intersex">Intersex</option>
  <option value="Other">Other</option>          </select>
        </div>
        <div>
          <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact (Phone Number)</label>
          <input
            type="text"
            id="contact"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="emergencyContactName" className="block text-sm font-medium text-gray-700">Emergency Contact Name</label>
          <input
            type="text"
            id="emergencyContactName"
            name="emergencyContactName"
            value={formData.emergencyContactName}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="emergencyContactPhone" className="block text-sm font-medium text-gray-700">Emergency Contact Phone</label>
          <input
            type="text"
            id="emergencyContactPhone"
            name="emergencyContactPhone"
            value={formData.emergencyContactPhone}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="medicalHistory" className="block text-sm font-medium text-gray-700">Medical History</label>
          <textarea
            id="medicalHistory"
            name="medicalHistory"
            value={formData.medicalHistory}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="currentMedications" className="block text-sm font-medium text-gray-700">Current Medications</label>
          <textarea
            id="currentMedications"
            name="currentMedications"
            value={formData.currentMedications}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="allergies" className="block text-sm font-medium text-gray-700">Allergies</label>
          <textarea
            id="allergies"
            name="allergies"
            value={formData.allergies}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="primaryCarePhysician" className="block text-sm font-medium text-gray-700">Primary Care Physician</label>
          <input
            type="text"
            id="primaryCarePhysician"
            name="primaryCarePhysician"
            value={formData.primaryCarePhysician}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="insuranceProvider" className="block text-sm font-medium text-gray-700">Insurance Provider</label>
          <input
            type="text"
            id="insuranceProvider"
            name="insuranceProvider"
            value={formData.insuranceProvider}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="insurancePolicyNumber" className="block text-sm font-medium text-gray-700">Insurance Policy Number</label>
          <input
            type="text"
            id="insurancePolicyNumber"
            name="insurancePolicyNumber"
            value={formData.insurancePolicyNumber}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="reasonForVisit" className="block text-sm font-medium text-gray-700">Reason for Visit</label>
          <textarea
            id="reasonForVisit"
            name="reasonForVisit"
            value={formData.reasonForVisit}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="preferredAppointmentTime" className="block text-sm font-medium text-gray-700">Preferred Appointment Time</label>
          <select
            id="preferredAppointmentTime"
            name="preferredAppointmentTime"
            value={formData.preferredAppointmentTime}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          >
            <option value="">Select</option>
            <option value="Morning">Morning</option>
            <option value="Afternoon">Afternoon</option>
            <option value="Evening">Evening</option>
          </select>
        </div>
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            required
          />
        </div>
        <div>
          <label htmlFor="photo" className="block text-sm font-medium text-gray-700">Photo</label>
          <input
            type="file"
            id="photo"
            name="photo"
            accept="image/*"
            onChange={handlePhotoChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
          />
          {photoFile && (
            <Cropper
              src={photoFile}
              style={{ height: 200, width: '100%' }}
              aspectRatio={1}
              guides={false}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => setCropper(instance)}
            />
          )}
        </div>
        <div>
          <label htmlFor="labResults" className="block text-sm font-medium text-gray-700">Lab Results</label>
          <input
            type="file"
            id="labResults"
            name="labResults"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.md"
            multiple
            onChange={handleLabFilesChange}
            className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
          />
          <ul className="mt-2">
            {labFiles.map((file, index) => (
              <li key={index} className="flex items-center justify-between">
                <span>{file.name}</span>
                <button type="button" onClick={() => removeLabFile(index)} className="text-red-500">
                  <DeleteOutlined />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default OnboardPatient;