import React from 'react';
import { Form, Input, Button, message } from 'antd';

const ContactUs = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // Here you would typically send the form data to your backend
    // For example, using fetch or axios to send a POST request
    // to an endpoint that handles email sending

    // Simulating a successful form submission
    message.success('Your message has been sent successfully!');
    form.resetFields();
  };

  return (
    <div className="container dark:bg-gray-800 mx-auto my-8 px-4">
      <h1 className="text-3xl font-bold text-center mb-6">Contact Us</h1>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter your name' }]}
        >
          <Input placeholder="Your Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter a valid email' },
          ]}
        >
          <Input placeholder="Your Email" />
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: 'Please enter your message' }]}
        >
          <Input.TextArea rows={4} placeholder="Your Message" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Send Message
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactUs;