import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, updateDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { DatePicker, TimePicker, Button, Card, Row, Col, Typography, Space, Form, Input, Select, List, Avatar } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const localizer = momentLocalizer(moment);

const Schedule = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ title: '', start: new Date(), end: new Date(), visitType: '', providerId: '', checkedInStatus: 'scheduled' });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, 'appointments');
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = await Promise.all(eventsSnapshot.docs.map(async (eventDoc) => {
        const data = eventDoc.data();
        const patientDocRef = doc(db, 'patients', data.patientId);
        const patientDoc = await getDoc(patientDocRef);
  
        if (patientDoc.exists()) {
          const patientData = patientDoc.data();
          return {
            ...data,
            title: `${data.reason} - ${patientData.firstName} ${patientData.lastName}`,
            start: data.start ? new Date(data.start.seconds * 1000) : new Date(),
            end: data.end ? new Date(data.end.seconds * 1000) : new Date(),
            id: eventDoc.id,
            photoUrl: patientData.photoUrl
          };
        } else {
          console.warn(`No patient found for ID: ${data.patientId}`);
          return {
            ...data,
            title: `${data.reason} - Unknown Patient`,
            start: data.start ? new Date(data.start.seconds * 1000) : new Date(),
            end: data.end ? new Date(data.end.seconds * 1000) : new Date(),
            id: eventDoc.id
          };
        }
      }));
      setEvents(eventsList);
    };
  
    const fetchPatients = async () => {
      const patientsCollection = collection(db, 'patients');
      const patientsSnapshot = await getDocs(patientsCollection);
      const patientsList = patientsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
  
      patientsList.sort((a, b) => a.lastName.localeCompare(b.lastName));
      setPatients(patientsList);
    };
  
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      setUsers(usersList);
    };
  
    fetchEvents();
    fetchPatients();
    fetchUsers();
  }, []); // Ensure the dependency array is present

  const handleAddEvent = async () => {
    if (!selectedPatient || !newEvent.providerId) {
      alert("Please select a patient and a provider.");
      return;
    }

    const docRef = await addDoc(collection(db, 'appointments'), {
      title: newEvent.title,
      start: newEvent.start,
      end: newEvent.end,
      visitType: newEvent.visitType,
      providerId: newEvent.providerId,
      date: moment(newEvent.start).format('YYYY-MM-DD'),
      time: moment(newEvent.start).format('HH:mm'),
      reason: newEvent.title,
      patientId: selectedPatient.id,
      checkInStatus: 'scheduled',
      timestamp: new Date()
    });
    setEvents([...events, { ...newEvent, id: docRef.id, title: `${newEvent.title} - ${selectedPatient.firstName} ${selectedPatient.lastName}` }]);
    setNewEvent({ title: '', start: new Date(), end: new Date(), visitType: '', providerId: '' });
  };

  const handleUpdateEvent = async () => {
    if (!selectedPatient || !selectedEvent.providerId) {
      alert("Please select a patient and a provider.");
      return;
    }

    const eventDoc = doc(db, 'appointments', selectedEvent.id);
    await updateDoc(eventDoc, {
      ...selectedEvent,
      title: `${selectedEvent.reason} - ${selectedPatient.firstName} ${selectedPatient.lastName}`
    });
    setEvents(events.map(event => (event.id === selectedEvent.id ? {
      ...selectedEvent,
      title: `${selectedEvent.reason} - ${selectedPatient.firstName} ${selectedPatient.lastName}`
    } : event)));
    setSelectedEvent(null);
  };

  const handleDelete = async (eventId) => {
    try {
      await deleteDoc(doc(db, 'appointments', eventId));
      setEvents(events.filter(event => event.id !== eventId));
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    setNewEvent({ ...newEvent, start, end });
  };

  const handleSelectEvent = event => {
    setSelectedEvent(event);
  };

  const handleTimeChange = (time, timeString, isStart) => {
    if (time) {
      const updatedTime = time.toDate();
      setNewEvent(prevEvent => ({
        ...prevEvent,
        start: isStart ? updatedTime : prevEvent.start,
        end: isStart ? prevEvent.end : updatedTime
      }));
    }
  };

  return (
    <div className="container dark:bg-gray-800 dark:text-gray-200 mx-auto my-4">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={6}>
          <Card title={<span className="dark:text-gray-200">Patients</span>} bordered={false} className='dark:bg-gray-800'>
            <List
              itemLayout="horizontal"
              dataSource={patients}
              renderItem={(patient, index) => (
                <List.Item
                  onClick={() => setSelectedPatient(patient)}
                  style={{
                    backgroundColor: selectedPatient && selectedPatient.id === patient.id ? '#f0f0f0' : 'transparent',
                    cursor: 'pointer'
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={patient.photoUrl || `https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                    title={<span className="font-bold dark:text-gray-200">{patient.firstName} {patient.lastName}</span>}
                    description={<span className="dark:text-gray-400">{patient.email}</span>}
                  />
                </List.Item>
              )}
              style={{ maxHeight: '50vh', overflowY: 'auto' }}
            />
          </Card>
        </Col>
        <Col xs={24} lg={18}>
          <Card title={<span className="dark:text-gray-200">Schedule</span>} bordered={false} className='dark:bg-gray-800'>
            <Form layout="vertical" className="dark:text-gray-200">
              <Form.Item label={<span className="dark:text-gray-200">Event Title</span>}>
                <Input
                  placeholder="Add Title"
                  value={newEvent.title}
                  onChange={e => setNewEvent({ ...newEvent, title: e.target.value })}
                  className='dark:bg-gray-700 dark:text-white'
                />
              </Form.Item>
              <Space direction="horizontal" size="middle" style={{ display: 'flex', marginBottom: 16 }}>
                <Form.Item label={<span className="dark:text-gray-200">Select Date Range</span>}>
                  <RangePicker
                    value={[moment(newEvent.start), moment(newEvent.end)]}
                    onChange={(dates) => {
                      if (dates) {
                        setNewEvent({ ...newEvent, start: dates[0].toDate(), end: dates[1].toDate() });
                      }
                    }}
                    placeholder={['Start Date', 'End Date']}
                    allowEmpty={[false, true]}
                    className='dark:bg-gray-700 dark:text-white'
                  />
                </Form.Item>
                <Form.Item label={<span className="dark:text-gray-200">Select Start Time</span>}>
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    value={moment(newEvent.start)}
                    onChange={(time, timeString) => handleTimeChange(time, timeString, true)}
                    className='dark:bg-gray-700 dark:text-white'
                  />
                </Form.Item>
                <Form.Item label={<span className="dark:text-gray-200">Select End Time</span>}>
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    value={moment(newEvent.end)}
                    onChange={(time, timeString) => handleTimeChange(time, timeString, false)}
                    className='dark:bg-gray-700 dark:text-white'
                  />
                </Form.Item>
              </Space>
              <Form.Item label={<span className="dark:text-gray-200">Visit Type</span>}>
                <Select
                  value={newEvent.visitType}
                  onChange={value => setNewEvent({ ...newEvent, visitType: value })}
                  className='dark:bg-gray-700 dark:text-white'
                >
                  <Option value="" className='dark:bg-gray-700 dark:text-white'>Select Visit Type</Option>
                  <Option value="chiropractic" className='dark:bg-gray-700 dark:text-white'>Chiropractic</Option>
                  <Option value="massage" className='dark:bg-gray-700 dark:text-white'>Massage</Option>
                  <Option value="evaluation" className='dark:bg-gray-700 dark:text-white'>Evaluation</Option>
                  <Option value="second visit" className='dark:bg-gray-700 dark:text-white'>Second Visit</Option>
                  <Option value="laser" className='dark:bg-gray-700 dark:text-white'>Laser</Option>
                </Select>
              </Form.Item>
              <Form.Item label={<span className="dark:text-gray-200">Provider</span>}>
                <Select
                  placeholder="Select Provider"
                  value={newEvent.providerId}
                  onChange={value => setNewEvent({ ...newEvent, providerId: value })}
                  className='dark:bg-gray-700 dark:text-white'
                >
                  {users.map(user => (
                    <Option key={user.id} value={user.id} className='dark:bg-gray-700 dark:text-white'>
                      {user.firstName} {user.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button type="primary" onClick={handleAddEvent}>
                Add Event
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
      <Card title={<span className="dark:text-gray-200">Calendar</span>} bordered={false} className="mt-4 dark:bg-gray-800">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          className='dark:bg-gray-700'
        />
      </Card>
      {selectedEvent && (
        <div className="mt-4">
          <Title level={4}>Edit Event</Title>
          <Form layout="vertical">
            <Form.Item label="Event Title">
              <Input
                value={selectedEvent.title}
                onChange={e => setSelectedEvent({ ...selectedEvent, title: e.target.value })}
              />
            </Form.Item>
            <Space direction="horizontal" size="middle" style={{ display: 'flex', marginBottom: 16 }}>
              <Form.Item label="Select Date Range">
                <RangePicker
                  value={[moment(selectedEvent.start), moment(selectedEvent.end)]}
                  onChange={(dates) => {
                    if (dates) {
                      setSelectedEvent({ ...selectedEvent, start: dates[0].toDate(), end: dates[1].toDate() });
                    }
                  }}
                  placeholder={['Start Date', 'End Date']}
                  allowEmpty={[false, true]}
                />
              </Form.Item>
              <Form.Item label="Select Start Time">
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  value={moment(selectedEvent.start)}
                  onChange={(time, timeString) => setSelectedEvent({ ...selectedEvent, start: time ? time.toDate() : selectedEvent.start })}
                />
              </Form.Item>
              <Form.Item label="Select End Time">
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  value={moment(selectedEvent.end)}
                  onChange={(time, timeString) => setSelectedEvent({ ...selectedEvent, end: time ? time.toDate() : selectedEvent.end })}
                />
              </Form.Item>
            </Space>
            <Form.Item label="Visit Type">
              <Select
                value={selectedEvent.visitType}
                onChange={value => setSelectedEvent({ ...selectedEvent, visitType: value })}
              >
                <Option value="">Select Visit Type</Option>
                <Option value="chiropractic">Chiropractic</Option>
                <Option value="massage">Massage</Option>
                <Option value="evaluation">Evaluation</Option>
                <Option value="second visit">Second Visit</Option>
                <Option value="laser">Laser</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Provider">
              <Select
                placeholder="Select Provider"
                value={selectedEvent.providerId}
                onChange={value => setSelectedEvent({ ...selectedEvent, providerId: value })}
              >
                {users.map(user => (
                  <Option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName} {/* Display first and last name */}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button type="primary" onClick={handleUpdateEvent}>
              Update Event
            </Button>
            <Button type="danger" onClick={() => handleDelete(selectedEvent.id)} className="ml-2">
              Delete Event
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Schedule;